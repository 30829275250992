import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBGCH2Mv--g5u_SZwUJtw_9bFHHmgVJIs0",
    authDomain: "malversum.firebaseapp.com",
    projectId: "malversum",
    storageBucket: "malversum.appspot.com",
    messagingSenderId: "972528940732",
    appId: "1:972528940732:web:be541249fb844e1db102b9",
    measurementId: "G-7FFN1B2LN8"
  };

  //initialize firebase
  initializeApp(firebaseConfig)

  //init services
  const db = getFirestore()
  const auth = getAuth()

  export { db, auth }