import LexiconsSublist from './LexiconsSublist';
import { useEffect, useState } from 'react';
import { db } from '../firebase/config'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import Select from 'react-select'
import { selectClassNames } from '../misc/SelectClassNames';
import styles from './Listing.module.css'
import Loading from './Loading';
import { setTopMargin, safeSearch } from '../misc/CommonFunctions';

function LexiconsList({
  theme,
  userIsAdmin,
  fixedSubtype,
  fixedUniverse,
  handleConfirm : handleConfirmChoice,
  labels: tLabels,
  themeOptions,
  handleSelect : handleThemeSelect,
  inModal
}) {

  const category = 'items'
  const [search, setSearch] = useState('')
  const [inContent, setInContent] = useState(false)
  const [filterPrice, setFilterPrice] = useState('')
  const [filterSubtype, setFilterSubtype] = useState('')
  const [data, setData] = useState()
  const [universes, setUniverses] = useState(['Malropa', 'Messianica', 'Faerion', 'Triamonda'])
  const [newItem, setNewItem] = useState(false)
  
  const getItems = (q) => {

    let ref = collection(db, category)
    if(q) {
      ref = query(ref, where(...q))
    }

    const unsub = onSnapshot(ref, (snapshot) => {
      let results = []
      snapshot.docs.forEach(doc => {
        results.push({ ...doc.data(), id: doc.id })
      })
      setData(results)
    })
    
    return () => unsub()
  }

  // console.log(data)

  const changeUniverses = (u, add) => {
    // console.log('u:',u,'add:',add)
    let un = []
    if(add && !universes.includes(u)) {
        un = [...universes, u]
    }
    else if(universes.includes(u)) {
        universes.forEach((n)=> {
            if(n !== u){
                un = [...un, n]
            }
        })
    }
    setUniverses(un)
  }

  const handleSetNewItem = (v) => {
    setNewItem(v)
  }

  

  useEffect(() => {
    getItems(['type','==',theme])
    setFilterSubtype('')
  }, [theme])


  const labels = {
    weapon: 'Broń',
    ranged_weapon: 'Broń Dystansowa',
    shield: 'Tarcza',
    armor: 'Zbroja',
    camouflage: 'Kamuflaż',
  
    social_state: 'Stan społeczny',
    origin: 'Pochodzenie',
    folk: 'Lud',
  
    natural: 'Ludzkie',
    supernatural: 'Nadludzkie',
    spell: 'Czarodziejskie',
  
    Oppression: 'Opresja',
    scenery: 'Sceneria',
    artiTectProject: 'Projekt Arti-tekta',
    GMastering: 'Prowadzenie sesji',
    NPC: 'Postacie Niezależne',
    quest: 'Zadanie',
    faction: 'Frakcja',
    place: 'Miejsce',
    genre: 'Typ przygody',
  
    active: 'Aktywne',
    passive: 'Pasywne',
    salvator: 'Salvator',
    artifact: 'Zaczarowany przedmiot'
  }
  
  const equipmentOptions = [
      // {value: '-', label: '-'},
      {value: 'active', label: labels['active']},
      {value: 'passive', label: labels['passive']},
      {value: 'salvator', label: labels['salvator']},
      {value: 'artifact', label: labels['artifact']}
  ]           
  
  const weaponOptions = [
      // {value: '-', label: '-'},
      {value: 'weapon', label: labels['weapon']},
      {value: 'ranged_weapon', label: labels['ranged_weapon']},
      {value: 'shield', label: labels['shield']},
      {value: 'armor', label: labels['armor']},
      {value: 'camouflage', label: labels['camouflage']}
  ]
  const belongingOptions = [
      // {value: '-', label: '-'},
      {value: 'social_state', label: labels['social_state']},
      {value: 'origin', label: labels['origin']},
      {value: 'folk', label: labels['folk']}
  ]
  const abilityOptions = [
      // {value: '-', label: '-'},
      {value: 'natural', label: labels['natural']},
      {value: 'supernatural', label: labels['supernatural']},
      {value: 'spell', label: labels['spell']}
  ]
  const inspirationOptions = [
      // {value: '-', label: '-'},
      {value: 'GMastering', label: labels['GMastering']},
      {value: 'Oppression', label: labels['Oppression']},
      {value: 'scenery', label: labels['scenery']},
      {value: 'artiTectProject', label: labels['artiTectProject']},
      {value: 'NPC', label: labels['NPC']},
      {value: 'quest', label: labels['quest']},
      {value: 'faction', label: labels['faction']},
      {value: 'place', label: labels['place']},
      {value: 'genre', label: labels['genre']}
  ]
  
  const handleSelect = (selected) => {
    setFilterSubtype(selected.value)
  }

  useEffect(()=>{
    setTopMargin()
  })

  if(data)
  {
  
         return (
            <>
  
            <header className={inModal ? 'modalTools' : 'tools'}>
              {inModal && <h4>Filtry:</h4>}
              <div className={`${styles.Filters} ${styles.Lexicons}`}>
                {themeOptions &&
                <div>
                  <span>Temat:</span>
                  <Select 
                      options={themeOptions}
                      onChange={handleThemeSelect}
                      value={{value: theme, label: tLabels[theme]}}
                      unstyled
                      classNames={selectClassNames}
                  />
                </div>
                } 


                <div className={styles.LexType}>
                
                  {(!fixedSubtype && (
                    theme === 'weapon' 
                    || theme === 'GMInspiration' 
                    || theme === 'equipment'
                    || theme === 'special'
                    || theme === 'ability'
                    || theme === 'belonging'
                    )) &&
                    <div>
                    <span>Typ:</span>
                      {theme === 'weapon' &&
                      <Select 
                          options={weaponOptions}
                          onChange={handleSelect}
                          value={{value: filterSubtype, label: labels[filterSubtype]}}
                          unstyled
                          classNames={selectClassNames}
                      /> 
                      }
                      {theme === 'ability' &&
                      <Select 
                          options={abilityOptions}
                          onChange={handleSelect}
                          value={{value: filterSubtype, label: labels[filterSubtype]}}
                          unstyled
                          classNames={selectClassNames}
                      />
                      }
                      {theme === 'GMInspiration' &&
                      <Select 
                          options={inspirationOptions}
                          onChange={handleSelect}
                          value={{value: filterSubtype, label: labels[filterSubtype]}}
                          unstyled
                          classNames={selectClassNames}
                      />
                      }
                      {theme === 'equipment' &&
                      <Select 
                          options={equipmentOptions}
                          onChange={handleSelect}
                          value={{value: filterSubtype, label: labels[filterSubtype]}}
                          unstyled
                          classNames={selectClassNames}
                      />
                      }
                      {theme === 'belonging' &&
                      <Select 
                          options={belongingOptions}
                          onChange={handleSelect}
                          value={{value: filterSubtype, label: labels[filterSubtype]}}
                          unstyled
                          classNames={selectClassNames}
                      />
                      }
                  </div>
                  }
                

                <div>
                  {(theme === 'weapon' || theme === 'equipment' || theme === 'ability') &&
                  <>
                    <span>Koszt:</span>
                    <input 
                      className={styles.priceFilter}
                      type="text" 
                      onChange={(e)=>{setFilterPrice(e.target.value)}}
                      value={filterPrice}
                    />
                  </>
                  }
                </div>

                </div>
                <div className={styles.search}>
                <label>
                  <span>Szukaj:</span>
                  
                  <input 
                      type='text' 
                      value={search} 
                      onChange={(e)=>{setSearch(safeSearch(e.target.value))}} 
                  />
                  {search && <button onClick={()=>{setSearch('')}}>X</button>}
                  </label>
                  <label>
                    <input 
                      type="checkbox"
                      onChange={(e)=>{setInContent(e.target.checked)}}
                      value={inContent}     
                    />
                    Szukaj w opisach
                  </label>
                </div>
                <div className={styles.checkboxes}>
                    {!fixedUniverse &&
                      <>
                        <label>
                            <input 
                                type="checkbox" 
                                checked={universes.includes('Faerion')}
                                onChange={(e)=>{changeUniverses('Faerion', e.target.checked)}}
                            />Faerion
                        </label>
                        <label>
                            <input 
                                type="checkbox" 
                                checked={universes.includes('Malropa')}
                                onChange={(e)=>{changeUniverses('Malropa', e.target.checked)}}
                            />Malropa
                        </label>
                        <label>
                            <input 
                                type="checkbox" 
                                checked={universes.includes('Messianica')}
                                onChange={(e)=>{changeUniverses('Messianica', e.target.checked)}}
                            />Messianica
                        </label>
                        <label>
                            <input 
                                type="checkbox" 
                                checked={universes.includes('Triamonda')}
                                onChange={(e)=>{changeUniverses('Triamonda', e.target.checked)}}
                            />Triamonda
                        </label>
                        </>
                    }
                </div>
                
                {(handleConfirmChoice && theme === 'characterType') && 
                <div>
                      <button
                        onClick={(e)=>{
                          e.preventDefault()
                          let rng = Math.floor((Math.random() * (data.length -1)) + 0)
                          handleConfirmChoice(data[rng])
                        }}
                      >
                        Wylosuj
                      </button>
                      </div>
                  }
                
                



              </div>

          {!newItem && userIsAdmin &&
              <div>
                <button
                    onClick={(e)=>{
                        e.preventDefault()
                        setNewItem(true)
                    }}
                >Dodaj
                </button>
              </div>
          }
            </header>


            {themeOptions && <h1>Inspiracje</h1>}
            <div className={`${styles.Listing} ${inModal && styles.modal}`}>
              <LexiconsSublist 
                  data={data}
                  search={search} 
                  inContent={inContent} 
                  filterPrice={filterPrice}
                  filterSubtype={fixedSubtype ? fixedSubtype : filterSubtype}
                  universes={fixedUniverse ? [fixedUniverse] : universes}
                  fixedUniverse={fixedUniverse}
                  fixedSubtype={fixedSubtype}
                  theme={theme}
                  handleSetNewItem={handleSetNewItem}
                  newItem={newItem}
                  userIsAdmin={userIsAdmin}
                  handleConfirmChoice={handleConfirmChoice}
                  inModal={inModal}
              />
            </div>
            
              
            </>
            )
    
   }
   else if(!data) {
    return (
      <Loading />
    )
   }
}

export default LexiconsList
