import styles from './Home.module.css'
import LexiconsList from '../components/LexiconsList'
import { useState, useEffect } from 'react'
import { useAuthContext } from '../hooks/useAuthContext';
import { useParams, useNavigate } from 'react-router-dom';

function Lexicons() {

  const { paramTheme = 'weapon' } = useParams()

  

  const [theme, setTheme] = useState(paramTheme)
  const { userIsAdmin } = useAuthContext()

  const navigate = useNavigate();
    
  useEffect(()=>{
    navigate('/lexicons', { replace: true });
    // eslint-disable-next-line
  },[])

  const labels = {
    weapon: 'Uzbrojenie',
    equipment: 'Ekwipunek',
    ability: 'Zdolności',
    characterType: 'Typy postaci',
    belonging: 'Przynależność',
    GMInspiration: 'Inspiracje dla MG'
  }

  const themeOptions = [
    // {value: '-', label: '-'},
    {value: 'weapon', label: labels['weapon']},
    {value: 'equipment', label: labels['equipment']},
    {value: 'ability', label: labels['ability']},
    {value: 'characterType', label: labels['characterType']},
    {value: 'belonging', label: labels['belonging']},
    {value: 'GMInspiration', label: labels['GMInspiration']}
]  

  const handleSelect = (selected) => {
    setTheme(selected.value)
  }

  return (
    <div className={styles.HomeContent}>
  
        <LexiconsList 
          theme={theme} 
          userIsAdmin={userIsAdmin} 
          labels={labels} 
          themeOptions={themeOptions} 
          handleSelect={handleSelect}
        />
    </div>
  )
}

export default Lexicons
