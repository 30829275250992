import { useAuthContext } from '../hooks/useAuthContext';
import { useLogout } from '../hooks/useLogout';
import styles from './RequestSent.module.css'
import { doc, getDoc, collection } from 'firebase/firestore';
import { db } from '../firebase/config';
import { useState } from 'react';
import Loading from '../components/Loading';

const getUser = async (id, checkStatus) => {
  const ref = doc(collection(db, 'users' ), id)
  await getDoc(ref)
    .then((doc) => {
      let st = doc.data()
      checkStatus(st)
    })   
    .catch(error => {
      if(error){
        checkStatus(null)
        console.log('check accessibility error',error)
      }   
  })      
}

function RequestSent() {
  const { logout } = useLogout()
  const { user } = useAuthContext()
  const[userStatus, setUserStatus] = useState('unknown')


  if(user) {
   
    // console.log(userStatus)

    if(userStatus === 'unknown')
    {
      getUser(user.uid, (status) => {  
        if(status && !status.removed && !status.verified){
          setUserStatus('pending')
        }else if(status && status.removed){
          setUserStatus('banned')
        }else if(!status){
          console.log('Cannot check status - access problem')
        }else {
          setUserStatus('verified')
        }
      })
    }

    if(userStatus === 'pending')
    {

      return (
    
    
        <div className={styles.wrapper}>
          <div className={styles.textContainer}>
            <h3>Zgłoszenie wysłane</h3>
            <div>Jak tylko zostanie zweryfikowane, otrzymasz wiadomość na adres {user.email}</div>
            <div><button className={styles.buttonWrap} onClick={logout}>Wróć do strony logowania</button></div>
          </div>
        </div>
    
    
        )
      }
      else if(userStatus === 'banned')
      {
        return (
          <div className={styles.wrapper}>
            <div className={styles.textContainer}>
              <h3>Wygnano Cię z Malversum</h3>
              <div>albo jest coś nie tak z bazą danych :-)</div>
              <div className={styles.buttonWrap}><button onClick={logout}>Wróć do strony logowania</button></div>
            </div>
          </div>
        )
      }
      else
      {
        return (
          <Loading />
        )
      }
  }
}
export default RequestSent
