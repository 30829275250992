import styles from './Home.module.css'
import lStyles from '../components/Listing.module.css'
import Loading from '../components/Loading';

const importAll = (r) => {
    let images = {};
    r.keys().forEach((item) => { images[item.replace('./', '')] = r(item); });
    return images
   }

const images = importAll(require.context('../illustrations', false, /\.(png|jpe?g|svg)$/));

function ImagesGallery() {

if(images) {
  return (
    
    <div className={styles.HomeContent}>
      <h2>Ilustracje</h2>
      <div className={lStyles.Listing}>
        <ul>
      {
        Object.keys(images).map((k, i) => {
            return (
                <li key={i} >
                  <div>
                    <img key={i} alt={k} src={images[k]} />
                  </div>
                  <div>
                    <button 
                        onClick={()=>{
                            navigator.clipboard.writeText(`<img src='${images[k]}' />`)
                        }}>Kopiuj link</button>
                  </div>
                </li>
            )
          })
      }
        </ul>
      </div>
    </div>
  )
  }
  else {
    return (
      <Loading />
    )
  }
}

export default ImagesGallery
