// import { useGetListFromDB } from '../hooks/useGetListFromDB'; 
import styles from './Print.module.css'
import PrintContent from '../components/PrintContent';
import { useEffect, useState } from 'react';
import { db } from '../firebase/config'
import { collection, onSnapshot, doc, getDoc, query, where } from 'firebase/firestore'
import logo from '../assets/logo - malversum - NORMAL - BLACK.svg';
import ReactToPrint from 'react-to-print';
import { useRef } from 'react';
import { setTopMargin } from '../misc/CommonFunctions'

function Print() {

  const category = 'articles'
  // const { data } = useGetListFromDB(category,['removed','==',false])
  const [fieldFilters, setFieldFilters] = useState({})
  const [pinnedFields, setPinnedFields] = useState('')
  const [fields, setFields] = useState([])
  const componentRef = useRef()
  const[data,setData] = useState()


  const getPinnedFields = async (cb) => {
    const ref = doc(collection(db, 'maintenance'), 'pinned')
    await getDoc(ref)
      .then((doc)=>{
        cb(doc.data())
      })
      .catch((err)=>{
        console.log(err)
      })
  }

  const getItems = (q,cb) => {

    let ref = collection(db, category)
    if(q) {
      ref = query(ref, where(...q))
    }

    const unsub = onSnapshot(ref, (snapshot) => {
      let results = []
      snapshot.docs.forEach(doc => {
        results.push({ ...doc.data(), id: doc.id })
      })
      cb(results)
    })
    
    return () => unsub()
  }

  // data && data.forEach((d)=>{
  //   d.field !== '' && fields.add(d.field)
  // }) 


  useEffect(()=>{

    getPinnedFields((cb)=>{
      setPinnedFields(cb)
      cb.fields.forEach((f)=>{
        fieldFilters[f] = true
      })
    })
    const flds = new Set()
    getItems(['removed','==',false],(cb)=>{
      setData(cb)
      cb.forEach((d)=>{
          d.field !== '' && flds.add(d.field)
      })
      setFields(Array.from(flds))
    })

    // eslint-disable-next-line
  },[])

  // const print = () => {
  //   let printContents = document.getElementById('toPrint').innerHTML;
  //   let originalContents = document.body.innerHTML;
  //   document.body.innerHTML = printContents;
  //   window.print();
  //  document.body.innerHTML = originalContents; 
  // }

  const getSortedArticles = (data, field) => {
    let articles = new Set()
    data.forEach((d)=>{
      d.field === field && articles.add(d)
    })
    let sortedArticles = Array.from(articles).sort()
    return sortedArticles
  }

  const getSortedFields = (fields, pinnedFields) => {
    let allSorted =  Array.from(fields).sort()
    let final = new Set()
    allSorted.forEach((s)=>{
      pinnedFields.includes(s) && final.add(s)
    })
    allSorted.forEach((s)=>{
      if(s!=='home') {
        final.add(s)
      }
    })
    final = Array.from(final)
    return final
  }

  const updateFieldFilter = (v, c) => {
    const newFilters = {...fieldFilters}
    newFilters[v] = c
    setFieldFilters(newFilters)
  }

  useEffect(()=>{
    setTopMargin()
  })

  if(data && fields && pinnedFields)
  {

    const sortedFields = getSortedFields(fields, pinnedFields.fields)
    
    return (
      
      <div className={styles.List}>
        <nav className='tools'>
          <div className={styles.Filters}>
            <span>Wybierz rozdziały:</span>
            <div className={styles.checkboxes}>
            {sortedFields && sortedFields.map(d => (
              <label key={d}>
                <input 
                  type='checkbox' 
                  checked={fieldFilters[d] ? fieldFilters[d] : false}
                  onChange={(e)=>{updateFieldFilter(e.target.value, e.target.checked)}}
                  value={d}
                />
                <span>{d}</span></label>
            ))}
            </div>
            <div>
            <ReactToPrint
                trigger={() => <button>Drukuj</button>}
                content={() => componentRef.current}
            />
              {/* <button onClick={(e)=>{print()}}>Drukuj</button> */}
            </div>
          </div>
        </nav>
        <div id='toPrint' className={styles.toPrint} ref={componentRef}>
        <div className={styles.frontPage}>
            <img className={styles.logoToPrint} src={logo} alt="logo" />
            <h4 className={styles.rpg}>Gra fabularna</h4>
        </div>
        {sortedFields && sortedFields.map((d) => {
            const a = getSortedArticles(data, d)
            return(
   
              <PrintContent 
                key={d} 
                field={d} 
                articles={a} 
                fieldFilters={fieldFilters} 
              />
  
            )
        })}
        </div>
      </div>
    
    )
   }
}

export default Print
