import { useGetListFromDB } from '../hooks/useGetListFromDB'; 
import styles from './Listing.module.css'
import { Link } from 'react-router-dom'
import { useAuthContext } from '../hooks/useAuthContext';
import { doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase/config';


const deleteArticle = async (id) => {
  const ref = doc(db, 'articles', id)
  await deleteDoc(ref)
}
const recoverArticle = async (id) => {
    const ref = doc(db, 'articles', id)
    await updateDoc(ref, {
        removed: false
    })
}

function RemovedArticlesList() {

  const category = 'articles'
  const { data } = useGetListFromDB(category, ['removed','==',true])
  const { userIsAdmin } = useAuthContext()

  return (
    <div className={styles.Listing}>
      <h2>Usunięte artykuły</h2>
      <ul> 
        {data && data.map(d => (
          <li key={d.id}>
            <div>
              <Link to={`/${category}/${d.id}`}>{d.title}</Link>
              <div className={styles.excerpt}>{d.content}</div>
            </div>
            {userIsAdmin && 
            <>
            <div>
            <button onClick={()=>{
              navigator.clipboard.writeText(d.id)
            }}>
              Kopiuj ID
            </button>
            </div>
            <div>
            <button onClick={()=>{
              deleteArticle(d.id)
            }}>Usuń bezpowrotnie</button>
            </div>
            <div>
            <button onClick={()=>{
              recoverArticle(d.id)
            }}>Przywróć</button>
            </div>
            </>
          }</li>
        ))}
      </ul>
    </div>
  )
}

export default RemovedArticlesList
