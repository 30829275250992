import styles from './InputAbilityCounter.module.css'
import { useState, useEffect, useRef } from 'react'
import CharEditModal from '../CharEditModal'

function InputAbilityCounter({
    disability, 
    path, 
    newAdded,  
    hasAnyFilledChildren, 
    character, 
    savedCharacter, 
    editMode, 
    getValue, 
    setValue, 
    justSaved,
    hint,
    parseLinks,
    maxLength,
    handleSubmit,
    unsaved
}) {


const namePath = `${path}.name`
const valuePath = `${path}.value`
const pricePath = `${path}.price`
const removePath = `${path}.removed`
const pointsPath = 'points'
const arPath = path.split('.')

const [edit, setEdit] = useState(editMode)
const[showHint, setShowHint] = useState(false)

// const [points, setPoints] = useState(0)
const prevEditMode = useRef(false)
const prevJustSaved = useRef(justSaved)
const toFocus = useRef(null)
const anyFilledChildren = getValue(character, disability ? 'disabilities' : 'abilities') 
? hasAnyFilledChildren(character[arPath[0]][arPath[1]]) 
: false

const[showCharEditModal, setShowCharEditModal] = useState(false)

const[charsLeft, setCharsLeft] = useState(maxLength)

const handleConfirm = (item) => {
    let newItemPrice = (Number(item.price)) > 0 ? (Number(item.price)) : 0 
    setShowCharEditModal(false)
    setEdit(false) 
    setValue([
        {
            value: item.name, 
            path: namePath
        },
        {
            value: newItemPrice,
            path: pricePath
        },
        {
            value: Number(getValue(character,'points'))
            - Number(getValue(character, pricePath))                   //price of old item
            + newItemPrice,                                            //price of new item
            path: pointsPath
        }
    ]) 
  }
  const handleClose = () => {
    setShowCharEditModal(false)
  }

let addPointsValue = getValue(character, valuePath) > 0 ? getValue(character, valuePath) +1 : 1

let subPointsValue = getValue(character, valuePath) > 0 ? getValue(character, valuePath) : 1

// console.log('abi: ',addPointsValue)

const cancel = (e) => {

    e && e.preventDefault()
    setValue(
        [{
            value: getValue(savedCharacter, namePath),
            path: namePath
        },
        {
            value: getValue(savedCharacter, valuePath),
            path: valuePath
        },
        {
            value: Number(getValue(character,'points'))
            - Number(getValue(character, pricePath))                   
            + Number(getValue(savedCharacter, pricePath)),                                            
            path: pointsPath
        },
        {
            value: getValue(savedCharacter, pricePath),
            path: pricePath
        }
        ]
        )
    setEdit(false)

}

const remove = (e, hard) => {

    e && e.preventDefault()
    setEdit(false)
    // let p = countSavedPointsFromItem(getValue(character,valuePath))
    let p = getValue(character,pricePath)
    console.log('p:',p)
    setValue(
        [{
            value: '',
            path: namePath
        },
        {
            value: '',
            path: valuePath
        },
        {
            value: '',
            path: pricePath
        },
        {
            value: p >= 0 ? getValue(character,'points') - p : getValue(character,'points') + p,
            path: 'points'
        },
        {
            value: true,
            path: removePath
        }],
        false, hard ? true : false
    )
}

useEffect(() => {
    if(toFocus.current && !editMode) {
        toFocus.current.focus()
    }
    // eslint-disable-next-line
},[edit])


useEffect(() => {

    if(prevJustSaved.current !== justSaved) {
        cancel()    
    }
    else {
        if(!anyFilledChildren && !edit && newAdded) {
            // console.log(path,': FORCED EDIT')
            setEdit(true)
        }
    }

    if(prevEditMode.current !== editMode)
    {
        prevEditMode.current = editMode
        setEdit(editMode)    
        if (prevEditMode.current === false && !anyFilledChildren) {
            cancel()
        }
    }

// eslint-disable-next-line
  },[justSaved, editMode])


  if(edit)
  {  
    return (
        <>
        <div tabIndex='0' className={styles.statCounter}
        onFocus={()=>{
            setTimeout(()=>{
            setShowHint(true)
            },0)
            }} 
        onBlur={()=>{
            setTimeout(()=>{
            setShowHint(false)
        },0) 
        }}
        >
           {showCharEditModal &&
              <CharEditModal 
                handleClose={handleClose} 
                handleConfirm={handleConfirm} 
                confirmText='Wybierz' 
                cancelText='Anuluj'
                theme='ability'
                subtype=''
                universe={getValue(character,'universum')}
              >
                <p></p>
              </CharEditModal>
              }
        <label>
            <div>
                <input 
                    type="text" 
                    onChange={(e)=>{
                        setValue([{value: e.target.value, path: namePath}])
                        setCharsLeft(Number(maxLength) - e.target.value.length)
                    }} 
                    value={getValue(character, namePath)}
                    maxLength={maxLength}
                    ref={toFocus}
                    onFocus={()=>{setCharsLeft(Number(maxLength) - getValue(character, namePath).length)}}
                />
            </div>
            <span>{!isNaN(charsLeft) && charsLeft}</span>
        </label>
        <div className={styles.pointsButtonsBox}>
            <div>
            <button
                className={styles.pointsButton}
                disabled={(!disability && getValue(character, valuePath) < 1) ? true : false}
                onClick={(e)=>{
                e.preventDefault()
                // setPoints(Number(getValue(character, pricePath)) - subPointsValue)
                setValue([
                    {
                        value: Number(getValue(character, valuePath) -1), 
                        path: valuePath
                    },
                    {
                        value: Number(getValue(character, pricePath)) - subPointsValue,
                        path: pricePath
                    }
                ], 'sub', false, subPointsValue)
                }}
                >
                -1
            </button> 
                Zyskasz: {subPointsValue ? subPointsValue : 0} pkt
            </div>
            <div>
                <input 
                    type="number"
                    disabled  
                    value={getValue(character, valuePath)}
                />
            </div>

            <div>
             <button 
                disabled={(disability && getValue(character, valuePath) > -1) ? true : false}
                className={styles.pointsButton}
                onClick={(e)=>{
                e.preventDefault()
                // setPoints(Number(getValue(character, pricePath)) + addPointsValue)
                setValue([
                    {
                        value: Number(getValue(character, valuePath) )+1, 
                        path: valuePath
                    },
                    {
                        value: Number(getValue(character, pricePath)) + addPointsValue,
                        path: pricePath
                    }
                ], 'add', false, addPointsValue)
                }}>
                    +1
             </button>
                Wydasz: {getValue(character, valuePath) > 0 ? getValue(character, valuePath) +1 : 1} pkt
            </div>
            </div>
            <div>{disability ? <div className='lbl'>Zysk:</div> : <div className='lbl'>Koszt:</div>} <span className={styles.priceInput}>{getValue(character, pricePath)}</span></div>
            
            {(edit && unsaved) &&
            <div>
                <button onClick={(e)=> {
                    // e.preventDefault()
                    handleSubmit(e)
                }}>Zapisz</button>
            </div>
            }
            <div>
            {(edit && !editMode) &&
                <button onClick={(e)=>{
                    (anyFilledChildren && Object.keys(getValue(savedCharacter, path)).length > 0) 
                    ? cancel(e) 
                    : remove(e, true)
                }}
                >Anuluj</button>
            }
            </div>
            <div>
            {edit &&
                <button onClick={(e)=> {
                    (anyFilledChildren && Object.keys(getValue(savedCharacter, path)).length > 0) 
                    ? remove(e)
                    : remove(e, true)
                }}>Usuń</button>
            }
            </div>
            <div>
            {(edit && !disability) &&
            <button onClick={(e)=> {
                e.preventDefault()
                setShowCharEditModal(true)
            }}>Inspiracje</button>
            }
            </div>
            
        </div>
        {(hint && parseLinks && showHint) &&
            <div className='hint'>
                <p>{parseLinks(hint)}</p>
            </div>
            }
        </>
        )
    }
    else if(anyFilledChildren) {
        return (
            
                <div 
                    className={styles.ability}
                    onClick={(e) => {
                        e.preventDefault()
                        setEdit(true)
                      }}
                >
                    <div>{getValue(character, namePath)}</div>
                    <div className={styles.price}>{getValue(character, valuePath)}</div>
                    <div>{Number(getValue(character, pricePath)) !== 0 && 
                        <>{disability ? <div className='lbl'>Zysk:</div> : <div className='lbl'>Koszt:</div>} <span className={styles.price}>{getValue(character, pricePath)}</span></>}</div>
                    <div>
                    <button onClick={(e)=> {
                        e.preventDefault()
                        setEdit(true)
                    }}>edytuj</button>
                    </div>
                </div>
            
            
          )
    }
    else {
        // console.log('not displayed', path)
        return (
            <div>{path} - ERROR -</div>
        )
    }
}

export default InputAbilityCounter
