import { createPortal } from 'react-dom'
import styles from './Modal.module.css'
import { useState } from 'react';


function Modal({children, handleClose, handleConfirm, cancelText, confirmText, noConfirm, charEdit}) {

  const [closing, setClosing] = useState(false)

  // console.log('CE',charEdit)

  const close = () => {
    setClosing(true)
    setTimeout(()=>{
      handleClose()
    },200)
  }
  
  document.addEventListener('keydown', evt => {
    if (evt.key === 'Escape') {
      close();
    }
  });

  return createPortal( (
    <div 
      className={styles.modalBcg}
      onClick={()=>{close()}}
    >
        <div 
          className={`${styles['modalBox']} ${closing && styles['close']}`}
          onClick={(e)=>{e.stopPropagation();}}  
        >
          
            <button className={styles.Xcloser} onClick={()=>{close()}}>X</button>
            {children}
            
          <div className={styles.buttons}>
            <button onClick={()=>{close()}}>{cancelText}</button>
            {!noConfirm && <button onClick={handleConfirm}>{confirmText}</button>}
          </div>
        </div>
    </div>
  ), document.body)
}

export default Modal
