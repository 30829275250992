import parse from 'html-react-parser';



function parseForPrint(text) {
    const options = {
      replace: ({ name, attribs, children }) => {
        if (name === 'a' && attribs.href && !attribs.href.startsWith('http')) {
          return children;
        }
      }
    };
        
    return parse(text, options);
  }


function PrintContent({field, articles, fieldFilters}) {

    // console.log(fieldFilters)
    return (
        <div>

        {fieldFilters[field] && <h1>{field}</h1>}
        <ul> 
            {articles && articles.map(d => (
             fieldFilters[field]
                &&  
            <div key={d.id}>
                <h2>{d.title}</h2>
                <div>{parseForPrint(d.content)}</div>
            
            </div>
            ))}
        </ul>
        </div>
    )
}

export default PrintContent
