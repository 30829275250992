import { db } from '../firebase/config'
import { doc, setDoc, collection } from 'firebase/firestore'
import { useState } from 'react'
import LexiconItemEdition from './LexiconItemEdition.js'

function LexiconsNewItem({
  theme, 
  handleSetNewItem, 
  setProperty
}) {   

const [item, setItem] = useState({})

const saveItem = async (item) => {
    item.type = theme      
    const ref = doc(collection(db, 'items'))
    await setDoc(ref, item)         
}

const handleSetItem = (v) => {
  setItem(v)
}

const clearAll = () => {
    setItem({})
} 


if(item) {
  return (
    <LexiconItemEdition 
      itm={item} 
      theme={theme} 
      clearAll={clearAll} 
      handleSetItem={handleSetItem} 
      setProperty={setProperty} 
      saveItem={saveItem}
      handleSetNewItem={handleSetNewItem} 
    />
  )
}
}

export default LexiconsNewItem
