import { useState } from "react"
import styles from "./DiceRoll.module.css"
import { forceNumber as n } from '../misc/CommonFunctions';

function DiceRoll() {

const [roll, setRoll] = useState({})
const [result, setResult] = useState({})
const [modifier, setModifier] = useState(0)
const [newRoll, setNewRoll] = useState(false)

const getRoll = () => {
    let r = {}
    r.dice = Math.floor((Math.random() * 6) + 1)
    r.secondDie = Math.floor((Math.random() * 6) + 1)
    r.luck =
        (r.dice === r.secondDie) ? true
        : false 
    setRoll(r)
    return r
}

const getResult = (newRoll, mod) => {
    let r = {} 
    if(newRoll) {
        r = getRoll()
    }
    else {
        r = roll
    }
    r.modifier = r.luck ? 0 : n(modifier) + n(mod)
    r.modified = r.luck ? n(r.dice) : n(r.dice) + n(modifier) + n(mod)
    r.interpretation =
        r.luck ? 'LOSOWE ' 
        : '' 
    r.interpretation += 
        r.modified <= 1 ? 'NIE I...'
        : r.modified === 2 ? 'NIE'
        : r.modified === 3 ? 'NIE, ALE...'
        : r.modified === 4 ? 'TAK, ALE...'
        : r.modified === 5 ? 'TAK'
        : 'TAK I...'
    if(mod) {
        setModifier(n(modifier) + n(mod))
    }
    setResult(r)
}

  return (
    <div className={styles.DiceRoll}>
        <div className={styles.controls}>
            <div className={styles.modifierControl}>
                <h5>Premia / Kara</h5>
                <div className={styles.modInputWrapper}>
                    <button
                        className={styles.pointsButton}
                        onClick={()=>{ 
                            if(Number(modifier) > -5) {
                                getResult(false, -1)
                                // setResult({})
                            }
                        }}
                    >-</button>
                    <label className={styles.modInputLabel}>
                        <input 
                            className={styles.modInput} 
                            type='text'
                            disabled={true}
                            value={modifier}
                            // onChange={(e)=>{setModifier(e.target.value)}}
                        />
                    </label>
                    <button
                        className={styles.pointsButton}
                        onClick={()=>{ 
                            if(Number(modifier) < 5) {
                                    getResult(false, 1)
                                // setResult({})
                            }
                        }}
                    >+</button>
                </div>
                <div>
                <button
                    onClick={()=>{ 
                        setModifier(Number(0)) 
                        setResult({})
                        setRoll({})
                    }}
                    >Reset</button>
                </div>
            </div>
            
        </div>
        <div>
            <button
                disabled={newRoll}
                className={styles.castButton}
                onClick={()=>{ 
                    setNewRoll(true)
                    !newRoll &&
                        getResult(true) 
                        setTimeout(()=>{
                            setNewRoll(false)
                        },1000)
                }}
            >Rzuć Kostkami</button>
        </div>
        {roll.dice > 0 &&
        <div className={styles.result}>
            <h4>Rezultat:</h4>
            {result.luck 
                ? <div className={`${styles.modifiedDiceResult} ${newRoll && styles.fadeIn2}`}>
                    <div className={`${styles['die'+roll.dice]} ${newRoll && styles.dice1FadeIn}`}></div> 
                    <div className={`${styles['die'+roll.secondDie]} ${newRoll && styles.dice1FadeIn}`}></div>
                  </div>
                : !result.modifier &&
                <div className={`${styles.modifiedDiceResult} ${newRoll && styles.fadeIn2}`}>
                    <div className={`${styles['die'+roll.dice]} ${newRoll && styles.dice1FadeIn}`}></div> 
                    <div className={`${styles['secondDie'+roll.secondDie]} ${newRoll && styles.dice2FadeIn}`}></div>
                </div>
            }
            
            
            {(n(result.modifier) !== 0 && !result.luck) &&
            <div className={styles.modifiedDiceResult}>            
                <div className={`${styles['die'+roll.dice]} ${newRoll && styles.dice1FadeIn}`}>
                    
                    </div> 

                    {n(result.modifier) === 0 &&
                    <></>
                }
                {n(result.modifier) < 0 &&
                    <div className={`${styles.resultMod} ${newRoll && styles.dice1FadeIn}`}>- {result.modifier !== 0 && Math.abs(result.modifier)}</div>
                }
                {n(result.modifier) > 0 &&
                    <div className={`${styles.resultMod} ${newRoll && styles.dice1FadeIn}`}>+ {result.modifier !== 0 && result.modifier}</div>
                }
                

                <div className={`${styles['secondDie'+roll.secondDie]} ${newRoll && styles.dice2FadeIn}`}>
                
    
                </div>
            </div>
            }
            {<h4 className={`${styles.resltNumber} ${newRoll && styles.fadeIn3}`}><span className={styles.equals}>=</span> {roll.luck ? roll.dice : result.modified}</h4>}
            <h1 className={`${styles[
                roll.luck ? 
                result.modified > 3 ? 'goodLuck' : 'badLuck'
                : 
                result.modified > 3 ? 'good' : 'bad'
            ]} ${newRoll && styles['fadeIn3']}`}><span className={styles.equals}>=</span> {result.interpretation}</h1>
        </div>
        }
    </div>
  )
}

export default DiceRoll
