import { useGetListFromDB } from '../hooks/useGetListFromDB'; 
import styles from './Listing.module.css'
import { useNavigate } from 'react-router-dom'
import CharactersSublist from './CharactersSublist';
import { useEffect, useState } from 'react';
import Loading from './Loading';
import { setTopMargin } from '../misc/CommonFunctions';
import { safeSearch } from '../misc/CommonFunctions';

function CharactersList({userIsAdmin}) {
  const navigate = useNavigate()
  const category = 'characters'
  let checkOwner = true
  if(userIsAdmin){ checkOwner = false }
  const { data : chars } = useGetListFromDB(category,['removed','==',false],checkOwner)
  const [search, setSearch] = useState('')
  const [universeFilters, setUniverseFilters] = useState({})
  const [inContent, setInContent] = useState(false)
  const universes = new Set()
  const { data : usersInDB } = useGetListFromDB('users',['removed','==',false])

  // console.log('checkOwner',checkOwner)

  chars && chars.forEach((d)=>{
    d.universum !== '' && universes.add(d.universum)
  })  

  const getSortedCharacters = (data, universum) => {
    let characters = new Set()
    data.forEach((d)=>{
      d.universum === universum && characters.add(d)
    })
    let sortedCharacters = Array.from(characters).sort()
    return sortedCharacters
  }

  const getSortedUniverses = (universes) => {
    let allSorted =  Array.from(universes).sort()
    return allSorted
  }

  const updateUniverseFilter = (v, c) => {
    const newFilters = {...universeFilters}
    newFilters[v] = c
    setUniverseFilters(newFilters)
  }

  // useEffect(()=>{
  //   setTopMargin()
  // },[])

//ALL FILTERS CHECKED AT START:
  useEffect(() => {
    if(chars && universes.size > 0){
      let newFilters = {}
      universes.forEach((u)=>{
        newFilters[u] = true
      })
      setUniverseFilters(newFilters)
    }
    // eslint-disable-next-line
  },[chars])


  useEffect(()=>{
    setTopMargin()
  })

  if(chars && chars.length > 0)
  {
    
    const sortedUniverses = getSortedUniverses(universes)
    return (
     <>
      <nav className='tools'>
      <div className={styles.Filters}>
          <div className={styles.search}>
            <label>
            <span>Szukaj:</span>
            <input 
              type='text' 
              value={search} 
              onChange={(e)=>{setSearch(safeSearch(e.target.value))}} 
            />
            {search && <button onClick={()=>{setSearch('')}}>X</button>}
            </label>
            <label><input 
              type="checkbox"
              onChange={(e)=>{setInContent(e.target.checked)}}
              value={inContent}     
            />Szukaj w treści</label>
          </div>
          <div className={styles.checkboxes}>
            <span className={styles.labelTitle}>Świat:</span>
            {sortedUniverses && sortedUniverses.map(d => (
              <label key={d}>
                <input 
                  type='checkbox' 
                  checked={universeFilters[d] ? universeFilters[d] : false}
                  onChange={(e)=>{updateUniverseFilter(e.target.value, e.target.checked)}}
                  value={d}
                />
                <span>{d}</span>
              </label>
            ))}
          </div>
        </div>
        {<button onClick={()=>{
          navigate(`/characters/newcharacter`, {state: { newCh: true}})
        }}>Stwórz Postać</button>}
      </nav>
      <div className={styles.Listing}>
        <h2>Postacie</h2>
        
        {sortedUniverses && sortedUniverses.map((d) => {
            const c = getSortedCharacters(chars, d)
            return(
              <CharactersSublist 
                key={d} 
                universum={d} 
                characters={c} 
                search={search} 
                inContent={inContent} 
                universeFilters={universeFilters} 
                usersInDB = {usersInDB}
              />
            )
        })}
        
      </div>
      </>
    )
   }
   else if(chars && chars.length === 0) {
    return (
     <div className={styles.noCharacters}>
        <h2>Nie masz żadnych postaci</h2>
        <div>
          <button onClick={()=>{
            navigate(`/characters/newcharacter`, {state: { newCh: true}})
          }}>
            Stwórz Postać
          </button>
        </div>
      </div>
    )
   }
   else if(!chars) {
    return (
      <Loading />
    )
   }
}

export default CharactersList
