import parse, { domToReact } from 'html-react-parser';
import { Link } from 'react-router-dom'

export function parseWithLinks(text) {
    const options = {
      replace: ({ name, attribs, children }) => {
        if (name === 'a' && attribs.href && !attribs.href.startsWith('http')) {
            const linkAttribs = {};
            if (attribs.target === '_blank') {
                linkAttribs.target = '_blank';
            }
            return <Link to={attribs.href} {...linkAttribs}>
                {domToReact(children)}
                </Link>;
        }
      }
    };
        
    return parse(text, options);
  }

export const setTopMargin = () => {
    let r = document.querySelector(':root')
    let margin = 0
    let head = document.getElementById('appHeader')
    let tools = document.getElementsByClassName('tools')
    let h = head ? head.offsetHeight : 0
    let tls = tools.length > 0 ? tools[0].offsetHeight : 0
    margin = h + tls + 20
    r.style.setProperty('--appMarginTop', margin+'px');
} 

export const setDraftVisible = () => {
    let r = document.querySelector(':root')
    r.style.setProperty('--draftDisplay', 'inline');
}

export const searchCheck = (t, searchRegExp) => {
    return searchRegExp.test(t)
}

export const getAllValues = (obj, s) => {
    let o = {...obj}
    o.owner = ''
    o.id = ''
    if(o.appearance){o.appearance.portrait = ''}
    let ar = Object.values(o)
    let output = []
    if(!s){ s = [] }
    output.concat(s)
    ar.forEach((i)=>{
        if(typeof i === 'object')
        {
            output.push(getAllValues(i, output))
        }
        else if(typeof i === 'string') {
            output.push(i)
        }
    })
    return output.flat()
}


export const deepSearchCheck = (o, rExp) => {
    let output = false
    getAllValues(o).forEach((v)=>{
        if(typeof v === 'string' && v.length > 1 &&
            searchCheck(v, rExp)    
        ){
            output = true
            return
        }
    })
    return output
}

export const safeSearch = (s) => {
    let r = s
    r = r.replace('\\')
    r = r.replace('[','')
    r = r.replace(']','')
    r = r.replace('*','')
    r = r.replace('(','')
    r = r.replace(')','')
    r = r.replace('?','')
    r = r.replace('+','')
    return r 
  }

  export const forceNumber = (n) => {
    return Number(n ? n : 0)
  }

  //przetestować
  
//   export const updateObj = (obj, key, prop) => {
//     const newObj = {...obj, key:prop}
//     return newObj
//   }