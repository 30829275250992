import { doc, updateDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom'
import { db } from '../firebase/config'
import { useState } from 'react';
import Modal from '../components/Modal';
import { useAuthContext } from '../hooks/useAuthContext';

const deleteArticle = async (id) => {
    const ref = doc(db, 'articles', id)
    await updateDoc(ref, {
      removed: true
    })
  }

const sortBy = (arr, prop) => {
    return arr.sort((a, b) => (a[prop] > b[prop]) ? 1 : -1) 
}



function ArticlesSublist({field, articles, search, inContent, fieldFilters}) {
    const[showModal, setShowModal] = useState(false)
    const[itemToDelete, setItemToDelete] = useState('')
    const { userIsAdmin } = useAuthContext()

    const handleClose = () => {
        setShowModal(false)
    }
    const handleConfirm = () => {
        console.log(itemToDelete)
        deleteArticle(itemToDelete.id)
        setShowModal(false)
    }
    const toDelete = (item) => {
        setItemToDelete(item)
        setShowModal(true)
    }

    const searchRegExp = new RegExp(search, 'i')

    const searchCheck = (t) => {
        return searchRegExp.test(t)
    }
    return (
        <div>
        {showModal &&
            <Modal handleClose={handleClose} handleConfirm={handleConfirm} cancelText='Anuluj' confirmText='Usuń'>
            <p>Na pewno usunąć artykuł {itemToDelete.name}?</p>
            </Modal>
        }
        {fieldFilters[field] && <h3>{field}</h3>}
        <ul> 
            {articles && sortBy(articles,'title').map(d => (
            ((!search || (
                inContent 
                ? (searchCheck(d.title) || searchCheck(d.content)) 
                : searchCheck(d.title))) && fieldFilters[field]) 
                &&  
            <li key={d.id}>
                <div>
                    <Link to={`/${'articles'}/${d.id}`}>{d.title}</Link>
                </div>
                {userIsAdmin && 
                <>
                <div>
                    <button onClick={()=>{
                    navigator.clipboard.writeText(`<a href='/articles/${d.id}'>${d.title}</a>`)
                    }}>
                    Kopiuj link
                    </button>
                </div>
                <div>
                    <button onClick={()=>{
                    navigator.clipboard.writeText(`/articles/${d.id}`)
                    }}>
                    Kopiuj URL
                    </button>
                </div>
                <div>
                    <button onClick={()=>{
                    toDelete(d)
                    }}>Usuń</button>
                </div>
                </>
                }
            </li>
            ))}
        </ul>
        </div>
    )
}

export default ArticlesSublist
