import { useState } from 'react'
import stls from './LexiconItemPreview.module.css'
import modalStyles from './LexiconItemPreviewModal.module.css'
import { parseWithLinks } from '../misc/CommonFunctions';

function LexiconItemPreview({
    itm:item, 
    theme,
    setProperty,
    fixedUniverse,
    handleConfirmChoice,
    inModal
}) {

const styles = inModal ? modalStyles : stls

const n = (n) => {
  return Number(n ? n : 0)
}

const getPtsMod = (type, action, stat) => {
  // console.log('mPrice:',n(mods.price),'iPrice:',n(item.price))
  let v = n(mods.price)     //cena po zmianie
  let p = 0                 //o ile zmieni się cena
  if(type === 'range'){
    p = 2
  }
  if(type === 'stat'){
    if(n(item[stat])<0) {
    //oddzielny wzór dla przedmiotów, które domyślnie mają ujemny stat (np. broń strzelecka)
      p = (3+n(item[stat])) + n(mods[stat]) + (action === 'add' ? 1 : 0)
      // console.log('p<0: mStat',n(mods[stat]),'+',(action === 'add' ? 1 : 0),'=',p)
    }else {
      p = n(item[stat]) + n(mods[stat]) + (action === 'add' ? 1 : 0)
      // console.log('p: iStat:',n(item[stat]),'mStat',n(mods[stat]),'+',(action === 'add' ? 1 : 0),'=',p)
    }
  }
  action === 'add'
    ? v = v + p
    : v = v - p
  // console.log(p)
  return v
}

const displayModsUI = (stat, action) => {
  let r = false
  // item.name === 'Miecz' && console.log(item['speed_max_upgreade'])
  if(action === 'add' 
  ? (n(item[stat]) + n(mods[stat])) < n(item[stat+'_max_upgrade']) && !isNaN(n(item[stat+'_max_upgrade']))
  : (n(item[stat]) + n(mods[stat])) > n(item[stat+'_max_downgrade']) && !isNaN(n(item[stat+'_max_downgrade']))
  ) 
  {
    // item.name === 'Miecz' && console.log(action)
    r = true
  }
  return r
}

const [mods, setMods] = useState({})


// item.name === 'Miecz' && console.log(mods)

  return (
  <div className={styles.item}>
    {inModal ? <h2>{item.name}</h2> : <h3>{item.name}</h3>}

    {!fixedUniverse &&
    <div className={styles.universesWrapper}>
      <span>Światy:</span>
      <div className={styles.universes}>
        {item.Faerion && <span>Faerion</span>}
        {item.Malropa && <span>Malropa</span>}
      </div>
      <div className={styles.universes}>
        {item.Messianica && <span>Messianica</span>}
        {item.Triamonda && <span>Triamonda</span>}
      </div>
    </div>    
    }
    <div>
      {item.imageUrl && <img alt={item.name} src={item.imageUrl} />}
    </div>
    <div>
      {//item.price &&
      <>
          <span>Koszt (pkt):</span> <span className={styles.amnt}>{(n(item.price)+n(mods.price)) > 0 ? (n(item.price)+n(mods.price)) : 0}</span>
          {/* {console.log('iPrice: ',n(item.price),'mPrice:',mods.price)} */}
      </>    
      }
    </div>
  
    {(theme === 'weapon' && item.subtype === 'weapon') &&
    <div>
      <span>Szybkość:</span><span className={styles.amnt}>{n(item.speed) + n(mods.speed)}</span>
      {displayModsUI('speed', 'add') &&
      <>
        <button
            className={styles.pointsButton}
            onClick={(e) => {
                e.preventDefault()
                setMods(
                  setProperty(
                    setProperty(mods, 'price', getPtsMod('stat','add','speed'))  
                  , 'speed', n(mods.speed) + 1)
                )
            }}
        >+1</button>
      </>
      } 
      {displayModsUI('speed', 'sub') &&
      <>
        <button
            className={styles.pointsButton}
            onClick={(e) => {
                e.preventDefault()
                setMods(
                  setProperty(
                    setProperty(mods, 'price', getPtsMod('stat','sub','speed'))  
                  , 'speed', n(mods.speed) - 1)
                )
            }}
        >-1</button>
      </>
      } 
    </div>
    }

{(theme === 'weapon' && (item.subtype === 'weapon' || item.subtype === 'shield')) &&
    <>
    <div className={styles.range}><span>Zasięg: </span>
      <span>
      <span>
      <span className={styles.rangeSlot}>{((item.range && item.range.short) || (mods.range && mods.range.short)) && <div className={styles.rangeFill}></div>}</span> 
      <input 
          type="checkbox"
          disabled={(item.range && item.range.short_variant) ? false : true}
          onChange={(e)=>{
            setMods(
              setProperty(
                setProperty(mods, 'price', getPtsMod('range',e.target.checked ? 'add' : 'sub'))
            , 'range.short', e.target.checked)
            )
          }}
          checked={((item.range && item.range.short)||(mods.range && mods.range.short)) ? true : false}
      />
      </span>
      <span>
      <span className={styles.rangeSlot}>{((item.range && item.range.medium) || (mods.range && mods.range.medium)) && <div className={styles.rangeFill}></div>}</span> 
      <input 
          type="checkbox"
          disabled={(item.range && item.range.medium_variant) ? false : true}
          onChange={(e)=>{
            setMods(
              setProperty(
                setProperty(mods, 'price', getPtsMod('range',e.target.checked ? 'add' : 'sub'))
            , 'range.medium', e.target.checked)
            )
          }}
          checked={((item.range && item.range.medium)||(mods.range && mods.range.medium)) ? true : false}
      />
      </span>
      <span>
      <span className={styles.rangeSlot}>{((item.range && item.range.long) || (mods.range && mods.range.long)) && <div className={styles.rangeFill}></div>}</span> 
      <input 
          type="checkbox"
          disabled={(item.range && item.range.long_variant) ? false : true}
          onChange={(e)=>{
            setMods(
              setProperty(
                setProperty(mods, 'price', getPtsMod('range',e.target.checked ? 'add' : 'sub'))
            , 'range.long', e.target.checked)
            )
          }}
          checked={((item.range && item.range.long)||(mods.range && mods.range.long)) ? true : false}
      />
      </span>
      </span>
    </div>
    </>
    }


  {(theme === 'weapon' && item.subtype === 'ranged_weapon') &&
<>
    <div>
    <span>Celność:</span> <span className={styles.amnt}> {n(item.accuracy) + n(mods.accuracy)} </span>
      {displayModsUI('accuracy', 'add') &&
      <>
        <button
            className={styles.pointsButton}
            onClick={(e) => {
                e.preventDefault()
                setMods(
                  setProperty(
                    setProperty(mods, 'price', getPtsMod('stat','add','accuracy'))  
                  , 'accuracy', n(mods.accuracy) + 1)
                )
            }}
        >+1</button>
      </>
      } 
    {displayModsUI('accuracy', 'sub') &&
      <>
        <button
            className={styles.pointsButton}
            onClick={(e) => {
                e.preventDefault()
                setMods(
                  setProperty(
                    setProperty(mods, 'price', getPtsMod('stat','sub','accuracy'))  
                  , 'accuracy', n(mods.accuracy) - 1)
                )
            }}
        >-1</button>
      </>
      } 
    </div>

<div>
    <span>Przeładowanie:</span> <span className={styles.amnt}> {(n(item.reload) + n(mods.reload)) ? n(item.reload) + n(mods.reload) : '---'}</span>
      {displayModsUI('reload', 'add') &&
      <>
        <button
            className={styles.pointsButton}
            onClick={(e) => {
                e.preventDefault()
                setMods(
                  setProperty(
                    setProperty(mods, 'price', getPtsMod('stat','add','reload'))  
                  , 'reload', n(mods.reload) + 1)
                )
            }}
        >+1</button>
      </>
      } 
    {displayModsUI('reload', 'sub') &&
      <>
        <button
            className={styles.pointsButton}
            onClick={(e) => {
                e.preventDefault()
                setMods(
                  setProperty(
                    setProperty(mods, 'price', getPtsMod('stat','sub','reload'))  
                  , 'reload', n(mods.reload) - 1)
                )
            }}
        >-1</button>
      </>
      } 
</div>

</>

  }


{(theme === 'weapon' && (item.subtype === 'shield' || item.subtype === 'armor')) &&
    <div>
      <span>Stopień opancerzenia:</span> <span className={styles.amnt}>{n(item.armor_level) + n(mods.armor_level)}</span>
      {displayModsUI('armor_level', 'add') &&
      <>
        <button
            className={styles.pointsButton}
            onClick={(e) => {
                e.preventDefault()
                setMods(
                  setProperty(
                    setProperty(mods, 'price', getPtsMod('stat','add','armor_level'))  
                  , 'armor_level', n(mods.armor_level) + 1)
                )
            }}
        >+1</button>
      </>
      } 
    {displayModsUI('armor_level', 'sub') &&
      <>
        <button
            className={styles.pointsButton}
            onClick={(e) => {
                e.preventDefault()
                setMods(
                  setProperty(
                    setProperty(mods, 'price', getPtsMod('stat','sub','armor_level'))  
                  , 'armor_level', n(mods.armor_level) - 1)
                )
            }}
        >-1</button>
      </>
      } 
    </div>
    }
    {(theme === 'weapon' && item.subtype === 'camouflage') &&
    <div>
    <span>Kamuflaż:</span> <span className={styles.amnt}>{n(item.camouflage) + n(mods.camouflage)}</span>
    {displayModsUI('camouflage', 'add') &&
      <>
        <button
            className={styles.pointsButton}
            onClick={(e) => {
                e.preventDefault()
                setMods(
                  setProperty(
                    setProperty(mods, 'price', getPtsMod('stat','add','camouflage'))  
                  , 'camouflage', n(mods.camouflage) + 1)
                )
            }}
        >+1</button>
      </>
      } 
    {displayModsUI('camouflage', 'sub') &&
      <>
        <button
            className={styles.pointsButton}
            onClick={(e) => {
                e.preventDefault()
                setMods(
                  setProperty(
                    setProperty(mods, 'price', getPtsMod('stat','sub','camouflage'))  
                  , 'camouflage', n(mods.camouflage) - 1)
                )
            }}
        >-1</button>
      </>
      } 
    </div>
    }
      {(theme === 'equipment') &&
      <div>
        <p>Kompetencja: {item.modifiedCompetence}</p>
        <p>Premia / kara: {item.modifier}</p>
      </div>      
      }
      
      <div>
      <span>Opis:</span>
      <span>{item.description && parseWithLinks(item.description)}</span>
      </div>

      {handleConfirmChoice &&
        <button
          onClick={(e)=>{
            e.preventDefault()
            handleConfirmChoice(item, mods)
          }}
        >
          Wybierz
        </button>
      } 

  </div>
  )
}

export default LexiconItemPreview
