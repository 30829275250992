import { Link } from 'react-router-dom'
import styles from './Home.module.css'
import lStyles from '../components/Listing.module.css'

function importAll(r) {
    let downloads = {};
     r.keys().forEach((item) => { downloads[item.replace('./', '')] = r(item); });
    return downloads
   }

const downloads = importAll(require.context('../download', false));

function Download() {
  return (
    <div className={styles.HomeContent}>
      <div className={lStyles.Listing}>
      <h2>Do pobrania</h2>
      <h4>Materiały</h4>
      <ul>
      {
        Object.keys(downloads).map((k, i) => {
            return (
                <li key={i}>
                    <a href={downloads[k]}>{k}</a>
                </li>
            )
          })
      }
        </ul>
        <h4>Pobierz podręcznik</h4>
        <ul>
          <li><Link to='/print'>Wybór treści</Link></li>
        </ul>
        <h4>Przykłady rozgrywki</h4>
        <ul>
          <li>Nagrania (tu będą)</li>
        </ul>
      </div>
    </div>
  )
}

export default Download
