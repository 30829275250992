import { useState, useEffect, useRef } from 'react'
import CharEditModal from '../CharEditModal'
import styles from './InputMiscUtensilia.module.css'
import { forceNumber } from '../../misc/CommonFunctions'

function InputMiscUtensilia({
    path, 
    character, 
    newAdded, 
    savedCharacter, 
    hasAnyFilledChildren, 
    editMode, 
    getValue, 
    setValue, 
    justSaved,
    hint,
    parseLinks,
    maxLength,
    maxLength2,
    handleSubmit,
    unsaved
}) {

const[charsLeft, setCharsLeft] = useState(maxLength)
const[charsLeft2, setCharsLeft2] = useState(maxLength2)
const [edit, setEdit] = useState(editMode)

// console.log('PATH: ',path)

const namePath = `${path}.name`
const statPath = `${path}.stat`
const bonusPath = `${path}.bonus`
const removePath = `${path}.removed`
const pointsPath = 'points'
const pricePath = `${path}.price`
const arPath = path.split('.')

const prevEditMode = useRef(false)
const prevJustSaved = useRef(justSaved)
const toFocus = useRef(null)
const anyFilledChildren = getValue(character, path) 
? hasAnyFilledChildren(character[arPath[0]][arPath[1]]) 
: false

const[showHint, setShowHint] = useState(false)

const[showCharEditModal, setShowCharEditModal] = useState(false)

const handleConfirm = (item) => {
    setShowCharEditModal(false)  
    setEdit(false)
    let newItemPrice = (Number(item.price)) > 0 ? (Number(item.price)) : 0 
    setValue([
        {
            value: item.name, 
            path: namePath
        },
        {
            value: newItemPrice,
            path: pricePath
        },
        {
            value: item.modifiedCompetence,
            path: statPath
        },
        {
            value: forceNumber(item.modifier),
            path: bonusPath
        },
        {
            value: Number(getValue(character,'points'))
            - Number(getValue(character, pricePath))                   //price of old item
            + newItemPrice,                                            //price of new item
            path: pointsPath
        }
    ]) 
  }
  const handleClose = () => {
    setShowCharEditModal(false)
  }

const cancel = (e) => {
    // console.log('cancel')
    e && e.preventDefault()
    setValue([
        {
            value: getValue(savedCharacter, namePath),
            path: namePath
        },
        {
            value: getValue(savedCharacter, statPath),
            path: statPath
        },
        {
            value: getValue(savedCharacter, bonusPath),
            path: bonusPath
        },
        {
            value: Number(getValue(character,'points'))
            - Number(getValue(character, pricePath))                   
            + Number(getValue(savedCharacter, pricePath)),                                            
            path: pointsPath
        },
        {
            value: getValue(savedCharacter, pricePath),
            path: pricePath
        }
    ])
    setEdit(false)

}

const remove = (e, hard) => {
    // console.log('remove')
    e && e.preventDefault()
    let p = getValue(character,pricePath)
    setValue(
        [{
            value: '',
            path: namePath
        },
        {
            value: '',
            path: statPath
        },
        {
            value: '',
            path: bonusPath
        },
        {
            value: '',
            path: pricePath
        },
        {
            value: p >= 0 ? getValue(character,'points') - p : getValue(character,'points') + p,
            path: 'points'
        },
        {
            value: true,
            path: removePath
        }
        ],
        false, hard ? true : false
    )
    setEdit(false) 
}

useEffect(() => {
    if(toFocus.current && !editMode) {
        toFocus.current.focus()
    }
    setCharsLeft(Number(maxLength) - getValue(character,namePath).length)
    setCharsLeft2(Number(maxLength2) - getValue(character,statPath).length)
    // eslint-disable-next-line
},[edit])

useEffect(() => {

    if(prevJustSaved.current !== justSaved) {
        cancel()    
    }
    else {
        if(!anyFilledChildren && !edit && newAdded) {
            // console.log(path,': FORCED EDIT')
            setEdit(true)
        }
    }

    if(prevEditMode.current !== editMode)
    {
        prevEditMode.current = editMode
        setEdit(editMode)    
        if (prevEditMode.current === false && !anyFilledChildren) {
            cancel()
        }
    }
// eslint-disable-next-line
  },[justSaved, editMode])




    
  if(edit)
  {  
    return (
        <>
        <div className={styles.MiscEdit}>
            {showCharEditModal &&
              <CharEditModal 
                handleClose={handleClose} 
                handleConfirm={handleConfirm} 
                confirmText='Wybierz' 
                cancelText='Anuluj'
                theme='equipment'
                universe={getValue(character,'universum')}
              >
                <p></p>
              </CharEditModal>
            }
        <label>
            
        <div className={styles.miscItemsHeaders}>Przedmiot:</div>

            <input 
                type='text'
                onChange={(e)=>{
                    setValue([{value: e.target.value, path: namePath}])
                    setCharsLeft(Number(maxLength) - e.target.value.length)
                }} 
                value={getValue(character,namePath)} 
                maxLength={maxLength}
                ref={toFocus} 
                onFocus={()=>{
                    setTimeout(()=>{
                    setShowHint(true)
                    },250)
                    }} 
                onBlur={()=>{
                    setTimeout(()=>{
                    setShowHint(false)
                },200) 
                }}
            />
        <span>{charsLeft}</span>
        </label>
        <label>

        <div className={styles.miscItemsHeaders}>Komp./Zdoln.:</div>
 
            <input 
                type='text'
                onChange={(e)=>{
                    setValue([{value: e.target.value, path: statPath}])
                    setCharsLeft2(Number(maxLength2) - e.target.value.length)
                }} 
                value={getValue(character,statPath)} 
                maxLength={maxLength2}
                onFocus={()=>{
                    setTimeout(()=>{
                    setShowHint(true)
                    },250)
                    }} 
                onBlur={()=>{
                    setTimeout(()=>{
                    setShowHint(false)
                },200) 
                }}
            />
        <span>{charsLeft2}</span>
        </label> 
        <label>

        <div className={styles.miscItemsHeaders}>Premia/kara:</div>
            <input 
                type='number'
                onChange={(e)=>{setValue([{value: Number(e.target.value), path: bonusPath}])}} 
                value={getValue(character,bonusPath)} 
                disabled={true}
                onFocus={()=>{
                    setTimeout(()=>{
                    setShowHint(true)
                    },250)
                    }} 
                onBlur={()=>{
                    setTimeout(()=>{
                    setShowHint(false)
                },200) 
                }}
            />
             <button
                        className={styles.pointsButton}
                        onClick={(e)=>{
                            e.preventDefault()
                            setValue([
                            {
                                value: Number(getValue(character, bonusPath)) +1,
                                path: bonusPath
                            }
                            ])
                        }}
                    >+</button>
                    <button
                        className={styles.pointsButton}
                        onClick={(e)=>{
                            e.preventDefault()
                            setValue([
                            {
                                value: Number(getValue(character, bonusPath)) -1,
                                path: bonusPath
                            }
                            ])
                        }}
                    >-</button>
        </label>
        <label><div className='lbl'>Koszt:</div> 
                {/* {console.log(character)} */}
                    <input 
                        type="number"
                        disabled={true}
                        value={getValue(character, pricePath)} 
                    />
                    <button
                        className={styles.pointsButton}
                        onClick={(e)=>{
                            e.preventDefault()
                            setValue([{
                                value: Number(getValue(character,'points'))+1, 
                                path: pointsPath
                            },
                            {
                                value: Number(getValue(character, pricePath))+1,
                                path: pricePath
                            }
                            ])
                        }}
                    >+</button>
                    {Number(getValue(character, pricePath)) > 0 &&
                    <button
                        className={styles.pointsButton}
                        onClick={(e)=>{
                            e.preventDefault()
                                setValue([{
                                    value: Number(getValue(character,'points') -1), 
                                    path: pointsPath
                                },
                                {
                                    value: Number(getValue(character, pricePath) -1),
                                    path: pricePath
                                }
                                ])
                        }}
                    >-</button>
                    }
            </label>
            <div>
            {(edit && unsaved) &&
      <div>
          <button onClick={(e)=> {
              // e.preventDefault()
              handleSubmit(e)
          }}>Zapisz</button>
      </div>
      }
        {(edit && !editMode) &&
            <div>
            <button onClick={(e)=>{
                (anyFilledChildren && Object.keys(getValue(savedCharacter, path)).length > 0) 
                ? cancel(e) 
                : remove(e, true)
            }}
            >Anuluj</button>
            </div>
        }
        {edit &&
            <div>
            <button onClick={(e)=> {
                (anyFilledChildren && Object.keys(getValue(savedCharacter, path)).length > 0) 
                ? remove(e)
                : remove(e, true)
            }}>Usuń</button>
            </div>
        }
        {edit &&
            <div>
            <button onClick={(e)=> {
                e.preventDefault()
                setShowCharEditModal(true)
            }}>Inspiracje</button>
            </div>
        }
        </div>
        
        </div>
       
       {(hint && parseLinks && showHint) &&
        <div className='hint'>
          <p>{parseLinks(hint)}</p>
        </div>
        }
        </>
    
        )
    }
    else if(anyFilledChildren) {
        return (
            <div 
                className={styles.Misc}
                onClick={(e) => {
                    e.preventDefault()
                    setEdit(true)
                  }}
            >

              <div>
                <div className={styles.miscItemsHeaders}>Przedmiot:</div>
                <div>{getValue(character, namePath)}:</div>
              </div> 
              <div>
                <div className={styles.miscItemsHeaders}>Komp./Zdoln.:</div>
                <div>{getValue(character, statPath)}:</div>
              </div> 
              <div>
                <div className={styles.miscItemsHeaders}>Premia/kara:</div>
                <span className={styles.price}>{getValue(character, bonusPath)}</span>
              </div>
              <div>{Number(getValue(character, pricePath)) !== 0 && 
                <><div className='lbl'>Koszt:</div> <span className={styles.price}>{getValue(character, pricePath)}</span></>
               }</div>
              <div>
              <button onClick={(e)=> {
                e.preventDefault()
                setEdit(true)
              }}>edytuj
              </button>
              </div>
            </div>
          )
    }
}

export default InputMiscUtensilia
