import { useState, useEffect, useRef } from "react"
import Select from 'react-select'
import { selectClassNames } from '../../misc/SelectClassNames';
import styles from './InputResource.module.css'

function InputResource({
    ammo, 
    path, 
    character, 
    newAdded,
    savedCharacter, 
    hasAnyFilledChildren, 
    editMode, 
    getValue, 
    setValue, 
    justSaved,
    hint,
    parseLinks,
    maxLength,
    handleSubmit,
    unsaved
}) {

    const [edit, setEdit] = useState(editMode)
    const[showHint, setShowHint] = useState(false)
    const[charsLeft, setCharsLeft] = useState(maxLength)

    const namePath = `${path}.name`
    const accuracyPath = `${path}.accuracy`
    const amountPath = `${path}.amount`
    const removePath = `${path}.removed`
    const pointsPath = 'points'
    const pricePath = `${path}.price`

    const prevEditMode = useRef(false)
    const prevJustSaved = useRef(justSaved)
    const toFocus = useRef(null)
    const anyFilledChildren = getValue(character, path) 
    ? hasAnyFilledChildren(character[path]) 
    : false

    const cancel = (e) => {
        // console.log('cancel')
        e && e.preventDefault()
        setValue([
            {
                value: getValue(savedCharacter, namePath),
                path: namePath
            },
            {
                value: getValue(savedCharacter, accuracyPath),
                path: accuracyPath
            },
            {
                value: getValue(savedCharacter, amountPath),
                path: amountPath
            },
            {
                value: Number(getValue(character,'points'))
                - Number(getValue(character, pricePath))                   
                + Number(getValue(savedCharacter, pricePath)),                                            
                path: pointsPath
            }
        ])
        setEdit(false)
    
    }

    const remove = (e, hard) => {
        // console.log('remove')
        let p = getValue(character,pricePath)
        e && e.preventDefault()
        setValue(
            [
                {
                    value: '',
                    path: namePath
                },
                {
                    value: '',
                    path: accuracyPath
                },
                {
                    value: '',
                    path: amountPath
                },
                {
                    value: true,
                    path: removePath
                },
                {
                    value: p >= 0 ? getValue(character,'points') - p : getValue(character,'points') + p,
                    path: 'points'
                },
                {
                    value: true,
                    path: removePath
                }
                ],
                false, hard ? true : false
            )
            setEdit(false) 
    }

    useEffect(() => {
        if(toFocus.current && !editMode) {
            toFocus.current.focus()
        }

        setCharsLeft(Number(maxLength) - getValue(character,namePath).length)
        // eslint-disable-next-line
    },[edit])
    
    useEffect(() => {
    
        if(prevJustSaved.current !== justSaved) {
            cancel()    
        }
        else {
            if(!anyFilledChildren && !edit && newAdded) {
                // console.log(path,': FORCED EDIT')
                setEdit(true)
            }
        }
    
        if(prevEditMode.current !== editMode)
        {
            prevEditMode.current = editMode
            setEdit(editMode)    
            if (prevEditMode.current === false && !anyFilledChildren) {
                cancel()
            }
        }
    
    // eslint-disable-next-line
      },[justSaved, editMode])


    const amountOptions = [
        {value: '0', label: '0',},
        {value: '1', label: '1',},
        {value: '2', label: '2',},
        {value: '3', label: '3',},
        {value: 'Mało', label: 'Mało',},
        {value: 'Trochę', label: 'Trochę',},
        {value: 'Dużo', label: 'Dużo',}
    ]

    const handleChangeAmount = (selected) => {
        setValue([{value: selected.value, path: amountPath}])
    }


if(edit)
{
  return (
    <>
  <div className={styles.ResourceEdit}>
    <div>
        <div><div className='lbl'>Nazwa:</div></div>
        <label>
            <input 
                type="text"
                onChange={(e)=>{
                    setValue([{value: e.target.value, path: namePath}])
                    setCharsLeft(Number(maxLength) - e.target.value.length)
                }}
                value={getValue(character, namePath)} 
                maxLength={maxLength}
                ref={toFocus} 
                onFocus={()=>{
                    setCharsLeft(Number(maxLength) - getValue(character,namePath).length)
                    setTimeout(()=>{
                    setShowHint(true)
                    },250)
                }} 
                onBlur={()=>{
                    setTimeout(()=>{
                    setShowHint(false)
                    },200)
                }} 
            />
            <span>{charsLeft}</span>
        </label>
    </div>

       {ammo &&
        <div>  
            <div><div className='lbl'>Celność:</div> </div>

                <input 
                    disabled={true}
                    type="number"
                    onChange={(e)=>{setValue([{value: Number(e.target.value), path: accuracyPath}])}}
                    value={getValue(character, accuracyPath)} 
                    onFocus={()=>{
                        setTimeout(()=>{
                        setShowHint(true)
                        },250)
                    }} 
                    onBlur={()=>{
                        setTimeout(()=>{
                        setShowHint(false)
                    },200)
                }} 
                />
                <button
                        className={styles.pointsButton}
                        onClick={(e)=>{
                            e.preventDefault()
                            setValue([
                            {
                                value: Number(getValue(character, accuracyPath) )+1,
                                path: accuracyPath
                            }
                            ])
                        }}
                    >+</button>
                    <button
                        className={styles.pointsButton}
                        onClick={(e)=>{
                            e.preventDefault()
                                setValue([
                                {
                                    value: Number(getValue(character, accuracyPath) -1),
                                    path: accuracyPath
                                }
                                ])
                        }}
                    >-</button>
        </div>
       } 

        <div>
            <div><div className='lbl'>Ilość:</div> </div>
        <label>
          
            <Select 
                options={amountOptions}
                onChange={handleChangeAmount}
                onFocus={()=>{
                    setTimeout(()=>{
                    setShowHint(true)
                    },250)
                    }} 
                onBlur={()=>{
                    setTimeout(()=>{
                    setShowHint(false)
                },200) 
                }}
                value={{value: getValue(character,amountPath), label: getValue(character,amountPath)}}
                unstyled
                classNames={selectClassNames}
            />          

      </label>
    </div>
    <div>
        <div><div className='lbl'>Koszt:</div> </div>
    
                {/* {console.log(character)} */}
                    <input 
                        type="number"
                        disabled={true}
                        value={getValue(character, pricePath)} 
                    />
                    <div>
                    <button
                        className={styles.pointsButton}
                        onClick={(e)=>{
                            e.preventDefault()
                            setValue([{
                                value: Number(getValue(character,'points') )+1, 
                                path: pointsPath
                            },
                            {
                                value: Number(getValue(character, pricePath) )+1,
                                path: pricePath
                            }
                            ])
                        }}
                    >+</button>
                    </div>
                    <div>
                    {Number(getValue(character, pricePath)) > 0 &&
                    <button
                        className={styles.pointsButton}
                        onClick={(e)=>{
                            e.preventDefault()
                                setValue([{
                                    value: Number(getValue(character,'points') -1), 
                                    path: pointsPath
                                },
                                {
                                    value: Number(getValue(character, pricePath) -1),
                                    path: pricePath
                                }
                                ])
                        }}
                    >-</button>
                    }
                    </div>
            
    </div>
    <div>
    {(edit && unsaved) &&
            <div>
                <button onClick={(e)=> {
                    // e.preventDefault()
                    handleSubmit(e)
                }}>Zapisz</button>
            </div>
            }
    {(edit && !editMode) &&
        <div>
            <button onClick={(e)=>{
                (anyFilledChildren && Object.keys(getValue(savedCharacter, path)).length > 0) 
                ? cancel(e) 
                : remove(e, true)
            }}
            >Anuluj</button>
        </div>
        }
        {edit &&
        <div>
            <button onClick={(e)=> {
                (anyFilledChildren && Object.keys(getValue(savedCharacter, path)).length > 0) 
                ? remove(e)
                : remove(e, true)
            }}>Usuń</button>
        </div>
        }

    </div>
</div>
{(hint && parseLinks && showHint) &&
    <div className='hint'>
      <p>{parseLinks(hint)}</p>
    </div>
    }
</>

)
}
else if(anyFilledChildren) {
    return (
    <div 
        className={styles.Resource}
        onClick={(e) => {
            e.preventDefault()
            setEdit(true)
          }}
    >
      
            <h4>{getValue(character,namePath)}</h4>
            {ammo && <div><div className='lbl'>Celność:</div> <span className={styles.price}>{getValue(character,accuracyPath)}</span></div>}
            <div><div className='lbl'>Ilość:</div> <span className={styles.price}>{getValue(character,amountPath)}</span></div>
            <div>{Number(getValue(character,pricePath)) !== 0 && <><div className='lbl'>Koszt:</div> <span className={styles.price}>{getValue(character,pricePath)}</span></>}</div>
              
        <div>
            <div>
                <button onClick={(e)=> {
                    e.preventDefault()
                    setEdit(true)
                }}>edytuj</button>
            </div>
        </div>

    </div>
  ) 
}
}

export default InputResource
