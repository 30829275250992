import styles from './CharacterEdition.module.css'
import Points from './Points'
import AnchorsMenu from './CharacterEditionComponents/AnchorsMenu'
import InputText from './CharacterEditionComponents/InputText'
import InputNumber from './CharacterEditionComponents/InputNumber'
import InputTextarea from './CharacterEditionComponents/InputTextarea'
import InputStatCounter from './CharacterEditionComponents/InputStatCounter'
import InputAbilityCounter from './CharacterEditionComponents/InputAbilityCounter'
import InputUtensiluim from './CharacterEditionComponents/InputUtensiluim'
import InputMiscUtensilia from './CharacterEditionComponents/InputMiscUtensilia'
import InputProgress from './CharacterEditionComponents/InputProgress'
import InputMount from './CharacterEditionComponents/InputMount'
import InputResource from './CharacterEditionComponents/InputResource'
import ValueDescription from './CharacterEditionComponents/ValueDescription'
import { useState, useEffect, useRef } from 'react'
import { diff } from 'deep-object-diff'
import { useAuthContext } from '../hooks/useAuthContext'
import { db } from '../firebase/config';
import { getDoc, doc } from 'firebase/firestore';
import parse, { domToReact } from 'html-react-parser';
import { Link } from 'react-router-dom'
import Select from 'react-select'
import { selectClassNames } from '../misc/SelectClassNames';
import { setTopMargin } from '../misc/CommonFunctions'

function CharacterEdition({
    character, 
    handleSetCharacter, 
    handleSetSavedCharacter,
    getValue, 
    handleSubmit,
    invalid,
    handleSetShouldValidate,
    shouldValidate,
    savedCharacter,
    isNewCharacter,
    editMode,
    justSaved,
    quit,
    handleGoToPrint,
    handleDelete,
    handleSwitchEditMode
}) {

  const { unsaved, dispatch } = useAuthContext()
  // const { data: hints } = useGetItemFromDB('hints', 'maintenance')
  const [hints, setHints] = useState({})

  const getHints = async (cb) => {
    const ref = doc(db, 'maintenance', 'hints')
    await getDoc(ref)
        .then((doc) => {
          cb(doc.data())
        })
        .catch((err)=>{
          console.log(err)
        })
  }

  useEffect(()=>{
    getHints((hints)=>{
      setHints(hints.characterEdition)
    })
  },[])

  const prevJustSaved = useRef(justSaved)
  const [forceSaveButtonDisplay, setForceSaveButtonDisplay] = useState(false)

  const [newAddedAbilities, setNewAddedAbilities] = useState([])
  const [noMoreAbilities, setNoMoreAbilities] = useState(false)
  const [abilitiesToDisplay, setAbilitiesToDisplay] = useState([])

  const [newAddedDisabilities, setNewAddedDisabilities] = useState([])
  const [noMoreDisabilities, setNoMoreDisabilities] = useState(false)
  const [disabilitiesToDisplay, setDisabilitiesToDisplay] = useState([])

  const [newAddedWeapons, setNewAddedWeapons] = useState([])
  const [noMoreWeapons, setNoMoreWeapons] = useState(false)
  const [weaponsToDisplay, setWeaponsToDisplay] = useState([])

  const [newAddedCamouflages, setNewAddedCamouflages] = useState([])
  const [noMoreCamouflages, setNoMoreCamouflages] = useState(false)
  const [camouflagesToDisplay, setCamouflagesToDisplay] = useState([])

  const [newAddedShields, setNewAddedShields] = useState([])
  const [noMoreShields, setNoMoreShields] = useState(false)
  const [shieldsToDisplay, setShieldsToDisplay] = useState([])

  const [newAddedArmors, setNewAddedArmors] = useState([])
  const [noMoreArmors, setNoMoreArmors] = useState(false)
  const [armorsToDisplay, setArmorsToDisplay] = useState([])

  const [newAddedRangedWeapons, setNewAddedRangedWeapons] = useState([])
  const [noMoreRangedWeapons, setNoMoreRangedWeapons] = useState(false)
  const [rangedWeaponsToDisplay, setRangedWeaponsToDisplay] = useState([])

  const [newAddedMiscItems, setNewAddedMiscItems] = useState([])
  const [noMoreMiscItems, setNoMoreMiscItems] = useState(false)
  const [miscItemsToDisplay, setMiscItemsToDisplay] = useState([])

  const [newAddedMount, setNewAddedMount] = useState([])
  const [noMoreMount, setNoMoreMount] = useState(false)
  const [mountToDisplay, setMountToDisplay] = useState([])

  const [newAddedResources, setNewAddedResources] = useState([])
  const [noMoreResources, setNoMoreResources] = useState(false)
  const [resourcesToDisplay, setResourcesToDisplay] = useState([])

  const [newAddedAmmo, setNewAddedAmmo] = useState([])
  const [noMoreAmmo, setNoMoreAmmo] = useState(false)
  const [ammoToDisplay, setAmmoToDisplay] = useState([])

  const [newAddedProgresses, setNewAddedProgresses] = useState([])
  const [noMoreProgresses, setNoMoreProgresses] = useState(false)
  const [progressesToDisplay, setProgressesToDisplay] = useState([])

  const [showNameHint, setShowNameHint] = useState(false)

  const max = {
    abilities: 12,
    disabilities: 5,
    weapon: 3,
    ranged_weapon: 3,
    misc_items: 9,
    ammo: 4,
    resource: 5,
    progress: 6,
    shield: 1,
    armor: 1,
    camouflage: 1,
    mount: 1,
    temporaryState: 1
  }

  const maxLengthName = 25
  const[charsLeftName, setCharsLeftName] = useState(maxLengthName)

  const hasAnyFilledChildren = (obj) => {
    let res = false
    if(!obj || typeof obj !== 'object' || obj.removed){
      res = false
    }
    else {
      let values = []
      Object.values(obj).forEach((v)=>{
        if(v) {
          values.push(v)
        }
      })
      if(values.length > 0)
      {
        res = true
      }
      else {
        res = false
      }
    }
    return res
  }

  const countSavedPointsFromItem = (p) => {
    let x = 0
    if(p<=0)
    {
      x = -p
    }
    else if(p>0)
    {
      for(p;p>0;p--) {
        x += p
      }
    }
    return x
  }

  function getInt(s) {
    let o = ''
    if(typeof s === 'string'){
      o = Number(s.match(/(\d+)/)[0])
    }
    else {
      o = s
    }
    return o
  }

  // const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

  const listFilledComponents = (path, inRoot, n) => {
    let list = []
    if(!inRoot)
    {
      for (const a in character[path]){    
        if(character[path] && hasAnyFilledChildren(character[path][a]) && !character[path][a]['removed']) {
          list = [...list, a]
        }
      }
      // console.log('list: ',list)
    }
    else {   
      // console.log('in root')  
      for (let a=1;a<=n;a++){
        // console.log('a: ',a)
        if(character[path+a] && hasAnyFilledChildren(character[path+a]) && !character[path+a]['removed']) {
          // console.log('any filled and not removed')
          list = [...list, a]
        }
      }
    }
    // console.log('items to display (in function): ',path,': ',list)
    return list.sort((a,b) => getInt(a) - getInt(b))
  }

  const getHighestID = (type) => {
    let n = 0
    let a = []
    Object.keys(character).forEach((i)=>{
      if(i.includes(type)) {
        a = [...a, getInt(i)]
      }
    })
    n = Math.max(...a)
    // console.log('N: ',n)
    return n
  }

  const setAllItemsToDisplay = () => {
    // console.log('setting items to display')
    // console.log('jS: ',justSaved,'pjS: ',prevJustSaved.current)
    if(newAddedAbilities.length === 0 || prevJustSaved.current !== justSaved) { 
      setAbilitiesToDisplay(listFilledComponents('abilities').sort((a,b) => getInt(a) - getInt(b)))
    }
    if(newAddedDisabilities.length === 0 || prevJustSaved.current !== justSaved) { 
      setDisabilitiesToDisplay(listFilledComponents('disabilities').sort((a,b) => getInt(a) - getInt(b)))
    }
    if(newAddedWeapons.length === 0 || prevJustSaved.current !== justSaved) { 
      setWeaponsToDisplay(listFilledComponents('weapon', true, getHighestID('weapon')).sort((a,b) => getInt(a) - getInt(b)))
    }
    if(newAddedCamouflages.length === 0 || prevJustSaved.current !== justSaved) { 
      setCamouflagesToDisplay(listFilledComponents('camouflage', true, getHighestID('camouflage')).sort((a,b) => getInt(a) - getInt(b)))
    }
    if(newAddedShields.length === 0 || prevJustSaved.current !== justSaved) { 
      setShieldsToDisplay(listFilledComponents('shield', true, getHighestID('shield')).sort((a,b) => getInt(a) - getInt(b)))
    }
    if(newAddedArmors.length === 0 || prevJustSaved.current !== justSaved) { 
      setArmorsToDisplay(listFilledComponents('armor', true, getHighestID('armor')).sort((a,b) => getInt(a) - getInt(b)))
    }
    if(newAddedRangedWeapons.length === 0 || prevJustSaved.current !== justSaved) { 
      setRangedWeaponsToDisplay(listFilledComponents('ranged_weapon', true, getHighestID('ranged_weapon')).sort((a,b) => getInt(a) - getInt(b)))
    }
    if(newAddedMiscItems.length === 0 || prevJustSaved.current !== justSaved) { 
      setMiscItemsToDisplay(listFilledComponents('misc_items').sort((a,b) => getInt(a) - getInt(b)))
    }
    if(newAddedMount.length === 0 || prevJustSaved.current !== justSaved) { 
      setMountToDisplay(listFilledComponents('mount', true, getHighestID('mount')).sort((a,b) => getInt(a) - getInt(b)))
    }
    if(newAddedAmmo.length === 0 || prevJustSaved.current !== justSaved) { 
      setAmmoToDisplay(listFilledComponents('ammo', true, getHighestID('ammo')).sort((a,b) => getInt(a) - getInt(b)))
    }
    if(newAddedResources.length === 0 || prevJustSaved.current !== justSaved) { 
      setResourcesToDisplay(listFilledComponents('resource', true, getHighestID('resource')).sort((a,b) => getInt(a) - getInt(b)))
    }
    if(newAddedProgresses.length === 0 || prevJustSaved.current !== justSaved) { 
      setProgressesToDisplay(listFilledComponents('progress', true, getHighestID('progress')).sort((a,b) => getInt(a) - getInt(b)))
    }
    prevJustSaved.current = justSaved 
  }

  useEffect(()=>{
    if(invalid.length > 0){
      window.scrollTo({top: document.getElementById('top'), behavior: 'smooth'})
    }
  },[invalid.length])

  useEffect(()=>{
    setAllItemsToDisplay()
// eslint-disable-next-line
  },[character, justSaved])

  
  useEffect(()=>{
    setForceSaveButtonDisplay(false)
    
  },[justSaved])


  function parseLinks(text) {
    const options = {
      replace: ({ name, attribs, children }) => {
        if (name === 'a' && attribs.href && !attribs.href.startsWith('http')) {
          return <Link to={attribs.href}>{domToReact(children)}</Link>;
        }
      }
    };
        
    return parse(text, options);
  }
    
    const setProperty = (obj, path, value) => {
        const [head, ...rest] = path.split('.')
        //console.log('head',head)
        if(head && !obj[head] && rest.length){
          obj[head] = {}
        }
        return {
            ...obj,
            [head]: rest.length
                ? setProperty(obj[head], rest.join('.'), value)
                : value
        }
      } 

      const setValue = (toUpdate, count, hard, p) => {
        // console.log('setValue: p: ',p)
        let newChar = character
        let newSavedChar = savedCharacter
        toUpdate.forEach((d)=>{
          const value = d.value
          const path = d.path
          newChar = setProperty(newChar, path, value)
            if(count && value > 0)
            {
              if(count === 'sub'){
                let points = Number(getValue(character,'points') - p)
                newChar = setProperty(newChar, 'points', points)
              }
              if(count === 'add'){
                let points = Number(getValue(character,'points') + p) 
                newChar = setProperty(newChar, 'points', points)
              }
            }  
            else if(count) {
              if(count === 'sub'){
                let points = p > 0 ? Number(getValue(character,'points')) -1 : Number(getValue(character,'points'))
                newChar = setProperty(newChar, 'points', points)
              }
              if(count === 'add'){
                let points = p > 0 ? Number(getValue(character,'points')) +1 : Number(getValue(character,'points'))
                newChar = setProperty(newChar, 'points', points)
              }
            } 
            if((getValue(savedCharacter,path) === '' && value === '') || hard){
              if(newSavedChar){
                newSavedChar = setProperty(newSavedChar, path, value)
              }
              newChar = setProperty(newChar, path, value)
            }
        })
        handleSetCharacter(newChar)
        handleSetSavedCharacter && handleSetSavedCharacter(newSavedChar)
        
      }

      const filterRemoved = (a, path, inRoot) => {
        let na = []
        if(inRoot) {
          a.forEach((i)=>{
            if(!getValue(character, path+i+'.removed')){
                  na = [...na, path+i]
                }
          })
        }
        else {
          a.forEach((i)=>{
            if(!getValue(character, path+'.'+i+'.removed')){
              na = [...na, i]
            }
          })
        }   
        // console.log(path,': toDisplay:',a,': inRoot:',inRoot,', filtered: ',na)
        return na
      }

      const getNextSlot = (td, path, letter, inRoot, i) => {
        // console.log('td:',td,'max.weapon',max[path])
        let m = Number(i ? i : 0)
        // console.log('M: ',m)
        let c = 0
        let n = td.length
        if(inRoot){
          // console.log('in root')
          for(let y = 0; y < max[path]; y++){
            // console.log('char.path: ',character[path+y])
            if(hasAnyFilledChildren(character[path+y])){
              c++
            }
          }
        }
        else {       
          c = character[path] ? Object.keys(character[path]).length : 0
        }
        // console.log('char.length: ',c,'td.length: ',n)
        let r = Number(c + n + m + 1)
        if(inRoot){
          // console.log('IIIIFFFFFF',r,': ',listFilledComponents(path, true, max.weapon))
          if(td.includes(r) || (Array.isArray(c) && c.includes(r)) || typeof getValue(character, path+r) === 'object') {
            r = getNextSlot(td, path, letter, true, m+1) 
          }
        }
        else{
          if(td.includes(letter+r) || (Array.isArray(c) && c.includes(letter+r)) || typeof getValue(character, path+'.'+letter+r) === 'object') {
            r = getNextSlot(td, path, letter, m+1) 
          }
        }      
        return r
      }

      useEffect(() => {
        if((isNewCharacter || forceSaveButtonDisplay || (savedCharacter && Object.keys(diff(character,savedCharacter)).length > 0))){
          dispatch({ type: 'UNSAVED', payload: true })
        }
        else {
          dispatch({ type: 'UNSAVED', payload: false })
        }
      },[isNewCharacter, character, savedCharacter, forceSaveButtonDisplay, dispatch])


      const selectOptions = [
        // {value: '', label: '',},
        {value: 'Faerion', label: 'Faerion',},
        {value: 'Malropa', label: 'Malropa',},
        {value: 'Messianica', label: 'Messianica',},
        {value: 'Triamonda', label: 'Triamonda',},
    ]
    
    const handleSelect = (selected) => {
      setValue([{
        value: selected.value, 
        path: 'universum'}])
        if(shouldValidate>0){handleSetShouldValidate(shouldValidate+1)}
    }

    // console.log(character)
    useEffect(()=>{
      setTopMargin()
    })

   return (
    <>
              {!isNewCharacter &&
              
               <AnchorsMenu /> 
               
               }
              

            <form onSubmit={handleSubmit} className={styles.charEditForm}>        

            <nav className='tools'>
              <div className={styles.controls}>
            {/* {console.log('forced: ',forceSaveButtonDisplay, ' new: ',isNewCharacter, 'char & sChar non equal: ',savedCharacter && Object.keys(diff(character,savedCharacter)).length > 0)} */}
            {unsaved && <div><button type='submit'>Zapisz</button></div>}
            {!editMode && 
                  <div>
                    <button 
                      onClick={(e)=>{
                        e.preventDefault()
                        handleGoToPrint()}}>Drukuj kartę postaci</button>
                  </div>
                }
                {(!editMode || isNewCharacter) &&
                  <div>  
                    <button 
                      onClick={(e)=>{
                        e.preventDefault()
                        quit()}}>Lista postaci</button>
                  </div>
                }
                <div>
                {!isNewCharacter &&
                  <button 
                    onClick={(e)=>{
                      e.preventDefault()
                      handleSwitchEditMode()
                    }}>
                        {editMode ? 
                        <span>{unsaved ? <>Anuluj</> : <>Wyjdź</>}</span> : 
                        <span>Edytuj całą kartę postaci</span>}
                  </button>
                } 
                </div>
                {!isNewCharacter &&
                  <div>
                    <button 
                      onClick={(e)=>{
                        e.preventDefault()
                        handleDelete()
                      }}>
                        Usuń postać
                    </button>
                  </div>
                }
                

              </div>
              {!isNewCharacter &&
              <div>
                <Points 
                  budget={character.budget} 
                  points={character.points} 
                  setValue={setValue} 
                  getValue={getValue} 
                  character={character} />
                </div>
                }
            </nav>
            <h2>{character.name ? character.name : <>Nowa Postać</>}</h2>
              <div className={isNewCharacter ? styles.newChar : styles.charSheet} id='top'>
                <div className={styles.appearance}>
                  {!isNewCharacter && <p>{character.universum}</p>}
                  
                  {getValue(character,'appearance.portrait') && <div className={styles.portraitWrapper}><img className={styles.portrait} src={getValue(character,'appearance.portrait')} alt={character.name} /></div>}
                  {(editMode && isNewCharacter) &&
                  <>
                  <div>
                    <div>Świat: </div>
                    <label>
                    
                    <Select 
                        // tabIndex={-1}
                        options={selectOptions}
                        onChange={handleSelect}
                        value={{value: getValue(character, 'universum'), label: getValue(character, 'universum')}}
                        unstyled
                        classNames={selectClassNames}
                        isSearchable={false}
                        readonly={true}
                    />                     

                    {(invalid && invalid.includes('universum')) && <span className={styles.formError}>Wybierz świat</span>}
                  </label>
                  </div>
                  {character.universum &&
                    <div className='hint'>
                      <p>{parseLinks(hints[character.universum])}</p>
                    </div>
                    }
                  </>
                  }
                  {!isNewCharacter &&
                  <>
                  <InputText unsaved={unsaved} handleSubmit={handleSubmit} name='Portret (url):' hideText={true} hint={hints.portrait} parseLinks={parseLinks} editButtonText='Zmień portret' addButtonText='Dodaj portret' path='appearance.portrait' editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} />
                  <InputNumber unsaved={unsaved} handleSubmit={handleSubmit} name='Budżet:' path='budget' editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} />
                  <InputText unsaved={unsaved} handleSubmit={handleSubmit} name='Postura:' maxLength={20} hint={hints.posture} parseLinks={parseLinks} path='appearance.posture' editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} />
                  <InputText unsaved={unsaved} handleSubmit={handleSubmit} name='Inne cechy wyglądu:' maxLength={70} path='appearance.description' editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} />
                  </>
                  }
                </div>
                <div className={styles.metrics} id='metrics'>
                  {!isNewCharacter && <h3>Metryka</h3>}
                  
                  {editMode &&
                  <>
                  <div className={styles.inputName}>
                    <div>Imię:</div>
                    <label> 
                      <input 
                        type="text" 
                        maxLength={maxLengthName}
                        onChange={(e)=>{
                          setCharsLeftName(Number(maxLengthName) - e.target.value.length)
                          setValue([{
                          value: e.target.value, 
                          path: 'name'}])
                          if(shouldValidate>0){handleSetShouldValidate(shouldValidate+1)}
                        }} 
                        value={getValue(character, 'name')} 
                        onFocus={()=>{setShowNameHint(true)}}
                        onBlur={()=>{
                          setTimeout(()=>{
                            setShowNameHint(false)
                          },200)
                        }}
                      /> 
                      <span>{charsLeftName}</span>
                    {(invalid && invalid.includes('name')) && <div className={styles.formError}>Wpisz imię</div>}
                  </label>
                  <div>
                    <button type='submit'>Dalej</button>
                  </div>
                  
                  </div>
                  {(showNameHint && hints.name) &&
                    <div className='hint'>
                      <p>{parseLinks(hints.name)}</p>
                    </div>
                    }
                    </>
                  }
                  {!isNewCharacter &&
                  <>
                  <InputTextarea unsaved={unsaved} handleSubmit={handleSubmit} maxLength={100} name='Krótki opis postaci / fach:' path='roleplay.description' editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} />
                  <InputNumber unsaved={unsaved} handleSubmit={handleSubmit} name='Wiek:' hint={hints.age} parseLinks={parseLinks} path='metrics.age' editMode={editMode} unit='lat' character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} />
                  {/* <InputText name='Płeć:' maxLength={20} path='metrics.sex' editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} /> */}
                  <InputNumber unsaved={unsaved} handleSubmit={handleSubmit} name='Wzrost:' path='metrics.height' editMode={editMode} unit='cm' character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} />   
                  <InputText unsaved={unsaved} handleSubmit={handleSubmit} name='Pochodzenie:' maxLength={25} theme='belonging' subtype='origin' path='metrics.origin' editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} />
                  <InputText unsaved={unsaved} handleSubmit={handleSubmit} name='Stan społeczny:' maxLength={25} theme='belonging' subtype='social_state' path='metrics.social_state' pricePath='metrics.price' editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} />
                  {getValue(character,'universum') === 'Faerion' && <InputText unsaved={unsaved} handleSubmit={handleSubmit} name='Lud:' maxLength={25} hint={hints.folk} parseLinks={parseLinks} pricePath='metrics.price' theme='belonging' subtype='folk' path='metrics.folk' editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} />}
                  </>
                  }
                </div>
                {!isNewCharacter &&
                  <>
                <div className={styles.roleplay} id='roleplay'>
                <InputTextarea unsaved={unsaved} handleSubmit={handleSubmit} maxLength={240} name='Istotne cechy osobowości:' path='roleplay.personality' editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} />
                <InputText unsaved={unsaved} handleSubmit={handleSubmit} maxLength={100} name='Typ (inspiracje):' hint={hints.characterType} parseLinks={parseLinks} theme='characterType' subtype='' additive={true} path='roleplay.personality_inspirations' editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} />
                <InputText unsaved={unsaved} handleSubmit={handleSubmit} maxLength={100} name='Role:' path='roleplay.roles' editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} />
                <InputText unsaved={unsaved} handleSubmit={handleSubmit} maxLength={100} name='Cele:' path='roleplay.goals' editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} />
                <InputText unsaved={unsaved} handleSubmit={handleSubmit} maxLength={100} name='Zaburzenia:' path='roleplay.disorders' editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} />
                </div>
                
                <div className={styles.bio} id='bio'>
                <InputTextarea unsaved={unsaved} handleSubmit={handleSubmit} name='Kluczowe zdarzenia z przeszłości:' maxLength={100} path='bio.key_events' editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} />
                <InputTextarea unsaved={unsaved} handleSubmit={handleSubmit} name='Koneksje:' maxLength={100} hint={hints.connections} parseLinks={parseLinks} path='bio.connections' pricePath='bio.price' hasPrice={true} editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} />
                </div>
                <div className={styles.competences} id='competences'>
                  <h3>Kompetencje</h3>
                  <h4>Wyczynowe</h4>
                  <InputStatCounter unsaved={unsaved} handleSubmit={handleSubmit} name='Siła:' path='competences.strength' editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} />
                  <InputStatCounter unsaved={unsaved} handleSubmit={handleSubmit} name='Walka:' path='competences.fighting' editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} />
                  <InputStatCounter unsaved={unsaved} handleSubmit={handleSubmit} name='Sprawność:' path='competences.agility' editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} />
                  <InputStatCounter unsaved={unsaved} handleSubmit={handleSubmit} name='Celność:' path='competences.marksmanship' editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} />
                  <InputStatCounter unsaved={unsaved} handleSubmit={handleSubmit} name='Wydolność:' path='competences.endurance' editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} />               
                  <InputStatCounter unsaved={unsaved} handleSubmit={handleSubmit} name='Zimna Krew:' path='competences.cold_blood' editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} />               
                  <h4>Intelektualne</h4>
                  <InputStatCounter unsaved={unsaved} handleSubmit={handleSubmit} name='Logika:' path='competences.logic' editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} />
                  <InputStatCounter unsaved={unsaved} handleSubmit={handleSubmit} name='Erudycja:' path='competences.erudition' editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} />
                  <InputStatCounter unsaved={unsaved} handleSubmit={handleSubmit} name='Technika:' path='competences.technics' editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} />
                  <h4>Uważnościowe</h4>
                  <InputStatCounter unsaved={unsaved} handleSubmit={handleSubmit} name='Skradanie:' path='competences.stealth' editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} />
                  <InputStatCounter unsaved={unsaved} handleSubmit={handleSubmit} name='Percepcja:' path='competences.perception' editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} />
                  <h4>Społeczne</h4>
                  <InputStatCounter unsaved={unsaved} handleSubmit={handleSubmit} name='Perswazja:' path='competences.persuasion' editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} />
                  <InputStatCounter unsaved={unsaved} handleSubmit={handleSubmit} name='Przenikliwość:' path='competences.perspicacity' editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} />

                </div>
                <div className={styles.abilities} id='abilities'>
                  <h3>Zdolności</h3>
                  {filterRemoved(abilitiesToDisplay, 'abilities').map((a, i)=>{
                      let newAdded = false
                      if(i+1===filterRemoved(abilitiesToDisplay, 'abilities').length){  
                        newAdded = true
                        if(Number(i+1) < max.abilities){
                          if(noMoreAbilities) { 
                           setNoMoreAbilities(false) 
                          }
                        }
                        else{
                          if(!noMoreAbilities) { 
                           setNoMoreAbilities(true) 
                          }
                        }
                        
                      }
                      return (
                        <InputAbilityCounter unsaved={unsaved} handleSubmit={handleSubmit} maxLength={20} disability={false} newAdded={newAdded} path={`abilities.${a}`} hasAnyFilledChildren={hasAnyFilledChildren} countSavedPointsFromItem={countSavedPointsFromItem} editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} key={a} />
                      )
                    })
                  }
                  {!noMoreAbilities &&
                  <button // toDisplay += 1
                    className={styles.AddItemBtn}
                    onClick={(e)=>{
                      e.preventDefault()
                      let td = abilitiesToDisplay
                      let nextItem = 'a'+Number(getNextSlot(td, 'abilities', 'a'))
                      td = [...td, nextItem]
                      let a = [...newAddedAbilities, nextItem]
                      setNewAddedAbilities(a)
                      setAbilitiesToDisplay(td)
                    }}>
                    Dodaj Zdolność
                  </button>
                  }
                  <h3>Niezdolności</h3>
                  {filterRemoved(disabilitiesToDisplay, 'disabilities').map((d, i)=>{
                      let newAdded = false
                      if(i+1===filterRemoved(disabilitiesToDisplay, 'disabilities').length){ 
                        newAdded = true
                        if(Number(i+1) < max.disabilities){
                          if(noMoreDisabilities) { 
                           setNoMoreDisabilities(false) 
                          }
                        }
                        else{
                          if(!noMoreDisabilities) { 
                           setNoMoreDisabilities(true) 
                          }
                        }
                      }
                      return (
                        <InputAbilityCounter unsaved={unsaved} handleSubmit={handleSubmit} maxLength={20} disability={true} hint={hints.disabilities} parseLinks={parseLinks} newAdded={newAdded} path={`disabilities.${d}`} hasAnyFilledChildren={hasAnyFilledChildren} countSavedPointsFromItem={countSavedPointsFromItem} editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} key={d} />
                      )
                    })
                  }
                  {!noMoreDisabilities &&
                  <button // toDisplay += 1
                    className={styles.AddItemBtn}
                    onClick={(e)=>{
                      e.preventDefault()
                      let td = disabilitiesToDisplay
                      let nextItem = 'd'+Number(getNextSlot(td, 'disabilities', 'd'))
                      td = [...td, nextItem]
                      let d = [...newAddedDisabilities, nextItem]
                      setNewAddedDisabilities(d)
                      setDisabilitiesToDisplay(td)
                    }}>
                    Dodaj Niezdolność
                  </button>
                  }
                </div>
                <div className={styles.Weapon} id='utensilia'>
                  <h3>Utensylia</h3>
                  {filterRemoved(weaponsToDisplay,'weapon',true).map((w, i)=>{
                      let newAdded = false
                      if(i+1===filterRemoved(weaponsToDisplay,'weapon',true).length){ 
                        newAdded = true
                        if(Number(i+1) < max.weapon){
                          if(noMoreWeapons) { 
                           setNoMoreWeapons(false) 
                          }
                        }
                        else{
                          if(!noMoreWeapons) { 
                           setNoMoreWeapons(true) 
                          }
                        }
                      }
                      return (
                        <InputUtensiluim type='Broń' unsaved={unsaved} handleSubmit={handleSubmit} maxLength={30} maxLengthTA={280} hint={hints.utensilia} parseLinks={parseLinks} newAdded={newAdded} stats={{speed: true, range: true}} path={w} hasAnyFilledChildren={hasAnyFilledChildren} countSavedPointsFromItem={countSavedPointsFromItem} editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} key={w} />
                      )
                    })
                  }
                  {!noMoreWeapons &&
                  <button // toDisplay += 1
                    className={styles.AddItemBtn}
                    onClick={(e)=>{
                      e.preventDefault()
                      let td = weaponsToDisplay
                      let nextItem = Number(getNextSlot(td, 'weapon', 'w', true))
                      td = [...td, nextItem]
                      let w = [...newAddedWeapons, nextItem]
                      setNewAddedWeapons(w)
                      setWeaponsToDisplay(td)
                    }}>
                    Dodaj Broń
                  </button>
                  }
                </div>


                <div className={styles.RangedWeapon}>
                {filterRemoved(rangedWeaponsToDisplay,'ranged_weapon',true).map((r, i)=>{
                      let newAdded = false
                      if(i+1===filterRemoved(rangedWeaponsToDisplay,'ranged_weapon',true).length){ 
                        newAdded = true
                        if(Number(i+1) < max.ranged_weapon){
                          if(noMoreRangedWeapons) { 
                           setNoMoreRangedWeapons(false) 
                          }
                        }
                        else{
                          if(!noMoreRangedWeapons) { 
                           setNoMoreRangedWeapons(true) 
                          }
                        }
                      }
                      return (
                        <InputUtensiluim type='Broń Dystansowa' unsaved={unsaved} handleSubmit={handleSubmit} hint={hints.utensilia} maxLength={30} maxLengthTA={280} parseLinks={parseLinks} newAdded={newAdded} stats={{accuracy: true, reload: true}} path={r} hasAnyFilledChildren={hasAnyFilledChildren} countSavedPointsFromItem={countSavedPointsFromItem} editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} key={r} />
                      )
                    })
                  }
                  {!noMoreRangedWeapons &&
                  <button // toDisplay += 1
                    className={styles.AddItemBtn}
                    onClick={(e)=>{
                      e.preventDefault()
                      let td = rangedWeaponsToDisplay
                      let nextItem = Number(getNextSlot(td, 'ranged_weapon', 'r', true))
                      td = [...td, nextItem]
                      let r = [...newAddedRangedWeapons, nextItem]
                      setNewAddedRangedWeapons(r)
                      setRangedWeaponsToDisplay(td)
                    }}>
                    Dodaj Broń Dystansową
                  </button>
                  }
                </div>


                <div className={styles.Camouflage}>

                {(max.camouflage === 1 && noMoreCamouflages && 
                  filterRemoved(camouflagesToDisplay,'camouflage',true).length === 0) && 
                  setNoMoreCamouflages(false) }

                {filterRemoved(camouflagesToDisplay,'camouflage',true).map((c, i)=>{
                      let newAdded = false
                      if(i+1===filterRemoved(camouflagesToDisplay,'camouflage',true).length){ 
                        newAdded = true
                        if(Number(i+1) < max.camouflage){
                          if(noMoreCamouflages) { 
                           setNoMoreCamouflages(false) 
                          }
                        }
                        else{
                          if(!noMoreCamouflages) { 
                           setNoMoreCamouflages(true) 
                          }
                        }
                      }
                      return (
                        <InputUtensiluim type='Kamuflaż' unsaved={unsaved} handleSubmit={handleSubmit} hint={hints.utensilia} maxLength={30} maxLengthTA={280} parseLinks={parseLinks} newAdded={newAdded} stats={{camouflage: true}} path={c} hasAnyFilledChildren={hasAnyFilledChildren} countSavedPointsFromItem={countSavedPointsFromItem} editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} key={c} />
                      )
                    })
                  }
                  {!noMoreCamouflages &&
                  <button // toDisplay += 1
                    className={styles.AddItemBtn}
                    onClick={(e)=>{
                      e.preventDefault()
                      let td = camouflagesToDisplay
                      let nextItem = Number(getNextSlot(td, 'camouflage', 'c', true))
                      td = [...td, nextItem]
                      let c = [...newAddedCamouflages, nextItem]
                      setNewAddedCamouflages(c)
                      setCamouflagesToDisplay(td)
                    }}>
                    Dodaj Kamuflaż
                  </button>
                  }
                  </div>


                <div className={styles.Shield}>

                {(max.shield === 1 && noMoreShields && 
                  filterRemoved(shieldsToDisplay,'shield',true).length === 0) && 
                  setNoMoreShields(false) }

                {filterRemoved(shieldsToDisplay,'shield',true).map((s, i)=>{
                      let newAdded = false
                      if(i+1===filterRemoved(shieldsToDisplay,'shield',true).length){ 
                        newAdded = true
                        if(Number(i+1) < max.shield){
                          if(noMoreShields) { 
                           setNoMoreShields(false) 
                          }
                        }
                        else{
                          if(!noMoreShields) { 
                           setNoMoreShields(true) 
                          }
                        }
                      }
                      return (
                        <InputUtensiluim type='Tarcza' unsaved={unsaved} handleSubmit={handleSubmit} hint={hints.utensilia} maxLength={30} maxLengthTA={280} parseLinks={parseLinks} newAdded={newAdded} stats={{armor_level: true, range: true}} path={s} hasAnyFilledChildren={hasAnyFilledChildren} countSavedPointsFromItem={countSavedPointsFromItem} editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} key={s} />
                      )
                    })
                  }
                  {!noMoreShields &&
                  <button // toDisplay += 1
                    className={styles.AddItemBtn}
                    onClick={(e)=>{
                      e.preventDefault()
                      let td = shieldsToDisplay
                      let nextItem = Number(getNextSlot(td, 'shield', 's', true))
                      td = [...td, nextItem]
                      let s = [...newAddedShields, nextItem]
                      setNewAddedShields(s)
                      setShieldsToDisplay(td)
                    }}>
                    Dodaj Tarczę
                  </button>
                  }                
                </div>
                <div className={styles.Armor}>

                {(max.armor === 1 && noMoreArmors && 
                  filterRemoved(armorsToDisplay,'armor',true).length === 0) && 
                  setNoMoreArmors(false) }

                {filterRemoved(armorsToDisplay,'armor',true).map((a, i)=>{
                      let newAdded = false
                      if(i+1===filterRemoved(armorsToDisplay,'armor',true).length){ 
                        newAdded = true
                        if(Number(i+1) < max.armor){
                          if(noMoreArmors) { 
                           setNoMoreArmors(false) 
                          }
                        }
                        else{
                          if(!noMoreArmors) { 
                           setNoMoreArmors(true) 
                          }
                        }
                      }
                      return (
                        <InputUtensiluim type='Zbroja' unsaved={unsaved} handleSubmit={handleSubmit} hint={hints.utensilia} maxLength={30} maxLengthTA={330} parseLinks={parseLinks} newAdded={newAdded} stats={{armor_level: true}} path={a} hasAnyFilledChildren={hasAnyFilledChildren} countSavedPointsFromItem={countSavedPointsFromItem} editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} key={a} />
                      )
                    })
                  }
                  {!noMoreArmors &&
                  <button // toDisplay += 1
                    className={styles.AddItemBtn}
                    onClick={(e)=>{
                      e.preventDefault()
                      let td = armorsToDisplay
                      let nextItem = Number(getNextSlot(td, 'armor', 'a', true))
                      td = [...td, nextItem]
                      let a = [...newAddedArmors, nextItem]
                      setNewAddedArmors(a)
                      setArmorsToDisplay(td)
                    }}>
                    Dodaj Zbroję
                  </button>
                  }                  
                  </div>
                
                

                <div className={styles.miscItems}>
                  <h3>Inne przedmioty rozszerzające Kompetencje</h3>
                  
                  {filterRemoved(miscItemsToDisplay, 'misc_items').map((m, i)=>{
                      let newAdded = false
                      if(i+1===filterRemoved(miscItemsToDisplay, 'misc_items').length){ 
                        newAdded = true
                        if(Number(i+1) < max.misc_items){
                          if(noMoreMiscItems) { 
                           setNoMoreMiscItems(false) 
                          }
                        }
                        else{
                          if(!noMoreMiscItems) { 
                           setNoMoreMiscItems(true) 
                          }
                        }
                      }
                      return (
                        <InputMiscUtensilia path={`misc_items.${m}`} unsaved={unsaved} handleSubmit={handleSubmit} maxLength={25} maxLength2={20} hint={hints.utensilia} parseLinks={parseLinks} newAdded={newAdded} hasAnyFilledChildren={hasAnyFilledChildren} countSavedPointsFromItem={countSavedPointsFromItem}  editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} key={m}/>
                      )
                    })
                  }
                  {!noMoreMiscItems &&
                  <button // toDisplay += 1
                    className={styles.AddItemBtn}
                    onClick={(e)=>{
                      e.preventDefault()
                      let td = miscItemsToDisplay
                      let nextItem = 'm'+Number(getNextSlot(td, 'misc_items', 'm'))
                      td = [...td, nextItem]
                      let m = [...newAddedMiscItems, nextItem]
                      setNewAddedMiscItems(m)
                      setMiscItemsToDisplay(td)
                    }}>
                    Dodaj Przedmiot
                  </button>
                  }
                  </div>

                <div className={styles.Inventory} id='inventory'>
                  <h3>Inwentarz</h3>
                  <InputTextarea unsaved={unsaved} handleSubmit={handleSubmit} name='Przy sobie:' maxLength={420} path='inventory.self' hasAnyFilledChildren={hasAnyFilledChildren} countSavedPointsFromItem={countSavedPointsFromItem}  editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} />
                  <InputTextarea unsaved={unsaved} handleSubmit={handleSubmit} name='Toboła:' maxLength={420} path='inventory.bag' hasAnyFilledChildren={hasAnyFilledChildren} countSavedPointsFromItem={countSavedPointsFromItem}  editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} />
                  <InputTextarea unsaved={unsaved} handleSubmit={handleSubmit} name='Przy wierzchowcu / pojeździe:' maxLength={200} path='inventory.mount' hasAnyFilledChildren={hasAnyFilledChildren} countSavedPointsFromItem={countSavedPointsFromItem}  editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} />
                  <InputTextarea unsaved={unsaved} handleSubmit={handleSubmit} name='W kwaterze:' maxLength={200} path='inventory.quarter' hasAnyFilledChildren={hasAnyFilledChildren} countSavedPointsFromItem={countSavedPointsFromItem}  editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} />

                </div>

                <div className={styles.Mount}>
                  <h3>Wierzchowiec / Pojazd</h3>
                  {(max.mount === 1 && noMoreMount && 
                  filterRemoved(mountToDisplay,'mount',true).length === 0) && 
                  setNoMoreMount(false) }

                  {filterRemoved(mountToDisplay,'mount',true).map((n, i)=>{
                      let newAdded = false
                      if(i+1===filterRemoved(mountToDisplay,'mount',true).length){ 
                        newAdded = true
                        if(Number(i+1) < max.mount){
                          if(noMoreMount) { 
                           setNoMoreMount(false) 
                          }
                        }
                        else{
                          if(!noMoreMount) { 
                            setNoMoreMount(true) 
                          }
                        }
                      }
                      return (
                        <InputMount path={n} unsaved={unsaved} handleSubmit={handleSubmit} maxLength={20} maxLengthTA={100} hint={hints.mount} parseLinks={parseLinks} newAdded={newAdded} editMode={editMode} hasAnyFilledChildren={hasAnyFilledChildren} countSavedPointsFromItem={countSavedPointsFromItem}  character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} key={n} />
                      )
                    })
                  }
                  {!noMoreMount &&
                  <button // toDisplay += 1
                    className={styles.AddItemBtn}
                    onClick={(e)=>{
                      e.preventDefault()
                      let td = mountToDisplay
                      let nextItem = Number(getNextSlot(td, 'mount', 'n', true))
                      td = [...td, nextItem]
                      let n = [...newAddedMount, nextItem]
                      setNewAddedMount(n)
                      setMountToDisplay(td)
                    }}>
                    Dodaj
                  </button>
                  }
                </div>

                <div className={styles.Wealth} id='wealth'>
                  <h3>Majętność</h3>
                  <InputStatCounter unsaved={unsaved} handleSubmit={handleSubmit} name='Moc nabywcza:' path='wealth.level' min={0} max={10} fixedPrices={{'0':'---','1':0,'2':1,'3':2,'4':3,'5':5,'6':8,'7':12,'8':20,'9':35,'10':50,'11':'---'}} editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} />
                  {getValue(character, 'wealth.level') > 0 && 
                  <div>
                    <p>Bez obniżenia poziomu majętności, możesz nabyć:</p>
                    <ValueDescription stat='wealthLevel' universum={getValue(character, 'universum')} value={getValue(character, 'wealth.level')} />
                  </div>
                  }
                  <InputTextarea unsaved={unsaved} handleSubmit={handleSubmit} name='Inne informacje o majątku:' maxLength={100} path='wealth.description' editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} />

                </div>
                <div id='resources'>
                  <h3>Amunicja</h3>
                  <div className={styles.ammo}>
                  {filterRemoved(ammoToDisplay,'ammo',true).map((a, i)=>{
                      let newAdded = false
                      if(i+1===filterRemoved(ammoToDisplay,'ammo',true).length){ 
                        newAdded = true
                        if(Number(i+1) < max.ammo){
                          if(noMoreAmmo) { 
                           setNoMoreAmmo(false) 
                          }
                        }
                        else{
                          if(!noMoreAmmo) { 
                            setNoMoreAmmo(true) 
                          }
                        }
                      }
                      return (
                        <InputResource unsaved={unsaved} handleSubmit={handleSubmit} newAdded={newAdded} maxLength={15} hint={hints.resources} parseLinks={parseLinks} ammo={true} path={a} hasAnyFilledChildren={hasAnyFilledChildren} countSavedPointsFromItem={countSavedPointsFromItem} editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} key={a} />
                      )
                    })
                  }
                  {!noMoreAmmo &&
                  <button // toDisplay += 1
                    className={styles.AddItemBtn}
                    onClick={(e)=>{
                      e.preventDefault()
                      let td = ammoToDisplay
                      let nextItem = Number(getNextSlot(td, 'ammo', 'a', true))
                      td = [...td, nextItem]
                      let a = [...newAddedAmmo, nextItem]
                      setNewAddedAmmo(a)
                      setAmmoToDisplay(td)
                    }}>
                    Dodaj Amunicję
                  </button>
                  }
                  </div>
                </div>
                
                <h3>Zasoby</h3>
                <div>

                  {filterRemoved(resourcesToDisplay,'resource',true).map((r, i)=>{
                      let newAdded = false
                      if(i+1===filterRemoved(resourcesToDisplay,'resource',true).length){ 
                        newAdded = true
                        if(Number(i+1) < max.resource){
                          if(noMoreResources) { 
                            setNoMoreResources(false) 
                          }
                        }
                        else{
                          if(!noMoreResources) { 
                            setNoMoreResources(true) 
                          }
                        }
                      }
                      return (
                        <InputResource unsaved={unsaved} handleSubmit={handleSubmit} newAdded={newAdded} maxLength={15} hint={hints.resources} parseLinks={parseLinks} ammo={false} path={r} hasAnyFilledChildren={hasAnyFilledChildren} countSavedPointsFromItem={countSavedPointsFromItem} editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} key={r} />
                      )
                    })
                  }
                  {!noMoreResources &&
                  <button // toDisplay += 1
                    className={styles.AddItemBtn}
                    onClick={(e)=>{
                      e.preventDefault()
                      let td = resourcesToDisplay
                      let nextItem = Number(getNextSlot(td, 'resources', 'r', true))
                      td = [...td, nextItem]
                      let r = [...newAddedResources, nextItem]
                      setNewAddedResources(r)
                      setResourcesToDisplay(td)
                    }}>
                    Dodaj Zasób
                  </button>
                  }
                    </div></>}


                  </div>
                  {!isNewCharacter &&
                  <>
                  

                  <div className={styles.temporaryState}>
                      <h3>Tymczasowy stan</h3>
                      <InputTextarea unsaved={unsaved} handleSubmit={handleSubmit} path='temporary_values.state' maxLength={400} hint={hints.state} parseLinks={parseLinks} editMode={editMode} hasAnyFilledChildren={hasAnyFilledChildren} countSavedPointsFromItem={countSavedPointsFromItem}  character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} />
                  </div>

                  

                  <div className={styles.progress} id='progresses'>
                    <h3>Rozwijane</h3>
                    {filterRemoved(progressesToDisplay,'progress',true).map((a, i)=>{
                      let newAdded = false
                      if(i+1===filterRemoved(progressesToDisplay,'progress',true).length){ 
                        newAdded = true
                        if(Number(i+1) < max.progress){
                          if(noMoreProgresses) { 
                           setNoMoreProgresses(false) 
                          }
                        }
                        else{
                          if(!noMoreProgresses) { 
                            setNoMoreProgresses(true) 
                          }
                        }
                      }
                      return (
                        <InputProgress unsaved={unsaved} handleSubmit={handleSubmit} maxLength={30} newAdded={newAdded} path={a} hint={hints.progresses} parseLinks={parseLinks} hasAnyFilledChildren={hasAnyFilledChildren} countSavedPointsFromItem={countSavedPointsFromItem} editMode={editMode} character={character} savedCharacter={savedCharacter} justSaved={justSaved} getValue={getValue} setValue={setValue} key={a} />
                      )
                    })
                    }
                    {!noMoreProgresses &&
                    <button // toDisplay += 1
                      className={styles.AddItemBtn}
                      onClick={(e)=>{
                        e.preventDefault()
                        let td = progressesToDisplay
                        let nextItem = Number(getNextSlot(td, 'progress', 'a', true))
                        td = [...td, nextItem]
                        let a = [...newAddedProgresses, nextItem]
                        setNewAddedProgresses(a)
                        setProgressesToDisplay(td)
                      }}>
                      Dodaj Progres
                    </button>
                    }
                  
                  </div>
                  </>}
                </form>
                  
              </>

)
}


export default CharacterEdition
