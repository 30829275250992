import styles from './InputStatCounter.module.css'
import { useState, useEffect } from 'react'

function InputStatCounter({
    name, 
    character, 
    savedCharacter, 
    path, 
    editMode, 
    getValue, 
    setValue, 
    justSaved,
    fixedPrices,
    min,
    max,
    handleSubmit,
    unsaved
}) {

const [edit, setEdit] = useState(editMode)
const [points, setPoints] = useState(0)

let addPointsValue = fixedPrices 
    ? fixedPrices[(getValue(character, path) ? getValue(character, path) : 0) +1]
    : (getValue(character, path) > 0 ? getValue(character, path) +1 : 1)

let subPointsValue = fixedPrices
    ? fixedPrices[getValue(character, path) ? getValue(character, path) : 0]
    : (getValue(character, path) > 0 ? getValue(character, path) : 1)

    // path === 'wealth.level' && console.log(subPointsValue)

const getPrice = (n, g) => {
    let x = 0
    if(!g) {
        for(let i=n;i>0;i--)
        {
            x += i
        }
    }
    else {
        for(let i=n;i<0;i++)
        {
            x++
        }
    }
    
    return x
}

const n = (v) => {
    if(isNaN(Number(v))) {
        return 0
    }
    else {
        return Number(v)
    }
}

const getFixedPrice = (statValue) => {
    let v = statValue
    let p = 0
        
        for(let f in fixedPrices) {
            if(f <= v) {
                p += n(fixedPrices[f])
                // console.log(fixedPrices[f])
            }
        }
    return p
}

useEffect(() => {
    setEdit(editMode)
  },[editMode, justSaved])

  if(edit)
  {  
    return (
        <div className={styles.statCounter}>
            <div><div className='lbl'>{name}</div></div>
            <div className={styles.pointsButtonsBox}>
                <div>
                    <button
                        onClick={(e)=>{
                            e.preventDefault()
                            setPoints(points - subPointsValue)
                            setValue([{value: Number(getValue(character, path) -1), path: path}], 'sub', false, subPointsValue)
                            }}
                        disabled={(typeof min === 'number' && getValue(character, path) <= min) ? true : false}
                        className={styles.pointsButton}
                    >
                    -1
                    </button> 
                    Zyskasz: {subPointsValue} pkt
                </div>
                <div>
                    <input 
                    type="number" 
                    disabled
                    value={getValue(character, path)} 
                    />
                </div>
                <div>
                    <button 
                        onClick={(e)=>{
                            e.preventDefault()
                            setPoints(points + addPointsValue)
                            setValue([{value: Number(getValue(character, path) )+1, path: path}], 'add', false, addPointsValue)
                            }}
                        disabled={(typeof max === 'number' &&  getValue(character, path) >= max) ? true : false}
                        className={styles.pointsButton}
                    >
                    +1
                    </button> 
                    Wydasz: {addPointsValue} pkt
                </div>
            </div>
            <div>
            {(Number(getPrice(getValue(character, path),true)) !== 0 && !fixedPrices) &&
            <> 
            {Number(getPrice(getValue(character, path),true)) < 0 
                ? <><div className='lbl'>Zysk:</div>
                <span className={styles.priceInput}>{getPrice(getValue(character, path),true)}</span>
                </> 
                : <><div className='lbl'>Koszt:</div>
                <span className={styles.priceInput}>{getPrice(getValue(character, path),false)}</span>
                </>  
            }
            </>
            }
            {(Number(getFixedPrice(getValue(character, path))) !== 0 && fixedPrices) &&
            <> 
            {Number(getFixedPrice(getValue(character, path))) < 0 
                ? <><div className='lbl'>Zysk:</div>
                <span className={styles.priceInput}>{getFixedPrice(getValue(character, path))}</span>
                </> 
                : <><div className='lbl'>Koszt:</div>
                <span className={styles.priceInput}>{getFixedPrice(getValue(character, path))}</span>
                </>  
            }
            </>
            }
            </div>
            {(edit && unsaved) &&
            <div>
                <button onClick={(e)=> {
                    // e.preventDefault()
                    handleSubmit(e)
                }}>Zapisz</button>
            </div>
            }
            {(edit && !editMode) &&
            <div>
                <button 
                onClick={(e)=>{
                    e.preventDefault()
                    setValue([
                        {
                            value: getValue(savedCharacter, path), 
                            path: path},
                        {
                            value: getValue(character,'points') - points,
                            path: 'points'
                        }
                    ])
                    setPoints(0)
                    setEdit(false)
                }}
                >
                Anuluj
                </button>
            </div>
            }
        </div>
        )
    }
    else {
        return (
            <div 
                className={styles.stat}
                onClick={(e) => {
                    e.preventDefault()
                    setEdit(true)
                  }}
            >
              <div><div className='lbl'>{name}</div></div> 
              <div className={styles.price}>{getValue(character, path)}</div>
              <div>
                {(Number(getPrice(getValue(character, path),true)) !== 0 && !fixedPrices) && 
                <> 
                {Number(getPrice(getValue(character, path),true)) < 0
                    ? <><div className='lbl'>Zysk:</div>
                        <span className={styles.price}>
                            {getPrice(getValue(character, path),true)}
                        </span>
                      </> 
                    : <><div className='lbl'>Koszt:</div>
                        <span className={styles.price}>
                            {getPrice(getValue(character, path),false)}
                        </span>
                      </>  
                }
                </>
                }
                {(Number(getFixedPrice(getValue(character, path))) !== 0 && fixedPrices) && 
                <> 
                {Number(getFixedPrice(getValue(character, path))) < 0
                    ? <><div className='lbl'>Zysk:</div>
                        <span className={styles.price}>
                            {getFixedPrice(getValue(character, path))}
                        </span>
                      </> 
                    : <><div className='lbl'>Koszt:</div>
                        <span className={styles.price}>
                            {getFixedPrice(getValue(character, path))}
                        </span>
                      </>  
                }
                </>
                }
              </div>
              <div>
              <button onClick={(e)=> {
                e.preventDefault()
                setEdit(true)
              }}>edytuj
              </button>
              </div>
            </div>
          )
    }
}

export default InputStatCounter
