import { useState, useEffect, useRef } from 'react'
import CharEditModal from '../CharEditModal'
import styles from './InputText.module.css'

function InputText({
  editMode,
  name, 
  character, 
  savedCharacter, 
  path, 
  setValue, 
  getValue, 
  justSaved,
  hideText,
  editButtonText,
  addButtonText,
  theme,
  subtype,
  additive,
  pricePath,
  hint,
  parseLinks,
  maxLength,
  handleSubmit,
  unsaved
}) {

const pointsPath = 'points'
  
const[showCharEditModal, setShowCharEditModal] = useState(false)
const[showHint, setShowHint] = useState(false)
const toFocus = useRef(null)
const[charsLeft, setCharsLeft] = useState(maxLength)

const handleConfirm = (item) => {
    let newItemPrice = (Number(item.price)) > 0 ? (Number(item.price)) : 0 
    setShowCharEditModal(false) 
    setEdit(false)
    if(additive){
      setValue([{value: getValue(character, path) + (getValue(character, path) ? ' + ' : '') + item.name, path: path}]) 
    }else {
      setValue([
        {
          value: item.name, 
          path: path
        },
        {
          value: newItemPrice,
          path: pricePath
        },
        {
          value: Number(getValue(character,'points'))
          - Number(getValue(character, pricePath))                   //price of old item
          + newItemPrice,                                            //price of new item
          path: pointsPath
        }
      ])
    }
      
  }
  const handleClose = () => {
    setShowCharEditModal(false)
  }
  
  const [edit, setEdit] = useState(editMode)

  useEffect(() => {
      setEdit(editMode)
  },[editMode, justSaved])

  useEffect(() => {
    if(toFocus.current && !editMode) {
        toFocus.current.focus()
    }
    // eslint-disable-next-line
  },[edit])

  if(edit){
  return (
    <>
    <div className={styles.InputTextEdit}>
      {showCharEditModal &&
              <CharEditModal 
                handleClose={handleClose} 
                handleConfirm={handleConfirm} 
                confirmText='Wybierz' 
                cancelText='Anuluj'
                theme={theme}
                subtype={subtype}
                universe={getValue(character,'universum')}
              >
                <p></p>
              </CharEditModal>
              }
      <div><div className='lbl'>{name} </div></div>
      <label>
          <input 
              type='text'
              onChange={(e)=>{
                setValue([{value: e.target.value, path: path}])
                setCharsLeft(Number(maxLength) - e.target.value.length)
              }}
              value={getValue(character,path)}
              maxLength={maxLength}
              ref={toFocus} 
              onFocus={()=>{
                  setTimeout(()=>{
                  setShowHint(true)
                },250)
                setCharsLeft(Number(maxLength) - getValue(character,path).length)
                }}       
              onBlur={()=>{
                  setTimeout(()=>{
                  setShowHint(false)
              },200)
            }}
          />
          <span>{!isNaN(charsLeft) && charsLeft}</span>
      </label>
      {subtype !== 'social_state' &&
        <div></div>
      }
      {subtype === 'social_state' &&
      <label><div className='lbl'>Koszt: </div>
      {/* {console.log(character)} */}
          <input 
              type="number"
              disabled={true}
              value={getValue(character, pricePath)} 
              className={styles.priceInput}
          />
          <button
              className={styles.pointsButton}
              onClick={(e)=>{
                  e.preventDefault()
                  setValue([{
                      value: Number(getValue(character,'points') )+1, 
                      path: pointsPath
                  },
                  {
                      value: Number(getValue(character, pricePath) )+1,
                      path: pricePath
                  }
                  ])
              }}
          >+</button>
          {Number(getValue(character, pricePath)) > 0 &&
          <button
             className={styles.pointsButton}
              onClick={(e)=>{
                  e.preventDefault()
                      setValue([{
                          value: Number(getValue(character,'points') -1), 
                          path: pointsPath
                      },
                      {
                          value: Number(getValue(character, pricePath) -1),
                          path: pricePath
                      }
                      ])
              }}
          >-</button>
          }
      </label>
      
      }
      <div>
      {(edit && unsaved) &&
      <div>
          <button onClick={(e)=> {
              // e.preventDefault()
              handleSubmit(e)
          }}>Zapisz</button>
      </div>
      }
      {(edit && theme) &&
      <div>
          <button onClick={(e)=> {
              e.preventDefault()
              setShowCharEditModal(true)
          }}>Inspiracje</button>
      </div>
      }
      
      {(edit && !editMode) &&
      <div>
        <button 
          onClick={(e)=>{
            e.preventDefault()
            let val = getValue(savedCharacter, path)
            if(pricePath)
            {
              setValue([
                {
                  value: val, 
                  path: path
                },
                {
                  value: getValue(savedCharacter, pricePath),
                  path: pricePath
                },
                {
                  value: Number(getValue(character,'points'))
                  - Number(getValue(character, pricePath))                   
                  + Number(getValue(savedCharacter, pricePath)),                                            
                  path: pointsPath
                }
              ])
            }else{
              setValue([
                {
                  value: val, 
                  path: path
                }
              ])
            }
             
            setEdit(false)
          }}
        >
          Anuluj
        </button>
      </div>
      }
      </div>
    </div>
    {(hint && parseLinks && showHint) &&
      <div className='hint'>
        <p>{parseLinks(hint)}</p>
      </div>
      }
      </>
  )
  }
  else {
    return (
      <div 
        onClick={(e) => {
          e.preventDefault()
          setEdit(true)
        }}
        className={styles.InputText}
      >
        {!hideText &&
        <> 
        <div><div className='lbl'>{name} </div> </div>
        <div>{getValue(character, path)}</div>
        <div></div> 
        {(subtype === 'social_state' && Number(getValue(character, pricePath)) !== 0) && <div><div className='lbl'>Koszt:</div> <span className={styles.price}>{getValue(character, pricePath)}</span></div>}
        </>}
        <div>
          <button 
          className={`${editButtonText && styles.AddItemBtn}`}
          onClick={(e)=> {
            e.preventDefault()
            setEdit(true)
          }}>{
            editButtonText 
            ? getValue(character,'appearance.portrait')
              ? editButtonText
              : addButtonText 
            : <>edytuj</>}
          </button>
        </div>
      </div>
    )
  }
}

export default InputText
