import styles from './Listing.module.css'
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase/config';
import emailjs from '@emailjs/browser'

const sendEmail = (email) => {

  const messageData = {
    to_email: email,
    from_email: 'malversum.rpg@gmail.com',
    subject: 'Witamy w Malversum',
    message: `Twoje konto zostało zaakceptowane. Od teraz możesz logować się do malversum.com przy użyciu adresu: ${email}`,
  };

  emailjs.send('service_8l9ufua', 'template_d995yy1', messageData, 'ayQwt9MvMF_5td0xT')
    .then((result) => {
        console.log(result.text);
    }, (error) => {
        console.log(error.text);
    });
};

const deleteFromDB = async (category, id, value) => {
  const ref = doc(db, category, id)
  await updateDoc(ref, {
    removed: value 
  });
}

const updateInDB = async (category, id, value, email) => {
  const ref = doc(db, category, id)
  await updateDoc(ref, {
    verified: value 
  });
  if(value) {
    sendEmail(email)
    console.log('test: email sent')
  }
}


function UsersList({users}) {

  return (
    <div className={styles.Listing}>
      <h2>Gracze</h2>
      <h3>Przyjęci Gracze:</h3>
      <ul> 
        {users && users.map(user => ((!user.admin && user.verified) &&
          <li key={user.id}>
            <div>{ user.email }</div>
            <div><button onClick={()=>{updateInDB('users',user.id,false)}}>Zablokuj</button></div>
            <div><button onClick={()=>{deleteFromDB('users',user.id,true)}}>Usuń</button></div>
          </li>
        ))}
      </ul>
      <h3>Oczekujący:</h3>
      <ul> 
        {users && users.map(user => ((!user.admin && !user.verified) &&
          <li key={user.id}>
            <div>{ user.email }</div>
            <div><button onClick={()=>{updateInDB('users',user.id,true,user.email)}}>Przyjmij</button></div>
            <div><button onClick={()=>{deleteFromDB('users',user.id,true)}}>Usuń</button></div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default UsersList
