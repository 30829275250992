import Login from "../components/Login"
import BigLogo from "../components/BigLogo"
import styles from './Authentication.module.css'
import { useState } from "react"
import { useSignup } from '../hooks/useSignup'
import { useLogin } from '../hooks/useLogin'


function Authentication() {

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { error : eL, login } = useLogin()
  const { error : eS, signup } = useSignup()

  // console.log('email: ',email, 'password: ', password)

  const handleSetEmail = (v) => {
    setEmail(v)
  }
  const handleSetPassword = (v) => {
    setPassword(v)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if(haveAccount) {
      login(email,password)
    }
    else {
      signup(email,password)
    }
    
  }

  const getErrMessage = (errFromDB) => {
    switch(true) {
      case errFromDB.includes('invalid-email'):
        return 'Nieprawidłowy email.'
      case errFromDB.includes('email-already-in-use'):
        return 'Ten adres email jest już w użyciu.'
      case errFromDB.includes('weak-password'):
        return 'Stwórz silniejsze hasło.'
      case errFromDB.includes('user-not-found'):
        return 'Nie ma Cię w bazie danych. Wyślij prośbę o przyjęcie.'
      default:
        return 'Coś poszło nie tak'
    }
  }
    
  const [haveAccount, setHaveAccount] = useState(true)

  return (
    <div className={styles.auth}>
        <BigLogo />
        <h3>{haveAccount ? <>Zaloguj się</> : <>Zgłoś chęć dołączenia</>}</h3>
        <Login 
          handleSubmit={handleSubmit} 
          handleSetEmail={handleSetEmail}
          handleSetPassword={handleSetPassword}
          email={email}
          password={password}
          haveAccount={haveAccount}
        />
        <button 
          className="linkLikeButton" 
          onClick={()=>{
            haveAccount ? setHaveAccount(false) : setHaveAccount(true)
          }}>
          {haveAccount ? <>lub zgłoś chęć dołączenia</> : <>lub zaloguj się</>}
        </button>
        {eL && console.log(eL)}
        {eS && console.log(eS)}
        {eS && <p className={styles.errorMessage}>{getErrMessage(eS)}</p>}
        {eL && <p className={styles.errorMessage}>{getErrMessage(eL)}</p>}
    </div>
  )
}

export default Authentication
