import { useGetListFromDB } from '../hooks/useGetListFromDB'; 
import styles from './Listing.module.css'
import { Link } from 'react-router-dom'
import { useAuthContext } from '../hooks/useAuthContext';
import { doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase/config';


const deleteCharacter = async (id) => {
  const ref = doc(db, 'characters', id)
  await deleteDoc(ref)
}
const recoverCharacter = async (id) => {
    const ref = doc(db, 'characters', id)
    await updateDoc(ref, {
        removed: false
    })
}

function RemovedCharactersList() {

  const category = 'characters'
  const { data } = useGetListFromDB(category, ['removed','==',true])
  const { userIsAdmin } = useAuthContext()

  return (
    <div className={styles.Listing}>
      <h2>Usunięte postacie</h2>
      <ul> 
        {data && data.map(d => (
          <li key={d.id}>
            <div>
              <Link to={`/${category}/${d.id}`}>{d.name}</Link>
              <div className={styles.excerpt}>{d.universum}</div>
            </div>
          {userIsAdmin && 
          <>
          <div>
          <button onClick={()=>{
            navigator.clipboard.writeText(d.id)
          }}>
            Kopiuj ID
          </button>
          </div>
          <div>
          <button onClick={()=>{
            deleteCharacter(d.id)
          }}>Usuń bezpowrotnie</button>
          </div>
          <div>
          <button onClick={()=>{
            recoverCharacter(d.id)
          }}>Przywróć</button>
          </div>
          </>
          }</li>
        ))}
      </ul>
    </div>
  )
}

export default RemovedCharactersList
