import styles from './NewArticle.module.css';
import { useAuthContext } from '../hooks/useAuthContext';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { doc, setDoc, collection } from 'firebase/firestore';
import { db } from '../firebase/config';
import parse, { domToReact } from 'html-react-parser';
import { selectClassNames } from '../misc/SelectClassNames';
import Select from 'react-select'

const category = 'articles'

const saveArticle = async (title, content, field, giveId) => {
  const ref = doc(collection(db, category))
  await setDoc(ref, {
    title: title,
    content: content,
    field: field,
    removed: false
  })
    .then(
        giveId(ref.id)
    )
    .catch(error => {
        console.log(error);
    })
    
}

function NewArticle() {
  const { userIsAdmin } = useAuthContext()
  const[title, setTitle] = useState('')
  const[content, setContent] = useState('')
  const[field, setField] = useState('Mechanika')

  const navigate = useNavigate()

  function parseWithLinks(text) {
    const options = {
      replace: ({ name, attribs, children }) => {
        if (name === 'a' && attribs.href) {
          return <span className={styles.pseudoLink}>{domToReact(children)}</span>;
        }
      }
    };
        
    return parse(text, options);
  }

  const submitHandle = (e) => {
    e.preventDefault()

    saveArticle(title, content, field, (id) => {
        navigate(`/articles/${id}`, {
          state: 
            {
              edit: true
            }
          }
        )
    })
  }

  const fieldsOptions = [
    // {value: '-', label: '-'},
    {value: 'Mechanika', label: 'Mechanika'},
    {value: 'Świat', label: 'Świat'},
    {value: 'Scenariusze', label: 'Scenariusze'},
    {value: 'Opowiadania', label: 'Opowiadania'}
  ]  



  const handleSelect = (selected) => {
    setField(selected.value)
  } 

  return (
    <div className={styles.Article}>
 
        {userIsAdmin &&
        <div>
          <div>
          
            <h2>{title}</h2>
            <p>{parseWithLinks(content)}</p>
          </div>
          <div>
            <form onSubmit={submitHandle} className={styles.NewArticle}>
              <div>Tytuł:</div>
              <label>
                <input 
                  type='text' 
                  onChange={(e)=>{setTitle(e.target.value)}}
                  value={title} 
                />
              </label>
              
              
              
              <div>Dziedzina:</div>
              <label>
              

              <Select 
                  options={fieldsOptions}
                  onChange={handleSelect}
                  value={{value: field, label: field}}
                  unstyled
                  classNames={selectClassNames}
              /> 

            </label>

              
              <div className={styles.hide}>Treść:</div>
              <label className={styles.hide}>
                <textarea 
                  onChange={(e)=>{setContent(e.target.value)}}
                  value={content} 
                />
              </label>
              <div>
              <button type='submit'>Zapisz</button>
              </div>
            </form>
          </div>
          </div>
        }

    </div>
  )
}

export default NewArticle
