import styles from './NewCharacter.module.css';
import { useAuthContext } from '../hooks/useAuthContext';
import { useNavigate, useLocation } from 'react-router-dom'
import CharacterEdition from '../components/CharacterEdition';
import { useState, useEffect } from 'react';
import { doc, setDoc, collection } from 'firebase/firestore';
import { db } from '../firebase/config';

const category = 'characters'
const saveCharacter = async (character, giveId) => {
    const ref = doc(collection(db, category))
    await setDoc(ref, character)
      .then(
          giveId(ref.id)
      )
      .catch(error => {
          console.log(error);
      })
      
  }

function NewCharacter() {
  const { user, dispatch } = useAuthContext()
  const[character, setCharacter] = useState({})
  const[invalid, setInvalid] = useState([])
  const[shouldValidate, setShouldValidate] = useState(0)

  const location = useLocation()
  const newCh = location.state ? location.state.newCh : false

  character.owner = user.uid
  character.removed = false
  if(!character.wealth){
    character.wealth = {level: 1}
  }
  
  const navigate = useNavigate()

  const handleSetCharacter = (v) => {
    setCharacter(v)
  }
  
  const handleSetShouldValidate = (v) => {
    setShouldValidate(v)
  }

  const getValue = (obj, path) => {
    // console.log('0: obj:',obj,' path: ',path)
    if(!obj) {
      return ''
    }
    const [head, ...rest] = path.split('.')
        if(rest.length && obj[head])
        {
          return getValue(obj[head], rest.join('.'))
        }
        else if(obj[head])
        { 
          return obj[head]
        }
        else 
        { 
          return ''
        }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const creationDate = new Date()
    character.creationDate = 
    + (Number(creationDate.getDate()) >= 10 ? creationDate.getDate() : '0' + creationDate.getDate()) + ':' 
    + ((creationDate.getMonth()+1) >= 10 ? (creationDate.getMonth()+1) : '0' + (creationDate.getMonth()+1)) + ':'
    + creationDate.getFullYear()
    character.creationTime = creationDate.getHours() + ':' 
    + (Number(creationDate.getMinutes()) >= 10 ? creationDate.getMinutes() : '0' + creationDate.getMinutes())
    setShouldValidate(shouldValidate+1)
    validate(['name', 'universum'], (cb)=>{
      if(!cb){
        saveCharacter(character, (id) => {
          // navigate(`/characters/${id}`, {edit: true})
          navigate(`/characters/${id}`, {
            state: 
              {
                edit: false
              }
            }
          )
          
        })
      }
    })
    
  }


  const validate = (v, cb) => {
    let invalids = new Set()
    v.forEach((a)=>{
      if(!character[a]){
        invalids.add(a)
      }
      else {
        invalids.delete(a)
      }    
    })
    invalids = Array.from(invalids)
    if(cb && invalids.length){
      cb(invalids)
    }
    else if(cb){
      cb(false)
    }

  //  console.log('invalids: ',invalids)
  setInvalid(invalids)
  }

  

useEffect(()=>{

    if(shouldValidate > 0){
      validate(['name','universum'])
    }
    // eslint-disable-next-line
  },[shouldValidate]) 

  const quit = () => {
    navigate('/characters');
  }

useEffect(()=>{
  // console.log('UNSAVED',unsaved)
  if(!newCh) {
    dispatch({ type: 'UNSAVED', payload: false })
    navigate('/characters')
  }
  else{
    navigate(location.pathname, {})
  }
// eslint-disable-next-line
},[])


  return (
    <div className={styles.Character}>

          {/* <div className={styles.controls}>
            <button onClick={()=>{
                quit()
            }}>
                Anuluj
            </button>
          </div> */}
            <CharacterEdition 
              character={character} 
              handleSetCharacter={handleSetCharacter} 
              getValue={getValue} 
              handleSubmit={handleSubmit}
              handleSetShouldValidate={handleSetShouldValidate}
              invalid={invalid}
              shouldValidate={shouldValidate}
              isNewCharacter={true}
              editMode={true}
              quit={quit}
            />   
            

    </div>
  )
}


export default NewCharacter
