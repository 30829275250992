import styles from './AnchorsMenu.module.css'

const scrollTo = (id) => {
    const offset = document.getElementById('appHeader').offsetHeight + 10
    window.scrollTo({
        behavior: "smooth",
        top:
            document.getElementById(id).getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            offset,
    });
}

function AnchorsMenu() {
  return (
    <div className={styles.AnchorsMenu}>
        <button className='linkLikeButton' onClick={()=>{scrollTo('metrics')}}>Metryka</button>
        <button className='linkLikeButton' onClick={()=>{scrollTo('competences')}}>Kompetencje</button>
        <button className='linkLikeButton' onClick={()=>{scrollTo('abilities')}}>Zdolności</button>
        <button className='linkLikeButton' onClick={()=>{scrollTo('utensilia')}}>Utensylia</button>
        <button className='linkLikeButton' onClick={()=>{scrollTo('inventory')}}>Inwentarz</button>
        <button className='linkLikeButton' onClick={()=>{scrollTo('resources')}}>Zasoby</button>
        <button className='linkLikeButton' onClick={()=>{scrollTo('progresses')}}>Rozwój</button>
    </div>
  )
}

export default AnchorsMenu
