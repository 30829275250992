import UsersList from "../components/UsersList"
import RemovedArticlesList from "../components/RemovedArticlesList"
import RemovedCharactersList from "../components/RemovedCharactersList"
import { useGetListFromDB } from "../hooks/useGetListFromDB"
import styles from './Home.module.css'
import Loading from "../components/Loading"

function Admin() {

  const { data : usersInDB } = useGetListFromDB('users',['removed','==',false])

  if(usersInDB) {
    return (
      <div className={styles.HomeContent}>
        <h1>ADMIN</h1>
        {usersInDB && <UsersList users={usersInDB} />}
        <RemovedArticlesList />
        <RemovedCharactersList />
      </div>
    )
  }
  else {
    return (
      <Loading />
    )
  }
  
}

export default Admin
