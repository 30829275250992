import { createContext, useReducer, useEffect } from "react";
import { auth } from "../firebase/config";
import { onAuthStateChanged } from 'firebase/auth'
import { db } from '../firebase/config';
import { getDoc, doc } from 'firebase/firestore';

export const AuthContext = createContext()

export const authReducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN':
            return { ...state, user: action.payload }
        case 'VERIFY_REMOVED':
            return {...state, userIsRemoved: action.payload}
        case 'VERIFY_USER':
            return {...state, userIsVerified: action.payload}
        case 'VERIFY_ADMIN':
            return {...state, userIsAdmin: action.payload}
        case 'LOGOUT':
            return { ...state, user: null, userIsAdmin: false, userIsVerified: false }
        case 'AUTH_IS_READY':
            return { ...state, user: action.payload, authIsReady: true }
        case 'USER_CHECK_PERMISSIONS':
            return { ...state, userPermissionsChecked: action.payload}
        case 'UNSAVED':
            return { ...state, unsaved: action.payload}
        default:
            return state
    }
}

export const AuthContextProvider = ({ children }) => { 
    const [state, dispatch] = useReducer(authReducer, {
        user: null,
        userIsRemoved: false,
        userIsVerified: false,
        userIsAdmin: false,
        authIsReady: false,
        userPermissionsChecked: false,
        unsaved: false
    })

    useEffect(() => {
        const unsub = onAuthStateChanged(auth, user => {
            dispatch({ type: 'AUTH_IS_READY', payload: user})
            if(user)
            {
                getDoc(doc(db, 'users', user.uid))
                .then(async(doc) => {
                    await dispatch({ 
                        type: 'VERIFY_REMOVED', 
                        payload: doc.data().removed
                    })
                    await dispatch({ 
                        type: 'VERIFY_USER', 
                        payload: doc.data().verified
                    })
                    await dispatch({ 
                        type: 'VERIFY_ADMIN', 
                        payload: doc.data().admin
                    })
                    await dispatch({
                        type: 'USER_CHECK_PERMISSIONS',
                        payload: true
                    })   
                })
                .catch((err)=> {
                    console.log('auth ERROR: ',err.message)
                })               
       
            }
            unsub()
        })
    }, [])

    return (
        <AuthContext.Provider value={{ ...state, dispatch }}>
            { children }
        </AuthContext.Provider>

    )
}