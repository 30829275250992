import { useState, useEffect, useRef } from "react";
import { db } from '../firebase/config';
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { useAuthContext } from "./useAuthContext";



export const useGetListFromDB = (category, _q, owner) => {

    const { user } = useAuthContext()

    const [data, setData] = useState(null)
    
    const q = useRef(_q).current
    
      useEffect(() => {
        // category === 'characters' && console.log('test')
        let ref = collection(db, category)
        if(q) {
          ref = query(ref, where(...q))
        }
        if(owner) {
          let o = ['owner','==',user.uid]
          ref = query(ref, where(...o))
        }
        
        const unsub = onSnapshot(ref, (snapshot) => {

          let results = []
          snapshot.docs.forEach(doc => {
            // category === 'characters' && console.log('doc.id:',doc.id)
            results.push({ ...doc.data(), id: doc.id })
          })
          setData(results)
        })
        return () => unsub()
        
      }, [category, q, owner, user.uid])

    
        return { data }  
}