import { useState, useEffect, useRef } from "react"
import styles from './InputText.module.css'

function InputNumber({
  editMode, 
  unit, 
  name, 
  character, 
  savedCharacter, 
  path, 
  setValue, 
  getValue, 
  justSaved,
  hint,
  parseLinks,
  handleSubmit,
  unsaved
}) {

  const [edit, setEdit] = useState(editMode)
  const[showHint, setShowHint] = useState(false)
  const toFocus = useRef(null)

  useEffect(() => {
    setEdit(editMode)
  },[editMode, justSaved])

  useEffect(() => {
    if(toFocus.current && !editMode) {
        toFocus.current.focus()
    }
    // eslint-disable-next-line
  },[edit])

  if(edit){
  return (
  <>
  <div className={styles.InputTextEdit}>  
    <div><div className='lbl'>{name}</div> </div>
    <label>
        <input 
            type='number'
            onChange={(e)=>{setValue([{value: Number(e.target.value), path: path}])}} 
            value={getValue(character,path)} 
            ref={toFocus} 
            onFocus={()=>{ setShowHint(true) }}
            onBlur={()=>{
              setTimeout(()=>{
                setShowHint(false)
              },200)
            }}
        />
        {unit}
    </label>
    {(edit && unsaved) &&
      <div>
          <button onClick={(e)=> {
              // e.preventDefault()
              handleSubmit(e)
          }}>Zapisz</button>
      </div>
      }
    {(edit && !editMode) &&
        <div>
          <button 
          onClick={(e)=>{
              e.preventDefault()
              setValue([{value: getValue(savedCharacter, path), path: path}])
              setEdit(false)
          }}
          >
          Anuluj
          </button>
        </div>  
      }
  </div>
  {(hint && parseLinks && showHint) &&
    <div className='hint'>
      <p>{parseLinks(hint)}</p>
    </div>
  }
  </>
  )
  }
  else {
    return (
      <div 
        className={styles.InputText}
        onClick={(e) => {
          e.preventDefault()
          setEdit(true)
        }}
      >
        <div><div className='lbl'>{name}</div> </div>
        <div><span className={styles.price}>{getValue(character, path)}</span> {unit}</div>
        <div></div>
        <div>
        <button onClick={(e)=> {
          e.preventDefault()
          setEdit(true)
        }}>edytuj
        </button>
        </div>
      </div>
    )
  }
}

export default InputNumber
