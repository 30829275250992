import styles from './Points.module.css'
import { useState, useEffect } from 'react'


function Points({setValue, getValue, character}) {

  const[update,setUpdate] = useState(false)

  useEffect(()=>{
    setUpdate(true)
    setTimeout(()=>{
      setUpdate(false)
    },100)
  },[character.points])

  const getPointsPercent = () => {
    let r = (Number(getValue(character,'points')) / Number(getValue(character,'budget')) * 100)
    if(r>100) {
      r = 100
    }
    return r+'%'
  }

  return (
    <div className={styles.points}>
      <div 
        className={`${styles.progressBar} ${Number(getValue(character,'points')) > Number(getValue(character,'budget')) && styles.pointsValueExceed}`} 
        style={{width: getPointsPercent()}}>
      </div>
      <div>Wydane punkty:</div>
      <div className={styles.pointsValue}>
        <span className={`
          ${styles.spent} 
          ${Number(getValue(character,'points')) > Number(getValue(character,'budget')) && styles.overSpent}
          ${update && styles.update}
        `}>
          {getValue(character,'points')}
        </span>
         / {getValue(character,'budget')}</div>
      <div>
      {/* <progress max={getValue(character,'budget')} value={getValue(character,'points')} /> */}
      Budżet:
      </div>
      <div className={styles.budgetButtons}>
      <button
        onClick={(e)=>{
          e.preventDefault()
          setValue([
            {
              value: Number(getValue(character, 'budget')) -1,
              path: 'budget'
            }
          ])
        }}
      >-1</button>
      <button
        onClick={(e)=>{
          e.preventDefault()
          setValue([
            {
              value: Number(getValue(character, 'budget')) +1,
              path: 'budget'
            }
          ])
        }}
      >+1</button>
      </div>
    </div>
  )
}

export default Points
