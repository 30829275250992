import { useState, useEffect, useRef } from 'react'
import styles from './Mount.module.css'

function InputMount({
    path, 
    character, 
    savedCharacter,
    newAdded,
    hasAnyFilledChildren,
    editMode, 
    getValue, 
    setValue, 
    justSaved,
    maxLength,
    maxLengthTA,
    handleSubmit,
    unsaved
}) {
    
    const [edit, setEdit] = useState(editMode)
    const[charsLeft, setCharsLeft] = useState(maxLength)
    const[charsLeftTA, setCharsLeftTA] = useState(maxLengthTA)

    const namePath = `${path}.name`
    const speedPath = `${path}.speed`
    const couragePath = `${path}.courage`
    const maneuverabilityPath = `${path}.maneuverability`
    const endurancePath = `${path}.endurance`
    const descriptionPath = `${path}.description`
    const removePath = `${path}.removed`
    const pointsPath = 'points'
    const pricePath = `${path}.price`

    const prevEditMode = useRef(false)
    const prevJustSaved = useRef(justSaved)
    const toFocus = useRef(null)
    const anyFilledChildren = getValue(character, path) 
    ? hasAnyFilledChildren(character[path]) 
    : false


const cancel = (e) => {
    // console.log('cancel')
    e && e.preventDefault()
    setValue([
        {
            value: getValue(savedCharacter, namePath),
            path: namePath
        },
        {
            value: getValue(savedCharacter, speedPath),
            path: speedPath
        },
        {
            value: getValue(savedCharacter, couragePath),
            path: couragePath
        },
        {
            value: getValue(savedCharacter, maneuverabilityPath),
            path: maneuverabilityPath
        },
        {
            value: getValue(savedCharacter, endurancePath),
            path: endurancePath
        },
        {
            value: getValue(savedCharacter, descriptionPath),
            path: descriptionPath
        },
        {
            value: getValue(savedCharacter, pricePath),
            path: pricePath
        },
        {
            value: Number(getValue(character,'points'))
            - Number(getValue(character, pricePath))                   
            + Number(getValue(savedCharacter, pricePath)),                                            
            path: pointsPath
        }
    ])
    setEdit(false)

}

const remove = (e, hard) => {
    // console.log('remove')
    e && e.preventDefault()
    let p = getValue(character,pricePath)
    setValue(
        [
            {
                value: getValue(savedCharacter, namePath),
                path: namePath
            },
            {
                value: getValue(savedCharacter, speedPath),
                path: speedPath
            },
            {
                value: getValue(savedCharacter, couragePath),
                path: couragePath
            },
            {
                value: getValue(savedCharacter, maneuverabilityPath),
                path: maneuverabilityPath
            },
            {
                value: getValue(savedCharacter, endurancePath),
                path: endurancePath
            },
            {
                value: getValue(savedCharacter, descriptionPath),
                path: descriptionPath
            },
            {
                value: '',
                path: pricePath
            },
            {
                value: p >= 0 ? getValue(character,'points') - p : getValue(character,'points') + p,
                path: 'points'
            },
            {
                value: true,
                path: removePath
            }
        ],
        false, hard ? true : false
    )
    setEdit(false) 
}

useEffect(() => {
    if(toFocus.current && !editMode) {
        toFocus.current.focus()
    }
    // eslint-disable-next-line
},[edit])

useEffect(() => {

    if(prevJustSaved.current !== justSaved) {
        cancel()    
    }
    else {
        if(!anyFilledChildren && !edit && newAdded) {
            // console.log(path,': FORCED EDIT')
            setEdit(true)
        }
    }

    if(prevEditMode.current !== editMode)
    {
        prevEditMode.current = editMode
        setEdit(editMode)    
        if (prevEditMode.current === false && !anyFilledChildren) {
            cancel()
        }
    }

    setCharsLeftTA(Number(charsLeftTA) - getValue(character, descriptionPath).length)

// eslint-disable-next-line
  },[justSaved, editMode])

    if(edit)
    {
      return (
      <div className={styles.MountEdit}>
        <div>
            <div><div className='lbl'>Imię / gatunek:</div></div>
                <label><input 
                    type="text"
                    onChange={(e)=>{
                        setValue(
                            [{value: e.target.value, path: namePath}])
                            setCharsLeft(Number(maxLength) - e.target.value.length)
                        }}
                    value={getValue(character, namePath)} 
                    maxLength={maxLength}
                    ref={toFocus} 
                    onFocus={()=>{
                        setCharsLeft(Number(maxLength) - getValue(character, namePath).length)
                    }}
                />
                <span>{charsLeft}</span>
                </label>
                
        </div>

            <div>
                <div><div className='lbl'>Szybkość:</div></div> 
                <div>
                    <input 
                        type="number"
                        onChange={(e)=>{setValue([{value: Number(e.target.value), path: speedPath}])}}
                        value={getValue(character, speedPath)} 
                        disabled={true}
                    />
                    <button
                        className={styles.pointsButton}
                        onClick={(e)=>{
                            e.preventDefault()
                            setValue([
                            {
                                value: Number(getValue(character, speedPath) )+1,
                                path: speedPath
                            }
                            ])
                        }}
                    >+</button>
                    <button
                        className={styles.pointsButton}
                        onClick={(e)=>{
                            e.preventDefault()
                            setValue([
                            {
                                value: Number(getValue(character, speedPath) -1),
                                path: speedPath
                            }
                            ])
                        }}
                    >-</button>
                </div>
            </div>
            <div>
                <div><div className='lbl'>Zwrotność: </div></div>
                <div>
                    <input 
                        disabled={true}
                        type="number"
                        onChange={(e)=>{setValue([{value: Number(e.target.value), path: maneuverabilityPath}])}}
                        value={getValue(character, maneuverabilityPath)} 
                    />
                    <button
                        className={styles.pointsButton}
                        onClick={(e)=>{
                            e.preventDefault()
                            setValue([
                            {
                                value: Number(getValue(character, maneuverabilityPath) )+1,
                                path: maneuverabilityPath
                            }
                            ])
                        }}
                    >+</button>
                    <button
                        className={styles.pointsButton}
                        onClick={(e)=>{
                            e.preventDefault()
                            setValue([
                            {
                                value: Number(getValue(character, maneuverabilityPath) -1),
                                path: maneuverabilityPath
                            }
                            ])
                        }}
                    >-</button>
                </div>
            </div>

            <div>
                <div><div className='lbl'>Odwaga: </div></div>
                <div>
                    <input 
                        disabled={true}
                        type="number"
                        onChange={(e)=>{setValue([{value: Number(e.target.value), path: couragePath}])}}
                        value={getValue(character, couragePath)} 
                    />
                    <button
                        className={styles.pointsButton}
                        onClick={(e)=>{
                            e.preventDefault()
                            setValue([
                            {
                                value: Number(getValue(character, couragePath) )+1,
                                path: couragePath
                            }
                            ])
                        }}
                    >+</button>
                    <button
                        className={styles.pointsButton}
                        onClick={(e)=>{
                            e.preventDefault()
                            setValue([
                            {
                                value: Number(getValue(character, couragePath) -1),
                                path: couragePath
                            }
                            ])
                        }}
                    >-</button>
                </div>
            </div>
            <div>
                <div><div className='lbl'>Wytrzymałość / zasięg (km):</div> </div>
                <div>
                    <input 
                        type="number"
                        disabled={true}
                        onChange={(e)=>{setValue([{value: Number(e.target.value), path: endurancePath}])}}
                        value={getValue(character, endurancePath)} 
                    />
                    <button
                        className={styles.pointsButton}
                        onClick={(e)=>{
                            e.preventDefault()
                            setValue([
                            {
                                value: Number(getValue(character, endurancePath) )+1,
                                path: endurancePath
                            }
                            ])
                        }}
                    >+</button>
                    <button
                        className={styles.pointsButton}
                        onClick={(e)=>{
                            e.preventDefault()
                            setValue([
                            {
                                value: Number(getValue(character, endurancePath) -1),
                                path: endurancePath
                            }
                            ])
                        }}
                    >-</button>
                </div>
            </div>
        
        <div>
            <label><div className='lbl'>Opis: </div>
                <textarea 
                    maxLength={maxLengthTA}
                    onChange={(e)=>{
                        setValue([{value: e.target.value, path: descriptionPath}])
                        setCharsLeftTA(Number(maxLengthTA) - e.target.value.length)
                    }}
                    value={getValue(character, descriptionPath)} 
                />
            <span>{charsLeftTA}</span>
            </label>
        </div>
        <div>
        <div><div className='lbl'>Koszt:</div> </div>
        <div>
                {/* {console.log(character)} */}
                    <input 
                        type="number"
                        disabled={true}
                        value={getValue(character, pricePath)} 
                    />
                    <button
                        className={styles.pointsButton}
                        onClick={(e)=>{
                            e.preventDefault()
                            setValue([{
                                value: Number(getValue(character,'points') )+1, 
                                path: pointsPath
                            },
                            {
                                value: Number(getValue(character, pricePath) )+1,
                                path: pricePath
                            }
                            ])
                        }}
                    >+</button>
                    {Number(getValue(character, pricePath)) > 0 &&
                    <button
                        className={styles.pointsButton}
                        onClick={(e)=>{
                            e.preventDefault()
                                setValue([{
                                    value: Number(getValue(character,'points') -1), 
                                    path: pointsPath
                                },
                                {
                                    value: Number(getValue(character, pricePath) -1),
                                    path: pricePath
                                }
                                ])
                        }}
                    >-</button>
                    }
            </div>
        </div>

        <div>
        {(edit && unsaved) &&
            <div>
                <button onClick={(e)=> {
                    // e.preventDefault()
                    handleSubmit(e)
                }}>Zapisz</button>
            </div>
            }
        {(edit && !editMode) &&
            <div>
            <button onClick={(e)=>{
                (anyFilledChildren && Object.keys(getValue(savedCharacter, path)).length > 0) 
                ? cancel(e) 
                : remove(e, true)
            }}
            >Anuluj</button>
            </div>
        }
        {edit &&
            <div>
            <button onClick={(e)=> {
                (anyFilledChildren && Object.keys(getValue(savedCharacter, path)).length > 0) 
                ? remove(e)
                : remove(e, true)
            }}>Usuń</button>
            </div>
        }

        </div>
    </div>
    )
    }
    else if(anyFilledChildren) {
        return (
        <div 
            className={styles.Mount}
            onClick={(e) => {
                e.preventDefault()
                setEdit(true)
              }}
        >
          
            <div>
                <div><div className='lbl'>Imię / gatunek: </div></div><div>{getValue(character,namePath)}</div>
            </div>
            <div>
                <div><div className='lbl'>Szybkość:</div></div> <div className={styles.price}>{getValue(character,speedPath)}</div>
            </div>
            <div>    
                <div><div className='lbl'>Zwrotność:</div></div> <div className={styles.price}>{getValue(character,maneuverabilityPath)}</div>
            </div>
            <div>
                <div><div className='lbl'>Odwaga:</div></div> <div className={styles.price}>{getValue(character,couragePath)}</div>
            </div>
            <div>
                <div><div className='lbl'>Wytrzymałość / zasięg (km):</div> </div><div className={styles.price}>{getValue(character,endurancePath)}</div>
            </div>
            <div>
                <div><div className='lbl'>Cechy:</div></div><div> {getValue(character,descriptionPath)}</div>
            </div>
            <div>
                <section></section><div><span><div className='lbl'>Koszt:</div></span> <span className={styles.price}>{getValue(character,pricePath)}</span></div>
            </div>
                  
            <div>
                <div>
            <button onClick={(e)=> {
                e.preventDefault()
                setEdit(true)
            }}>edytuj</button>
                </div>
            </div>

        </div>
      ) 
    }
    // else {
    //     return (
    //         <div>ERROR</div>
    //     )
    // }

}

export default InputMount
