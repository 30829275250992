import coverLogo from '../assets/Logo malversum - WEB - FULL - dark or light bcg.png'
import styles from './BigLogo.module.css'

function bigLogo() {
  return (
    <div className={styles.AppLink}>
    <a
          href="./"
          rel="noopener noreferrer"
        >
         <img src={coverLogo} className={styles.AppLogo} alt="logo" />
    </a>
    </div>
  )
}

export default bigLogo
