export const selectClassNames = {
    control: (state) => state.isFocused ? 'rSelect-control-focus' : 'rSelect-control',
    clearIndicator: (state) => state.isFocused ? 'rSelect-clearIndicator-focus' : 'rSelect-clearIndicator',
    container: (state) => state.isFocused ? 'rSelect-container-focus' : 'rSelect-container',
    dropdownIndicator: (state) => state.isFocused ? 'rSelect-dropdownIndicator-focus' : 'rSelect-dropdownIndicator',
    group: (state) => state.isFocused ? 'rSelect-group-focus' : 'rSelect-group',
    groupHeading: (state) => state.isFocused ? 'rSelect-groupHeading-focus' : 'rSelect-groupHeading',
    indicatorsContainer: (state) => state.isFocused ? 'rSelect-indicatorsContainer-focus' : 'rSelect-indicatorsContainer',
    indicatorSeparator: (state) => state.isFocused ? 'rSelect-indicatorSeparator-focus' : 'rSelect-indicatorSeparator',
    input: (state) => state.isFocused ? 'rSelect-input-focus' : 'rSelect-input',
    loadingIndicator: (state) => state.isFocused ? 'rSelect-loadingIndicator-focus' : 'rSelect-loadingIndicator',
    loadingMessage: (state) => state.isFocused ? 'rSelect-loadingMessage-focus' : 'rSelect-loadingMessage',
    menu: (state) => state.isFocused ? 'rSelect-menu-focus' : 'rSelect-menu',
    menuList: (state) => state.isFocused ? 'rSelect-menuList-focus' : 'rSelect-menuList',
    menuPortal: (state) => state.isFocused ? 'rSelect-menuPortal-focus' : 'rSelect-menuPortal',
    noOptionsMessage: (state) => state.isFocused ? 'rSelect-noOptionsMessage-focus' : 'rSelect-noOptionsMessage',
    option: (state) => state.isFocused ? 'rSelect-option-focus' : 'rSelect-option',
    placeholder: (state) => state.isFocused ? 'rSelect-placeholder-focus' : 'rSelect-placeholder',
    singleValue: (state) => state.isFocused ? 'rSelect-singleValue-focus' : 'rSelect-singleValue',
    valueContainer: (state) => state.isFocused ? 'rSelect-valueContainer-focus' : 'rSelect-valueContainer',
  }