import { useState, useEffect, useRef } from "react"
import styles from './InputText.module.css'

function InputTextarea({
  editMode, 
  name, 
  character, 
  savedCharacter, 
  path, 
  setValue, 
  getValue, 
  justSaved,
  pricePath,
  hasPrice,
  hint,
  parseLinks,
  maxLength,
  handleSubmit,
  unsaved
}) {

  const [edit, setEdit] = useState(editMode)
  const pointsPath = 'points'
  const[showHint, setShowHint] = useState(false)
  const toFocus = useRef(null)
  const[charsLeft, setCharsLeft] = useState(maxLength)

  useEffect(() => {
    setEdit(editMode)
  },[editMode, justSaved])

  useEffect(() => {
    if(toFocus.current && !editMode) {
        toFocus.current.focus()
    }
    // eslint-disable-next-line
  },[edit])

  if(edit){
  return (
  <>
  <div className={`${styles.InputTextEdit} ${styles.hasSection}`}>
    <div>{path === 'temporary_values.state' ? <div className='lbl'>Opis:</div> : <div className='lbl'>{name}</div>}</div>
    <label>
        <textarea 
            onChange={(e)=>{
              setValue([{value: e.target.value, path: path}])
              setCharsLeft(Number(maxLength) - e.target.value.length)
            }} 
            value={getValue(character,path)} 
            maxLength={maxLength}
            ref={toFocus} 
            onFocus={()=>{
              setTimeout(()=>{
              setShowHint(true)
              },250)
              setCharsLeft(Number(maxLength) - getValue(character,path).length)
              }
            } 
          onBlur={()=>{
              setTimeout(()=>{
              setShowHint(false)
          },200) 
          }}
        />
        <span>{charsLeft}</span>
    </label>
    {hasPrice &&
      <label><div className='lbl'>Koszt: </div>
      {/* {console.log(character)} */}
          <input 
              type="number"
              disabled={true}
              value={getValue(character, pricePath)} 
              className={styles.priceInput}
          />
          <button
              className={styles.pointsButton}
              onClick={(e)=>{
                  e.preventDefault()
                  setValue([{
                      value: Number(getValue(character,'points') )+1, 
                      path: pointsPath
                  },
                  {
                      value: Number(getValue(character, pricePath) )+1,
                      path: pricePath
                  }
                  ])
              }}
          >+</button>
          {Number(getValue(character, pricePath)) > 0 &&
          <button
            className={styles.pointsButton}
              onClick={(e)=>{
                  e.preventDefault()
                      setValue([{
                          value: Number(getValue(character,'points') -1), 
                          path: pointsPath
                      },
                      {
                          value: Number(getValue(character, pricePath) -1),
                          path: pricePath
                      }
                      ])
              }}
          >-</button>
          }
      </label>
      }
      {(edit && unsaved) &&
      <div>
          <button onClick={(e)=> {
              // e.preventDefault()
              handleSubmit(e)
          }}>Zapisz</button>
      </div>
      }
    {(edit && !editMode) &&
      <>
        {/* <div></div> */}
        <div>
          <button 
          onClick={(e)=>{
              e.preventDefault()
              let val = getValue(savedCharacter, path)
              if(hasPrice)
              {
                setValue([
                  {
                    value: val, 
                    path: path
                  },
                  {
                    value: getValue(savedCharacter, pricePath),
                    path: pricePath
                  },
                  {
                    value: Number(getValue(character,'points'))
                    - Number(getValue(character, pricePath))                   
                    + Number(getValue(savedCharacter, pricePath)),                                            
                    path: pointsPath
                  }
                ])
              }else{
                setValue([
                  {
                    value: val, 
                    path: path
                  }
                ])
              }
              setEdit(false)
          }}
          >
          Anuluj
          </button>
        </div>
      </>
      }
  </div>
  {(hint && parseLinks && showHint) &&
    <div className='hint'>
      <p>{parseLinks(hint)}</p>
    </div>
    }
    </>
  )
  }
  else {
    return (
      <div 
        className={`${styles.InputText} ${styles.hasSection}`}
        onClick={(e) => {
          e.preventDefault()
          setEdit(true)
        }}
      >
        <div>{path === 'temporary_values.state' ? <div className='lbl'>Opis:</div> : <div className='lbl'>{name}</div>}</div>
        <section>{getValue(character, path)}</section>
        {(hasPrice && Number(getValue(character, pricePath)) !== 0) && <div><div className='lbl'>Koszt:</div> <span className={styles.price}>{getValue(character, pricePath)}</span></div>}
        <div>
          <button onClick={(e)=> {
            e.preventDefault()
            setEdit(true)
          }}>edytuj
          </button>
        </div>
      </div>
    )
  }
}

export default InputTextarea
