import styles from './CharacterSheet.module.css';
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { useState, useEffect, memo, useRef } from 'react';
import { doc, updateDoc, collection, onSnapshot, deleteField } from 'firebase/firestore';
import { db } from '../firebase/config';
import Modal from '../components/Modal';
import CharacterEdition from '../components/CharacterEdition';
import { useAuthContext } from '../hooks/useAuthContext';
import Loading from '../components/Loading';


const category = 'characters'

const deleteCharacter = async (id) => {
  const ref = doc(db, category, id)
  await updateDoc(ref, {
    removed: true
  })
}
const updateCharacter = async (id, c) => {
  const ref = doc(db, category, id)
  try {
    await updateDoc(ref, c)
  } catch(e) {
    console.log('DB update error: ', e)
  }
}

function CharacterSheet() {
  const { id } = useParams()
  const { state : mode } = useLocation()
  const characterId = id
  const navigate = useNavigate()

  const[character, setCharacter] = useState({})
  const[savedCharacter, setSavedCharacter] = useState({})
  const[editMode, setEditMode] = useState(false)
  const[showModal, setShowModal] = useState(false)
  const [showUnsavedModal, setShowUnsavedModal] = useState(false)
  const[invalid, setInvalid] = useState([])
  const[shouldValidate, setShouldValidate] = useState(0)
  const [justSaved, setJustSaved] = useState(0)
  const prevJustSaved = useRef(0)

  const[modalDestiation,setModalDestination] = useState('/characters')

  const { unsaved, dispatch } = useAuthContext()
  const handleUnsavedConfirm = () => {
    dispatch({ type: 'UNSAVED', payload: false })
    setShowUnsavedModal(false)
    navigate(modalDestiation)
  }

  const handleUnsavedClose = () => {
    setShowUnsavedModal(false)
  }

  const handleClose = () => {
    setShowModal(false)
  }
  const handleConfirm = () => {
    deleteCharacter(id);
    setShowModal(false)  
  }
  const goBackToCharactersList = () => {
    setTimeout(()=>{
      navigate('/characters');
    },1500)
  }
  

  useEffect(()=>{
      (mode && mode.edit) && setEditMode(true)
      let ref = doc(collection(db, category), id)
      const unsub = onSnapshot(ref, (snapshot) => {
        let c = snapshot.data()
        setSavedCharacter(c)
        setCharacter(c)
      })
      
      return () => unsub
  },[id, mode])

  useEffect(() => {
    if(justSaved !== prevJustSaved.current) {
      // console.log('jS: ',justSaved,' pJS.cur: ',prevJustSaved.current)
      setEditMode(false)
    }
  },[justSaved])
  
  useEffect(()=>{
    if(!editMode) { window.scrollTo(0,0) }
  },[editMode])

  const validate = (v, cb) => {
    let invalids = new Set()
    v.forEach((a)=>{
      if(!character[a]){
        invalids.add(a)
      }
      else {
        invalids.delete(a)
      }    
    })
    invalids = Array.from(invalids)
    if(cb && invalids.length){
      cb(invalids)
    }
    else if(cb){
      cb(false)
    }

  //  console.log('invalids: ',invalids)
  setInvalid(invalids)
  }


  const prepareCharacterToSave = (ch, forDB) => {
    let obj = ch
    obj.abilities && Object.entries(obj.abilities).forEach((e) => {
      if(e[1]['removed']){
        delete obj['abilities'][e[0]]
      }
    })
    obj.disabilities && Object.entries(obj.disabilities).forEach((e) => {
      if(e[1]['removed']){
        delete obj['disabilities'][e[0]]
      }
    })
    obj.misc_items && Object.entries(obj.misc_items).forEach((e) => {
      if(e[1]['removed']){
        delete obj['misc_items'][e[0]]
      }
    })

    if(forDB) {
      for(let itm in obj) {
        if(obj[itm]['removed']) {
          // obj[itm] = deleteField()
          obj = {...obj, [itm]: deleteField()}
        } 
      }
    } else {
      for(let itm in obj) {
        if(obj[itm]['removed']) {
          const { [itm]: removedProperty, ...restObject } = obj;
          obj = restObject
        }
      }
    }
    // console.log('fdb?',forDB, 'char: ', obj)
    return obj
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setShouldValidate(shouldValidate+1)
    validate(['name', 'universum'], (cb)=>{
      if(!cb){
        let prepCharDB = prepareCharacterToSave(character, true)
        let prepChar = prepareCharacterToSave(character, false)
        updateCharacter(characterId, prepCharDB)
        setCharacter(prepChar)
        // console.log('prepchar: ',prepChar)
        setSavedCharacter(prepChar)
        setJustSaved(justSaved+1)
      }
    })
  }
  const handleSetCharacter = (v) => {
    setCharacter(v)
  }
  const handleSetSavedCharacter = (v) => {
    setSavedCharacter(v)
  }
  const handleSetShouldValidate = (v) => {
    setShouldValidate(v)
  }

  const getValue = (obj, path) => {
    // console.log('path: ',path)
    if(!obj) {
      return ''
    }
    const [head, ...rest] = path.split('.')
        if(rest.length && obj[head])
        {
          return getValue(obj[head], rest.join('.'))
        }
        else if(obj[head])
        { 
          return obj[head]
        }
        else 
        { 
          return ''
        }
  }

  const quit = () => {
    if(unsaved) {
      setShowUnsavedModal(true)
    } 
    else {
      navigate('/characters')
    }
  }

  useEffect(()=>{
    if(shouldValidate > 0){
      validate(['name','universum'])
    }
    // eslint-disable-next-line
  },[shouldValidate]) 


  const handleGoToPrint = () => {
    // setShowUnsavedModal(false)
    if(unsaved) {
      setModalDestination(`/characters/print/${characterId}`)
      setShowUnsavedModal(true)
    } else {
      navigate(`/characters/print/${characterId}`)
    }
  }
  const handleDelete = () => {
    setShowModal(true)
  }
  const handleSwitchEditMode = () => {
    // console.log('EM:',editMode)
    editMode 
    ? setEditMode(false) 
    : setEditMode(true)  
    setCharacter(savedCharacter)
  }



  if(character && !character.removed){
    return (
      <div className={styles.Character}>
        {showModal &&
              <Modal handleClose={handleClose} handleConfirm={handleConfirm} confirmText='Usuń' cancelText='Anuluj'>
                <p>Na pewno usunąć postać {character.name && character.name}?</p>
              </Modal>
          }
          {showUnsavedModal &&
              <Modal handleClose={handleUnsavedClose} handleConfirm={handleUnsavedConfirm} cancelText='Anuluj' confirmText='Kontynuuj bez zapisania'>
                <p>Niezapisane zmiany!</p>
              </Modal>
          }
          {
            <div>
  
                <CharacterEdition 
                  character={character} 
                  handleSetCharacter={handleSetCharacter} 
                  getValue={getValue} 
                  handleSubmit={handleSubmit} 
                  savedCharacter={savedCharacter}
                  isNewCharacter={false}
                  editMode={editMode}
                  invalid={invalid}
                  shouldValidate={shouldValidate}
                  handleSetShouldValidate={handleSetShouldValidate}
                  handleSetSavedCharacter={handleSetSavedCharacter}
                  justSaved={justSaved}
                  quit={quit}
                  handleGoToPrint={handleGoToPrint}
                  handleDelete={handleDelete}
                  handleSwitchEditMode={handleSwitchEditMode}
                />   
                
  
            </div>
          }
 
      </div>
    )
  }
  if(character && character.removed) {
    return (
      <h1 className={styles.deletedInfo}>Postać usunięta
            {goBackToCharactersList()}
      </h1>
    )
  }
  if(!character) {
    return (
      <Loading />
    )
  }

  
}
// }

export default memo(CharacterSheet)
