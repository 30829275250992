import { useGetListFromDB } from '../hooks/useGetListFromDB'; 
import styles from './Listing.module.css'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../hooks/useAuthContext';
import ArticlesSublist from './ArticlesSublist';
import { useEffect, useState } from 'react';
import { db } from '../firebase/config'
import { doc, getDoc, collection } from 'firebase/firestore';
import Loading from './Loading';
import { setTopMargin } from '../misc/CommonFunctions';
import { safeSearch } from '../misc/CommonFunctions';

function ArticlesList() {

  const navigate = useNavigate()
  const category = 'articles'
  const { data } = useGetListFromDB(category,['removed','==',false])
  const { userIsAdmin } = useAuthContext()
  const [search, setSearch] = useState('')
  const [fieldFilters, setFieldFilters] = useState({})
  const [pinnedFields, setPinnedFields] = useState({})
  const [inContent, setInContent] = useState(false)
  const fields = new Set()

  data && data.forEach((d)=>{
    d.field !== '' && fields.add(d.field)
  })  


  const getPinnedFields = async (cb) => {
    const ref = doc(collection(db, 'maintenance'), 'pinned')
    await getDoc(ref)
      .then((doc)=>{
        cb(doc.data())
      })
      .catch((err)=>{
        console.log(err)
      })
  }

  useEffect(()=>{
    // fields.forEach((f)=>{
    //   fieldFilters[f] = false
    // })
    

    getPinnedFields((cb)=>{
      setPinnedFields(cb)
      cb.fields.forEach((f)=>{
        fieldFilters[f] = true
        
      })
    })
    // eslint-disable-next-line
  },[])


  const getSortedArticles = (data, field) => {
    let articles = new Set()
    data.forEach((d)=>{
      d.field === field && articles.add(d)
    })
    let sortedArticles = Array.from(articles).sort()
    return sortedArticles
  }

  const getSortedFields = (fields, pinnedFields) => {
    let allSorted =  Array.from(fields).sort()
    let final = new Set()
    allSorted.forEach((s)=>{
      pinnedFields.includes(s) && final.add(s)
    })
    allSorted.forEach((s)=>{
      if(s !== 'home') {
        final.add(s)
      }
    })
    final = Array.from(final)
    return final
  }

  const updateFieldFilter = (v, c) => {
    const newFilters = {...fieldFilters}
    newFilters[v] = c
    setFieldFilters(newFilters)
  }

  // const tools = useRef()

  useEffect(()=>{
    setTopMargin()
  })

  if(data && Object.keys(pinnedFields).length > 0)
  {
    const sortedFields = getSortedFields(fields, pinnedFields.fields)

    return (
      <>
      
      
        <nav className='tools'>
          
          <div className={styles.Filters}>
         <div className={styles.search}>
          <label>
            <span>Szukaj:</span>
            <input 
              type='text' 
              value={search} 
              onChange={(e)=>{setSearch(safeSearch(e.target.value))}} 
            />
            {search && <button onClick={()=>{setSearch('')}}>X</button>}
            </label>
            <label><input 
              type="checkbox"
              onChange={(e)=>{setInContent(e.target.checked)}}
              value={inContent}     
            />Szukaj w treści</label>
          </div>
          <div className={styles.checkboxes}>
            <span className={styles.labelTitle}>Dziedzina:</span>
            {sortedFields && sortedFields.map(d => (
              <label key={d}>
                <input 
                  type='checkbox' 
                  checked={fieldFilters[d] ? fieldFilters[d] : false}
                  onChange={(e)=>{updateFieldFilter(e.target.value, e.target.checked)}}
                  value={d}
                />
                <span>{d}</span></label>
            ))}
          </div>
        </div>
        {userIsAdmin && 
          <button onClick={()=>{navigate('/articles/newarticle')}}>Nowy Artykuł</button>
        }
        </nav>  
     
      
      <div className={styles.Listing}>
        <h2>Kompendium</h2>
        {sortedFields && sortedFields.map((d) => {
            const a = getSortedArticles(data, d)
            return(
              <ArticlesSublist 
                key={d} 
                field={d} 
                articles={a} 
                search={search} 
                inContent={inContent} 
                fieldFilters={fieldFilters} 
              />
            )
        })}
        
      </div>
      </>
    )
    
   }
   else if(!data) {
    return (
      <Loading />
    )
   }
}

export default ArticlesList
