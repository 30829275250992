import { doc, deleteDoc, updateDoc } from 'firebase/firestore';
// import { Link } from 'react-router-dom'
import { db } from '../firebase/config'
import { useEffect, useState } from 'react';
import Modal from '../components/Modal';
import LexiconsNewItem from './LexiconsNewItem.js'
import LexiconItemPreview from './LexiconItemPreview';
import LexiconItemEdition from './LexiconItemEdition';
import styles from './LexiconSublist.module.css'
import { deepSearchCheck, searchCheck } from '../misc/CommonFunctions'

const sortBy = (arr, prop) => {
    return arr.sort((a, b) => (a[prop] > b[prop]) ? 1 : -1) 
}

const deleteItem = async (id) => {
    const ref = doc(db, 'items', id)
    await deleteDoc(ref)
  }

  const setProperty = (obj, path, value) => {
    const [head, ...rest] = path.split('.')

    if(head && !obj[head] && rest.length){
      obj[head] = {}
    }
    return {
        ...obj,
        [head]: rest.length
            ? setProperty(obj[head], rest.join('.'), value)
            : value
    }
  } 

function LexiconsSublist({
    data, 
    search, 
    inContent, 
    filterPrice,
    filterSubtype,
    universes, 
    theme, 
    handleSetNewItem, 
    newItem, 
    userIsAdmin,
    fixedSubtype,
    fixedUniverse,
    handleConfirmChoice,
    inModal
}) {


    const[showModal, setShowModal] = useState(false)
    const[itemToDelete, setItemToDelete] = useState('')
    const[editing, setEditing] = useState('')
    const[items, setItems] = useState()
    
    function compare( a, b ) {
        if ( a.name < b.name ){
          return -1;
        }
        if ( a.name > b.name ){
          return 1;
        }
        return 0;
      }

    useEffect(() => {
        setItems(data.sort( compare )) //sortowanie (alfabetyczne, po imieniu)
    },[data])

    const handleClose = () => {
        setShowModal(false)
    }
    const handleConfirm = () => {
        deleteItem(itemToDelete.id)
        setShowModal(false)
    }
    const toDelete = (item) => {
        setItemToDelete(item)
        setShowModal(true)
    }

    const handleSetItem = (v, i) => {     
        let itm = items
        itm[i] = v
        // console.log('itm:',itm)
        setItems(itm)
    }


    const searchRegExp = new RegExp(search, 'i')


    const isAllowedInUniversum = (item) => {
        let r = []
        universes.forEach((u)=>{
            if(item[u]){
                r = [...r, u]
            }
        })
        return r.length > 0 ? true : false
    }

    const saveItem = async (item) => {
            const ref = doc(db, 'items', item.id)
            try {
              await updateDoc(ref, item)
            } catch(e) {
              console.log('DB update error: ', e)
            }
    }

    const handleSetEditing = (v) => {
        setEditing(v)
    }

    // console.log('EDITING: ',editing)

    return (
        <div>
        {showModal &&
            <Modal handleClose={handleClose} handleConfirm={handleConfirm} cancelText='Anuluj' confirmText='Usuń'>
                <p>Na pewno usunąć element {itemToDelete.name}?</p>
            </Modal>
        }
        {(!fixedSubtype && !fixedUniverse) && theme === 'weapon' && <h2>Uzbrojenie:</h2>}
        {(!fixedSubtype && !fixedUniverse) && theme === 'equipment' && <h2>Ekwipunek:</h2>}
        {(!fixedSubtype && !fixedUniverse) && theme === 'ability' && <h2>Zdolności:</h2>}
        {(!fixedSubtype && !fixedUniverse) && theme === 'characterType' && <h2>Typy postaci:</h2>}
        {(!fixedSubtype && !fixedUniverse) && theme === 'fabularInspiration' && <h2>Inspiracje fabularne (TYLKO DLA MG!):</h2>}
        {(!fixedSubtype && !fixedUniverse) && theme === 'belonging' && <h2>Przynależność:</h2>}
        {/* {(!fixedSubtype && !fixedUniverse) && theme === 'special' && <h2>Specjalny ekwipunek:</h2>} */}
        <ul> 
            {newItem && 
                <li className={styles.li}>
                    <LexiconsNewItem 
                        theme={theme} 
                        handleSetNewItem={handleSetNewItem}
                        setProperty={setProperty}
                    />
                </li>
            }
            {items && sortBy(items, 'name').map((d,i) => (
            ((!search || (
                inContent 
                ? (deepSearchCheck(d, searchRegExp)) 
                : searchCheck(d.name, searchRegExp)
            )
            ) 
                && isAllowedInUniversum(d)
                && (Number(d.price) === Number(filterPrice) || !filterPrice)
                && (d.subtype === filterSubtype || !filterSubtype)
                ) 
                &&               
            <li className={styles.li} key={d.id+i}>
                {editing === d.id 
                ?
                <LexiconItemEdition 
                    itm={d}
                    theme={theme}
                    setProperty={setProperty}
                    saveItem={saveItem}
                    deleteItem={deleteItem}
                    handleSetItem={handleSetItem}
                    handleSetEditing={handleSetEditing}
                    inModal={inModal}
                />
                :
                <LexiconItemPreview 
                    itm={d}
                    theme={theme}
                    setProperty={setProperty}
                    fixedSubtype={fixedSubtype}
                    fixedUniverse={fixedUniverse}
                    handleConfirmChoice={handleConfirmChoice}
                    inModal={inModal}
                />
            
                }
                {(userIsAdmin && editing !== d.id) && 
                <>
                <div>
                    <button onClick={()=>{
                        setEditing(d.id)
                    }}>
                    Edytuj
                    </button>
                </div>
                <div>
                    <button onClick={()=>{
                    toDelete(d)
                    }}>Usuń</button>
                </div>
                </>
                }
            </li>
            ))}
        </ul>
        </div>
    )
}

export default LexiconsSublist
