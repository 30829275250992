import { useState } from 'react'
import { auth } from '../firebase/config'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { useAuthContext } from '../hooks/useAuthContext'
import { db } from '../firebase/config';
import { getDoc, doc } from 'firebase/firestore';

export const useLogin = () => {

    const [error, setError] = useState(null)
    const { dispatch } = useAuthContext()

    const checkRole = (uid) => {
        getDoc(doc(db, 'users', uid))
        .then(async(doc) => {
            await dispatch({ 
                type: 'VERIFY_REMOVED', 
                payload: doc.data().removed
            })
            await dispatch({ 
                type: 'VERIFY_USER', 
                payload: doc.data().verified
            })
            await dispatch({ 
                type: 'VERIFY_ADMIN', 
                payload: doc.data().admin
            })
            await dispatch({
                type: 'USER_CHECK_PERMISSIONS',
                payload: true
            })
        })
        .catch((err) => {
            setError(err.message)
            console.log('Login ERROR: ',err.message)
            dispatch({
                type: 'USER_CHECK_PERMISSIONS',
                payload: true
            })
        })   
    }
    const login = (email, password) => {
        setError(null)
        signInWithEmailAndPassword(auth, email, password)
            .then((res) => {
                dispatch({ 
                    type: 'LOGIN', 
                    payload: res.user
                })
                checkRole(res.user.uid)
                
            })  
            .catch((err) => {
                setError(err.message)
            })   
    }

  return { error, login }
}