import { Route, Routes, Navigate } from 'react-router-dom';
import styles from './App.module.css';
import AppHead from './components/AppHead';
import Article from './pages/Article';
import Admin from './pages/Admin';
import NewArticle from './pages/NewArticle';
import Lexicons from './pages/Lexicons';
import RequestSent from './pages/RequestSent'
import Home from './pages/Home';
import Characters from './pages/Characters';
import Authentication from './pages/Authentication';
import CharacterSheet from './pages/CharacterSheet';
import PrintCharacterSheet from './pages/PrintCharacterSheet';
import NewCharacter from './pages/NewCharacter';
import ImagesGallery from './pages/ImagesGallery';
import Download from './pages/Download';
import Print from './pages/Print';
import { useAuthContext } from './hooks/useAuthContext';
import preloader from './assets/preloader.gif'
import Loading from './components/Loading';


function App() {
  const { user, authIsReady, userIsVerified, userIsAdmin, userPermissionsChecked, userIsRemoved } = useAuthContext()
  
 if(authIsReady) {

  return (
    <div className={styles.App} id='appContent'>    
        { authIsReady && (
          <>
          <img className={styles.preloadPreloader} src={preloader} alt='preloader' />
          {(user && userIsVerified && !userIsRemoved) && <AppHead />} 
          <Routes>
            <Route path='/' element={
              user && userIsVerified && !userIsRemoved ?
              <Navigate to="/articles/AgspW6iQt2qOViOSb63g" replace /> 
              :
              user && userPermissionsChecked && (!userIsVerified || userIsRemoved) ?
              <RequestSent />
              :
              <Authentication />
            }/>
            <Route path='/characters/:id' element={
              user && userIsVerified && !userIsRemoved ?
              <CharacterSheet />
              :
              user && userPermissionsChecked && (!userIsVerified || userIsRemoved) ?
              <RequestSent />
              :
              <Authentication />
            }/>
            <Route path='/characters/print/:id' element={
              user && userIsVerified && !userIsRemoved ?
              <PrintCharacterSheet />
              :
              user && userPermissionsChecked && (!userIsVerified || userIsRemoved) ?
              <RequestSent />
              :
              <Authentication />
            }/>
            <Route path='/characters' element={
              user && userIsVerified && !userIsRemoved ?
              <Characters userIsAdmin={userIsAdmin} user={user} />
              :
              user && userPermissionsChecked && (!userIsVerified || userIsRemoved) ?
              <RequestSent />
              :
              <Authentication />
            }/>
            <Route path='/characters/newcharacter' element={
              user && userIsVerified && !userIsRemoved ?
              <NewCharacter />
              :
              user && userPermissionsChecked && (!userIsVerified || userIsRemoved) ?
              <RequestSent />
              :
              <Authentication />
            }/>
            <Route path='/articles' element={
              user && userIsVerified && !userIsRemoved ?
              <Home /> 
              :
              user && userPermissionsChecked && (!userIsVerified || userIsRemoved) ?
              <RequestSent />
              :
              <Authentication />
            }/>
            <Route path='/lexicons' element={
              user && userIsVerified && !userIsRemoved ?
              <Lexicons /> 
              :
              user && userPermissionsChecked && (!userIsVerified || userIsRemoved) ?
              <RequestSent />
              :
              <Authentication />
            }/>
            <Route path='/lexicons/:paramTheme' element={
              user && userIsVerified && !userIsRemoved ?
              <Lexicons /> 
              :
              user && userPermissionsChecked && (!userIsVerified || userIsRemoved) ?
              <RequestSent />
              :
              <Authentication />
            }/>
            <Route path='/download' element={
              user && userIsVerified && !userIsRemoved ?
              <Download /> 
              :
              user && userPermissionsChecked && (!userIsVerified || userIsRemoved) ?
              <RequestSent />
              :
              <Authentication />
            }/>
            <Route path='/print' element={
              user && userIsVerified && !userIsRemoved ?
              <Print /> 
              :
              user && userPermissionsChecked && (!userIsVerified || userIsRemoved) ?
              <RequestSent />
              :
              <Authentication />
            }/>
            <Route path='/admin' element={
              user && userIsVerified && userIsAdmin ?
              <Admin />
              :
              user && userIsVerified && !userIsRemoved ?
              <Navigate to="/articles/AgspW6iQt2qOViOSb63g" replace /> 
              :
              user && userPermissionsChecked && (!userIsVerified || userIsRemoved) ?
              <RequestSent />
              :
              <Authentication />
            }/>
            <Route path='/gallery' element={
              user && userIsVerified && userIsAdmin ?
              <ImagesGallery />
              :
              user && userIsVerified && !userIsRemoved ?
              <Home />
              :
              user && userPermissionsChecked && (!userIsVerified || userIsRemoved) ?
              <RequestSent />
              :
              <Authentication />
            }/>
            <Route path='/articles/newarticle' element={
              user && userIsVerified && userIsAdmin ?
              <NewArticle />
              :
              user && userIsVerified && !userIsRemoved ?
              <Home />
              :
              user && userPermissionsChecked && (!userIsVerified || userIsRemoved) ?
              <RequestSent />
              :
              <Authentication />
            }/>
            <Route path='/articles/:id' element={
              user && userIsVerified && !userIsRemoved ?
              <Article /> 
              :
              user && userPermissionsChecked && (!userIsVerified || userIsRemoved) ?
              <RequestSent />
              :
              <Authentication />
            }/>
            <Route path='*' element={ 
              user && userIsVerified && !userIsRemoved ?
              <Navigate to="/articles/AgspW6iQt2qOViOSb63g" replace /> 
              :
              user && userPermissionsChecked && (!userIsVerified || userIsRemoved) ?
              <RequestSent />
              :
              <Authentication />
            }/>  
            <Route path='/authentication' element={
              !user && !userIsVerified ?
              <Authentication /> 
              :
              user && userPermissionsChecked && (!userIsVerified || userIsRemoved) ?
              <RequestSent />
              :
              <Navigate to="/articles/AgspW6iQt2qOViOSb63g" replace /> 
            }/>  
            <Route path='*' element={ 
              !user &&
              <Authentication /> 
            }/>  
          </Routes>
          </>
        )}
    </div>
  );
}
else {
  return ( <Loading /> )
}
}

export default App;
