import styles from './AppHead.module.css';
import logoWhite from '../assets/logo - malversum - NORMAL - WHITE.svg';
import { Link, useNavigate } from 'react-router-dom';
import { useLogout } from '../hooks/useLogout';
import { useAuthContext } from '../hooks/useAuthContext';
import { useEffect, useState } from 'react';
import Modal from './Modal';
import DiceRoll from './DiceRoll';
import DiceIcon from '../assets/dice-icon.png'
import { setTopMargin } from '../misc/CommonFunctions';
import { db } from '../firebase/config';
import { collection, onSnapshot, query, where } from 'firebase/firestore';

function AppHead() {

  const [newUsers, setNewUsers] = useState()

  let ref = collection(db, 'users')
  ref = query(ref, where('verified','==',false), where('admin','==',false), where('removed','==',false))

  useEffect(() =>{
      const unsub = onSnapshot(ref, (snapshot) => {
        let results = []
        snapshot.docs.forEach(doc => {
          results.push({ ...doc.data(), id: doc.id })
        })
        setNewUsers(results.length)
      })
      return () => unsub()
      // eslint-disable-next-line
  },[])

  const { logout } = useLogout()
  const { user, userIsVerified, userIsAdmin, unsaved, dispatch } = useAuthContext()
  const navigate = useNavigate()

  const [showModal, setShowModal] = useState(false)
  const [showDiceModal, setShowDiceModal] = useState(false)
  const [modalLink, setModalLink] = useState()

  const [hamburgerOpened, setHamburgerOpened] = useState(false)

  const handleConfirm = () => {
    dispatch({ type: 'UNSAVED', payload: false })
    setShowModal(false)
    if(typeof modalLink === 'string') {
      navigate(modalLink)
    }else {
      logout()
    }
  }

  const handleClose = () => {
    setShowModal(false)
  }

  const handleCloseDiceModal = () => {
    setShowDiceModal(false)
  }

  const onUnsaved = (e, l) => {
    e.preventDefault()
    setModalLink(l)
    setShowModal(true)
  }

  useEffect(()=>{
    setTopMargin()
  })

  // console.log('appHead: unsaved:', unsaved)
  return (
    <>
    {showModal &&
      <Modal handleClose={handleClose} handleConfirm={handleConfirm} cancelText='Anuluj' confirmText='Kontynuuj bez zapisania'>
        <p>Niezapisane zmiany!</p>
      </Modal>
    }
    {showDiceModal &&
      <Modal handleClose={handleCloseDiceModal} noConfirm={true} cancelText='Wyjdź'>
        <DiceRoll />
      </Modal>
    }

    <div className={styles.AppHead} id='appHeader'>
      <div className={styles.main}>
        <div className={styles.logoWrapper}>
          <Link to='/' onClick={(e)=>{ unsaved && onUnsaved(e, '/') }}>
            <img className={styles.logo} src={logoWhite} alt="" />
          </Link>
        </div>
        <nav className={styles.mainMenu}>
          <div className={`${styles.contentNav} ${hamburgerOpened && styles.hamburgerOpened}`}
            onClick={()=>{setHamburgerOpened(false)}}
          >
        {(user && userIsAdmin) && 
          <>
            <Link to='/admin' onClick={(e)=>{ unsaved && onUnsaved(e, '/admin') }}>Admin{Number(newUsers) > 0 && <span className={styles.newUsersAlert}>{newUsers}</span>}</Link>
            <Link to='/gallery' onClick={(e)=>{ unsaved && onUnsaved(e, '/gallery') }}>Ilustracje</Link>
          </>
        }
        {(user && userIsVerified) && 
          <>
              <Link to="/articles/AgspW6iQt2qOViOSb63g">Spis treści</Link>
              <Link to='/characters' onClick={(e)=>{ unsaved && onUnsaved(e, '/characters') }}>Postacie</Link>
              <Link to='/articles' onClick={(e)=>{ unsaved && onUnsaved(e, '/articles') }}>Kompendium</Link>
              <Link to='/lexicons' onClick={(e)=>{ unsaved && onUnsaved(e, '/lexicons') }}>Inspiracje</Link>
              <Link to='/download' onClick={(e)=>{ unsaved && onUnsaved(e, '/download') }}>Materiały</Link>
              <button onClick={(e)=>{ unsaved ? onUnsaved(e) : logout() }}>Wyloguj się</button>
          </>
        }
        </div>
        {(user && userIsVerified) && 
          <>
            <label className={styles.hamburger}>
              <input 
                checked={hamburgerOpened}
                type="checkbox"
                onChange={(e)=>{
                  setHamburgerOpened(e.target.checked)
                }}
              />
            </label>
            <button 
              className={styles.diceButton}
              onClick={(e)=>{
                e.preventDefault() 
                setShowDiceModal(true) 
            }}><img className={styles.diceIcon} src={DiceIcon} alt='Kostki' /></button>
          </>
        }
        </nav>
      </div> 
    </div>
    </>
  )
}

export default AppHead
