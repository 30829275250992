import styles from './Home.module.css'
import CharactersList from '../components/CharactersList';


function Characters({userIsAdmin}) {
  return (
    <div className={styles.HomeContent}>
        <CharactersList userIsAdmin={userIsAdmin} />
    </div>
  )
}

export default Characters
