import styles from './ArticleContent.module.css';
import { useNavigate, useLocation } from 'react-router-dom'
import { useAuthContext } from '../hooks/useAuthContext';
import { useState, useRef, useEffect } from 'react';
import { doc, updateDoc, getDoc, collection } from 'firebase/firestore';
import { db } from '../firebase/config';
import Modal from '../components/Modal';
import Select from 'react-select'
import { selectClassNames } from '../misc/SelectClassNames';
import Loading from './Loading';
import { setTopMargin, setDraftVisible } from '../misc/CommonFunctions';
import { parseWithLinks } from '../misc/CommonFunctions';

const category = 'articles'

const getArticle = async (id, cb) => {
  const ref = doc(collection(db, category), id)
  await getDoc(ref)
    .then((doc) => {
      let dd = doc.data()
      cb(dd)
    })
    .catch(error => {
      if(error){
        console.log('cannot get article: ',error)
      } 
    })
}

const deleteArticle = async (id) => {
  console.log('id: ',id,'cat: ',category,'db: ',db)
  const ref = doc(db, category, id)
  await updateDoc(ref, {
    removed: true
  })
}
const updateArticle = async (id, title, content, field) => {
  const ref = doc(db, category, id)
  await updateDoc(ref, {
    title: title,
    content: content,
    field: field
  })
}

function ArticleContent(identifier) {
  const { userIsAdmin } = useAuthContext()

  const { state : mode } = useLocation()

  const id = identifier.id
  const articleId = id
  const navigate = useNavigate()

  const[title, setTitle] = useState('')
  const[content, setContent] = useState('')
  const[field, setField] = useState('')
  const[savedTitle, setSavedTitle] = useState('')
  const[savedContent, setSavedContent] = useState('')
  const[savedField, setSavedField] = useState('')
  const[removed, setRemoved] = useState(false)
  const[data, setData] = useState('')
  const[showModal, setShowModal] = useState(false)
  const[edit, setEdit] = useState(mode ? mode : false)
  const[selected, setSelected] = useState('')

  const handleClose = () => {
    setShowModal(false)
  }
  const handleConfirm = () => {
    deleteArticle(id);
    setRemoved(true)
    setShowModal(false)
    setTimeout(()=>{
      navigate('/articles');
    },1500)
  }

  const titleRef = useRef()
  const contentRef = useRef()
  const lastSelected = useRef()

// eslint-disable-next-line
  useEffect(()=>{
    if(selected) {
      lastSelected.current = selected
      if (!edit) { setEdit(true) }
      setSelected('')

    }
    let ref = contentRef
    if(lastSelected.current) {
      const selection = lastSelected.current
      // console.log(selection)
      
      if (edit && ref.current) {
        const textarea = ref.current
        const index = textarea.value.indexOf(selection)
        // if(document.activeElement !== textarea) { textarea.focus() }
        textarea.setSelectionRange(index, index + selection.length)
        
      }
      
    }
  })



  useEffect(()=>{
      getArticle(id, (article) =>{
        setTitle(article.title)
        setContent(article.content)
        setField(article.field)
        setSavedTitle(article.title)
        setSavedContent(article.content)
        setSavedField(article.field)
        setData(article)
    })
  },[id])

  useEffect(()=>{
    if (contentRef.current) {
      // console.log(contentRef.current.style)
      contentRef.current.style.height = "0px";
      const scrollHeight = contentRef.current.scrollHeight;
      contentRef.current.style.height = scrollHeight + "px";
    }
  },[content])

  // function parseWithLinks(text) {
  //   const options = {
  //     replace: ({ name, attribs, children }) => {
  //       if (name === 'a' && attribs.href && !attribs.href.startsWith('http')) {
  //         return <Link to={attribs.href}>{domToReact(children)}</Link>;
  //       }
  //     }
  //   };
        
  //   return parse(text, options);
  // }

  const handleSubmit = (e) => {
    e.preventDefault()
    setEdit(false)
    setSavedTitle(title)
    setSavedContent(content)
    setSavedField(field)
    updateArticle(articleId, title, content, field)
  }


  const selectOptions = [
    {value: 'Mechanika', label: 'Mechanika',},
    {value: 'Świat', label: 'Świat',},
    {value: 'Scenariusze', label: 'Scenariusze',},
    {value: 'Opowiadania', label: 'Opowiadania',},
]

const handleSelect = (selected) => {
  setField(selected.value)
}


useEffect(()=>{
  setTopMargin()
  if(userIsAdmin) { setDraftVisible() }
})




const typeInTextarea = (newText, newText2, el = document.activeElement) => {
  const textArea = document.getElementById('artContent')
  const selection = textArea.value.substring(textArea.selectionStart,textArea.selectionEnd)
  const newVal = newText+selection+newText2
  const [start, end] = [el.selectionStart, el.selectionEnd];
  if(!selection.toString().includes(newText2)) {
    el.setRangeText(newVal, start, end, 'select');
  }
}

const editSelection = (e, btn) => {
  // const artHTML = e.target.closest('#articleContent').innerHTML
  const selection = getSelection().toString()
  if(selection) {
    setSelected(selection)
  }else if(btn) {
    setEdit(true)
  }
}

window.addEventListener('keydown', function (event) {
  if (event.ctrlKey && event.altKey && event.altKey && event.code === 'KeyP') {
    typeInTextarea("<p>","</p>");
  }
  if (event.ctrlKey && event.altKey && event.altKey && event.code === 'KeyT') {
    typeInTextarea("<p class='lbl'>","</p>");
  }
  if (event.ctrlKey && event.altKey && event.altKey && event.code === 'KeyB') {
    typeInTextarea("<span style='font-weight:bold;'>","</span>");
  }
  if (event.ctrlKey && event.altKey && event.altKey && event.code === 'KeyI') {
    typeInTextarea("<span style='font-style:italic;'>","</span>");
  }
  if (event.ctrlKey && event.altKey && event.altKey && event.code === 'Digit1') {
    typeInTextarea("<h1>","</h1>");
  }
  if (event.ctrlKey && event.altKey && event.altKey && event.code === 'Digit2') {
    typeInTextarea("<h2>","</h2>");
  }
  if (event.ctrlKey && event.altKey && event.altKey && event.code === 'Digit3') {
    typeInTextarea("<h3>","</h3>");
  }
  if (event.ctrlKey && event.altKey && event.altKey && event.code === 'Digit4') {
    typeInTextarea("<h4>","</h4>");
  }
  if (event.ctrlKey && event.shiftKey && event.altKey && event.code === 'KeyR') {
    typeInTextarea("<br />","<br />");
  }
  if (event.ctrlKey && event.shiftKey && event.altKey && event.code === 'KeyD') {
    typeInTextarea("<section class='columns'>","</section>");
  }
  if (event.ctrlKey && event.shiftKey && event.altKey && event.code === 'KeyF') {
    typeInTextarea("<mark class='draft'>","</mark>");
  }
  if (event.ctrlKey && event.shiftKey && event.altKey && event.code === 'KeyK') {
    typeInTextarea("<a href=''>","</a>");
  }
});



  return (
    <div className={styles.Article}>
      {(data && userIsAdmin && !removed) &&
      <nav className='tools'>
        <div className={styles.controls}>
          <div>
            <button onClick={(e)=>{
              e.preventDefault()
              if(edit) { 
                setEdit(false)
                setContent(savedContent)
                setTitle(savedTitle)
              } 
              else { 
                editSelection(e, true)
                // setEdit(true) 
              }
            }}>
              {edit ? <>Anuluj</> : <>Edytuj</>}
            </button>
          </div>
          <div>
          <button
            onClick={(e)=>{handleSubmit(e)}}
            >Zapisz{
                  (title !== savedTitle || 
                  content !== savedContent ||
                  field !== savedField
                  ) && <span>*</span>
                }</button>
          </div>
          <div>
            <button onClick={()=>{
                setShowModal(true)
              }}>
                  Usuń
              </button>
            </div>
      </div>
      </nav>
      }
        {showModal &&
          <Modal handleClose={handleClose} handleConfirm={handleConfirm} confirmText='Usuń' cancelText='Anuluj'>
            <p>Na pewno usunąć artykuł {title && title}?</p>
          </Modal>
        }
        {(data && data.removed && !userIsAdmin) && navigate('/articles')}
        {(data && (!removed || userIsAdmin) && !edit) && 
          <div>
            <h2>{title}</h2>
            <div 
              className={styles.articleContent}
              onClick={(e)=>{ userIsAdmin && editSelection(e) }}
              id='articleContent'
            >{parseWithLinks(content)}
            </div>
          </div>
        }
        {(data && userIsAdmin && edit /* && !removed*/) &&
          <div className={styles.edition}>
            <h2>{title}</h2>
            <form>
              <div>
              <label>
                <div>Tytuł:</div>
                <input 
                  type='text' 
                  onChange={(e)=>{setTitle(e.target.value)}}
                  value={title} 
                  ref={titleRef}
                />
              </label>
              <label>

              <div>Dziedzina:</div>
              <Select 
                options={selectOptions}
                onChange={handleSelect}
                value={{value: field, label: field}}
                unstyled
                classNames={selectClassNames}
             />  

              </label>
              </div>
              <div>
              <label>Treść:
                <div className={styles.growWrap}>
                <textarea 
                  id='artContent'
                  onChange={(e)=>{
                    lastSelected.current = null
                    setContent(e.target.value)
                  }}
                  autoFocus={true}
                  value={content} 
                  ref={contentRef}
                  rows='1'
                  onFocus={()=>{
                    if(content===savedContent)
                    {
                      setContent(content+' ')
                      setTimeout(()=>{
                        setContent(savedContent)
                      },20)
                    }
                  }}
                />
                </div>
                <p className='lbl'>Skróty: [ctrl] + [alt] + [shift] + <br />
                [1],[2],[3],[4]: {'<h>'} | [B]: bold | [I]: italic | [P]: {'<p>'} | [T]: {"<p class='lbl'>"} | [R]: {'2x <br />'} | [K]: link | [D]: {"<section class='Columns'>"} | [F]: {"<mark class='draft'>"}
                </p> 
              </label>
              </div>
            </form>
          </div>
        }
        {(userIsAdmin && removed) &&
          <div className={styles.deletedInfo}>
            <h1>Artykuł usunięty</h1>
          </div>
        }
        {!data && 
          <Loading />
        }
    </div>
  )
}

export default ArticleContent
