import { useParams } from 'react-router-dom'
import ArticleContent from '../components/ArticleContent';
import { useEffect, useState } from 'react';
import styles from './Home.module.css'

function Article() {

  const { id } = useParams();
  
  const[artId, setArtId] = useState(id)

  useEffect(()=>{
    if(id){
      setArtId(id)
    }
  },[id])

  return (
    <div className={styles.HomeContent}>
      <ArticleContent key={artId} id={artId} />
    </div>
  )
  
  
}

export default Article
