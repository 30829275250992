
import styles from './ValueDescription.module.css'

function ValueDescription({
    stat,
    value,
    universum
}) {
  
const description = {
    wealthLevel: {
        0: 'Nie posiadasz absolutnie żadnej mocy nabywczej.',
        1: 'wyżywienie przez kilka dni.',
        2: 'jedzenie i nocleg',
        3: (universum === 'Faerion' || universum === 'Malropa') 
            ? 'narzędzia, niemetalową odzież, drób' 
            : 'narzędzia, niemetalową odzież',
        4: (universum === 'Faerion' || universum === 'Malropa') 
            ? 'broń, konie, drogie stroje, zbroje'
            : (universum === 'Triamonda')
            ?'broń, pojazdy, drogie stroje, ekskluzywne farmaceutyki, cenne wierzytelności'
            :'broń, pojazdy, drogie stroje, wysokotechnologiczne ubiory i przedmioty osobiste',
        5: (universum === 'Faerion' || universum === 'Malropa')
            ? 'karety, drobne nieruchomości'
            : (universum === 'Triamonda') 
            ? 'drobne nieruchomości, glejty, wehikuły latające'
            : 'drobne nieruchomości, wehikuły latające',
        6: (universum === 'Faerion' || universum === 'Malropa')
            ? 'księgi, wsie, dworki'
            : (universum === 'Triamonda')
            ? 'duże nieruchomości, zastępy siły roboczej'
            : 'zastępy siły roboczej',
        7: (universum === 'Faerion' || universum === 'Malropa')
            ? 'folwarki, dwory, pałacyki'
            : 'nowoodkryte terytoria',
        8: (universum === 'Faerion' || universum === 'Malropa')
            ? 'zamki, miasta, klucze folwarków'
            : (universum === 'Triamonda')
            ? 'pomniejsze miasta'
            : 'istniejące osady, jeszcze nieodkryte terytoria',
        9: 'utrzymanie własnej armii',
        10: 'decyzje władców i papieży'
    }
    // place for other elements (objects)
}

  return (
    <p className={styles.importantText}>
    {
        description[stat][value]
    }
    </p>
  )
}

export default ValueDescription
