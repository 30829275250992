import { createPortal } from 'react-dom'
import LexiconsList from './LexiconsList'
import styles from './Modal.module.css'
import { useState } from 'react'

function CharEditModal({
    children, 
    handleClose, 
    handleConfirm,
    theme,
    subtype,
    universe
}) {

  const [closing, setClosing] = useState(false)

  const close = () => {
    setClosing(true)
    setTimeout(()=>{
      handleClose()
    },200)
  }
  
  document.addEventListener('keydown', evt => {
    if (evt.key === 'Escape') {
      close();
    }
  });

  return createPortal( (
    <div 
      className={styles.modalBcg}
      onClick={()=>{close()}}
    >
        <div 
          className={`${styles['modalBox']} ${closing && styles['close']}`}
          onClick={(e)=>{e.stopPropagation();}}  
        >
            <button 
              onClick={()=>{close()}}
              className={styles.Xcloser}
            >
                X
            </button>
            {children}
            <LexiconsList 
              theme={theme} 
              fixedSubtype={subtype && subtype} 
              fixedUniverse={universe} 
              handleConfirm={handleConfirm} 
              inModal={true}
            />
        </div>
    </div>
  ), document.body)
}

export default CharEditModal
