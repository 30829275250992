import { useState, useEffect } from "react"
import styles from './LexiconItemEdition.module.css'
import Select from 'react-select'
import { selectClassNames } from '../misc/SelectClassNames';

function LexiconItemEdition({
    itm, 
    theme, 
    handleSetNewItem, 
    handleSetItem, 
    setProperty, 
    clearAll, 
    saveItem,
    handleSetEditing,
}) {

  const [item, setItem] = useState(itm)
  const [savedItem, setSavedItem] = useState()

  useEffect(()=> {
    setSavedItem(item)
    // console.log('UE')
    // eslint-disable-next-line
  },[])

const labels = {
  weapon: 'Broń',
  ranged_weapon: 'Broń Dystansowa',
  shield: 'Tarcza',
  armor: 'Zbroja',
  camouflage: 'Kamuflaż',

  social_state: 'Stan społeczny',
  origin: 'Pochodzenie',
  folk: 'Lud',

  natural: 'Ludzkie',
  supernatural: 'Nadludzkie',
  spell: 'Czarodziejskie',

  Oppression: 'Opresja',
  scenery: 'Sceneria',
  artiTectProject: 'Projekt Arti-tekta',
  GMastering: 'Prowadzenie sesji',
  NPC: 'Postacie Niezależne',
  quest: 'Zadanie',
  faction: 'Frakcja',
  place: 'Miejsce',
  genre: 'Typ przygody',

  active: 'Aktywne',
  passive: 'Pasywne',
  salvator: 'Salvator',
  artifact: 'Zaczarowany przedmiot'
}

const equipmentOptions = [
    // {value: '', label: ''},
    {value: 'active', label: labels['active']},
    {value: 'passive', label: labels['passive']},
    {value: 'salvator', label: labels['salvator']},
    {value: 'artifact', label: labels['artifact']}
]           

const weaponOptions = [
    // {value: '', label: ''},
    {value: 'weapon', label: labels['weapon']},
    {value: 'ranged_weapon', label: labels['ranged_weapon']},
    {value: 'shield', label: labels['shield']},
    {value: 'armor', label: labels['armor']},
    {value: 'camouflage', label: labels['camouflage']}
]
const belongingOptions = [
    // {value: '', label: ''},
    {value: 'social_state', label: labels['social_state']},
    {value: 'origin', label: labels['origin']},
    {value: 'folk', label: labels['folk']}
]
const abilityOptions = [
    // {value: '', label: ''},
    {value: 'natural', label: labels['natural']},
    {value: 'supernatural', label: labels['supernatural']},
    {value: 'spell', label: labels['spell']}
]
const inspirationOptions = [
    // {value: '', label: ''},
    // {value: 'PCengager', label: labels['PCengager']},
    // {value: 'fabularGeneral', label: labels['fabularGeneral']},
    {value: 'GMastering', label: labels['GMastering']},
    {value: 'Oppression', label: labels['Oppression']},
    {value: 'scenery', label: labels['scenery']},
    {value: 'artiTectProject', label: labels['artiTectProject']},
    {value: 'NPC', label: labels['NPC']},
    {value: 'quest', label: labels['quest']},
    {value: 'faction', label: labels['faction']},
    {value: 'place', label: labels['place']},
    {value: 'genre', label: labels['genre']}
]

const handleSelect = (selected) => {
  setItem(
      setProperty(item, 'subtype', selected.value), item
  )
}

  return (
    <div className={styles.itemEdit}>
      
      <div className={styles.section}>
        <label><span>Nazwa: </span><input
          type='text'
          maxLength={20}
          value={item.name ? item.name : ''}
          onChange={(e)=>{setItem(
              setProperty(item, 'name', e.target.value), item
          )}}
        ></input></label>
        <div>

        <div className={styles.universes}>
        <span>Światy: </span>
        <label><input
          type='checkbox'
          checked={item.Faerion ? true : false}    
          onChange={(e)=>{setItem(
              setProperty(item, 'Faerion', e.target.checked), item
          )}}
        ></input>Faerion</label>
        <label><input
          type='checkbox'
          checked={item.Malropa ? true : false}    
          onChange={(e)=>{setItem(
              setProperty(item, 'Malropa', e.target.checked), item
          )}}
        ></input>Malropa</label>
        <label><input
          type='checkbox'
          checked={item.Messianica ? true : false}    
          onChange={(e)=>{setItem(
              setProperty(item, 'Messianica', e.target.checked), item
          )}}
        ></input>Messianica</label>
        <label><input
          type='checkbox'
          checked={item.Triamonda ? true : false}    
          onChange={(e)=>{setItem(
              setProperty(item, 'Triamonda', e.target.checked), item
          )}}
        ></input>Triamonda</label>
      
      </div>
    </div>
    </div> 


    <div className={styles.section}>      
      <label><span>Ilustracja (url): </span><input
        type='text'
        value={item.imageUrl ? item.imageUrl : ''}
        onChange={(e)=>{setItem(
            setProperty(item, 'imageUrl', e.target.value), item
        )}}
      ></input>
      </label>
    <div>
        {(theme !== 'characterType' && theme !== 'GMInspiration') &&
        <label><span>Koszt (pkt): </span><input
          type='number'
          value={item.price ? item.price : 0}
          onChange={(e)=>{setItem(
              setProperty(item, 'price', Number(e.target.value)), item
          )}}
        ></input>
        </label>
        }
      </div>
    </div>
      
      
      

    <div className={styles.options}>
      {theme === 'weapon' &&
      <div>
        <label className={styles.subtypeSelect}>
          
          <span>Rodzaj uzbrojenia:</span>


        <Select 
            options={weaponOptions}
            onChange={handleSelect}
            value={{value: item.subtype ? item.subtype : '', label: labels[item.subtype] ? labels[item.subtype] : ''}}
            unstyled
            classNames={selectClassNames}
        /> 

        </label>

        
        {item.subtype === 'weapon' &&
        <div className={styles.stat}>
          <label><span>Szybkość:</span>
            <input
              type='number'
              value={item.speed ? item.speed : 0}
              onChange={(e)=>{
                setItem(
                  setProperty(item, 'speed', e.target.value), item
              )}}
            />
          </label>
          <label><span>Możliwe ulepszenie max. do:</span>
            <input
              type='number'
              value={item.speed_max_upgrade ? item.speed_max_upgrade : 0}
              onChange={(e)=>{
                setItem(
                  setProperty(item, 'speed_max_upgrade', e.target.value), item
              )}}
            />
          </label>
          <label><span>Możliwe pogorszenie max. do:</span>
            <input
              type='number'
              value={item.speed_max_downgrade ? item.speed_max_downgrade : 0}
              onChange={(e)=>{
                setItem(
                  setProperty(item, 'speed_max_downgrade', e.target.value), item
              )}}
            />
          </label>
        </div>
        }
        {(item.subtype === 'weapon' || item.subtype === 'shield') &&
          <div className={styles.range}>
        <div>
          <span>Zasięg: </span>
          <span className={styles.rangeCheckboxes}>
          <input 
              type="checkbox"
              onChange={(e)=>{
                setItem(
                  setProperty(item, 'range.short', e.target.checked), item
                )
              }}
              checked={(item.range && item.range.short) ? true : false}
          />
          <input 
              type="checkbox"
              onChange={(e)=>{
                setItem(
                  setProperty(item, 'range.medium', e.target.checked), item
                )
              }}
              checked={(item.range && item.range.medium) ? true : false}
          />
          <input 
              type="checkbox"
              onChange={(e)=>{
                setItem(
                  setProperty(item, 'range.long', e.target.checked), item
                )
              }}
              checked={(item.range && item.range.long) ? true : false}
          />
          </span>
          </div>
          <div><span>Możliwe warianty: </span>
          <span className={styles.rangeCheckboxes}>
          <input 
              type="checkbox"
              onChange={(e)=>{
                setItem(
                  setProperty(item, 'range.short_variant', e.target.checked), item
                )
              }}
              checked={(item.range && item.range.short_variant) ? true : false}
          />
          <input 
              type="checkbox"
              onChange={(e)=>{
                setItem(
                  setProperty(item, 'range.medium_variant', e.target.checked), item
                )
              }}
              checked={(item.range && item.range.medium_variant) ? true : false}
          />
          <input 
              type="checkbox"
              onChange={(e)=>{
                setItem(
                  setProperty(item, 'range.long_variant', e.target.checked), item
                )
              }}
              checked={(item.range && item.range.long_variant) ? true : false}
          />
          </span>
          </div>
        </div>
        }
        {item.subtype === 'ranged_weapon' &&
        <>
        <div className={styles.stat}>
        <label><span>Celność:</span>
          <input
            type='number'
            value={item.accuracy ? item.accuracy : 0}
            onChange={(e)=>{
              setItem(
                setProperty(item, 'accuracy', e.target.value), item
            )}}
          />
        </label>
        <label><span>Możliwe ulepszenie max. do:</span>
          <input
            type='number'
            value={item.accuracy_max_upgrade ? item.accuracy_max_upgrade : 0}
            onChange={(e)=>{
              setItem(
                setProperty(item, 'accuracy_max_upgrade', e.target.value), item
            )}}
          />
        </label>
        <label><span>Możliwe pogorszenie max. do:</span>
          <input
            type='number'
            value={item.accuracy_max_downgrade ? item.accuracy_max_downgrade : 0}
            onChange={(e)=>{
              setItem(
                setProperty(item, 'accuracy_max_downgrade', e.target.value), item
            )}}
          />
        </label>
        </div>
        <div className={styles.stat}>
        <label><span>Przeładowanie:</span>
          <input
            type='text'
            value={(item.reload && item.reload) ? item.reload : ''}
            onChange={(e)=>{
              setItem(
                setProperty(item, 'reload', e.target.value), item
            )}}
          />
        </label>
        <label><span>Możliwe ulepszenie max. do:</span>
          <input
            type='text'
            value={item.reload_max_upgrade ? item.reload_max_upgrade : ''}
            onChange={(e)=>{
              setItem(
                setProperty(item, 'reload_max_upgrade', e.target.value), item
            )}}
          />
        </label>
        <label><span>Możliwe pogorszenie max. do:</span>
          <input
            type='text'
            value={item.reload_max_downgrade ? item.reload_max_downgrade : ''}
            onChange={(e)=>{
              setItem(
                setProperty(item, 'reload_max_downgrade', e.target.value), item
            )}}
          />
        </label>
        </div>
        </>
        }
        {(item.subtype === 'shield' || item.subtype === 'armor') &&
        <div className={styles.stat}>
        <label><span>Stopień opancerzenia:</span>
          <input
            type='number'
            value={item.armor_level ? item.armor_level : 0}
            onChange={(e)=>{
              setItem(
                setProperty(item, 'armor_level', e.target.value), item
            )}}
          />
        </label>
        <label><span>Możliwe ulepszenie max. do:</span>
          <input
            type='number'
            value={item.armor_level_max_upgrade ? item.armor_level_max_upgrade : 0}
            onChange={(e)=>{
              setItem(
                setProperty(item, 'armor_level_max_upgrade', e.target.value), item
            )}}
          />
        </label>
        <label><span>Możliwe pogorszenie max. do:</span>
          <input
            type='number'
            value={item.armor_level_max_udowngrade ? item.armor_level_max_udowngrade : 0}
            onChange={(e)=>{
              setItem(
                setProperty(item, 'armor_level_max_udowngrade', e.target.value), item
            )}}
          />
        </label>
        </div>

        }
        {item.subtype === 'camouflage' &&
        <div className={styles.stat}>
        <label><span>Kamuflaż:</span>
          <input
            type='number'
            value={item.camouflage ? item.camouflage : 0}
            onChange={(e)=>{
              setItem(
                setProperty(item, 'camouflage', e.target.value), item
            )}}
          />
        </label>
        <label><span>Możliwe ulepszenie max. do:</span>
          <input
            type='number'
            value={item.camouflage_max_upgrade ? item.camouflage_max_upgrade : 0}
            onChange={(e)=>{
              setItem(
                setProperty(item, 'camouflage_max_upgrade', e.target.value), item
            )}}
          />
        </label>
        <label><span>Możliwe pogorszenie max. do:</span>
          <input
            type='number'
            value={item.camouflage_max_downgrade ? item.camouflage_max_downgrade : 0}
            onChange={(e)=>{
              setItem(
                setProperty(item, 'camouflage_max_downgrade', e.target.value), item
            )}}
          />
        </label>
        </div>
        }
      </div>
      }
      {theme === 'belonging' &&
      <div className={styles.subtypeSelect}>
        <label><span>Przynależność:</span>

        <Select 
            options={belongingOptions}
            onChange={handleSelect}
            value={{value: item.subtype ? item.subtype : '', label: labels[item.subtype] ? labels[item.subtype] : ''}}
            unstyled
            classNames={selectClassNames}
        /> 

        </label>
      </div>      
      }
      {theme === 'ability' &&
      <div className={styles.subtypeSelect}>
        <label><span>Rodzaj zdolności:</span>

        <Select 
            options={abilityOptions}
            onChange={handleSelect}
            value={{value: item.subtype ? item.subtype : '', label: labels[item.subtype] ? labels[item.subtype] : ''}}
            unstyled
            classNames={selectClassNames}
        /> 

         
        </label>
      </div>      
      }
      {theme === 'GMInspiration' &&
      <div className={styles.subtypeSelect}>
        <label><span>Rodzaj inspiracji:</span>

          <Select 
              options={inspirationOptions}
              onChange={handleSelect}
              value={{value: item.subtype ? item.subtype : '', label: labels[item.subtype] ? labels[item.subtype] : ''}}
              unstyled
              classNames={selectClassNames}
          /> 

        
        </label>
      </div>      
      }
      {theme === 'equipment' &&
      <>
      <div className={styles.subtypeSelect}>
        <label><span>Rodzaj ekwipunku:</span>

        <Select 
              options={equipmentOptions}
              onChange={handleSelect}
              value={{value: item.subtype ? item.subtype : '', label: labels[item.subtype] ? labels[item.subtype] : ''}}
              unstyled
              classNames={selectClassNames}
          /> 

         
        </label>
      </div>
      
      <div className={styles.section}>
        <label><span>Kompetencja / Zdolność:</span>
          <input 
            onChange={(e)=>{
              setItem(
                setProperty(item, 'modifiedCompetence', e.target.value), item
            )}}
            type='text'
            maxLength={20}
            value={item.modifiedCompetence ? item.modifiedCompetence : ''}
          />
        </label>
        <label><span>Premia / kara:</span>
          <input 
            onChange={(e)=>{
              setItem(
                setProperty(item, 'modifier', e.target.value), item
            )}}
            type='number'
            value={item.modifier ? item.modifier : ''}
          />
        </label>
      </div>     
      </> 
      }

      </div>  


      <div className={styles.description}>
      <label><span>Opis:</span>
      {/* {console.log(item.subtype)} */}
          <textarea
            maxLength={
              (item.subtype === 'weapon'
              || item.subtype === 'ranged_weapon'
              || item.subtype === 'shield'
              || item.subtype === 'camouflage')
              ? 280
              : (item.subtype === 'armor'
              || item.subtype === 'folk'
              || item.subtype === 'social_state'
              || item.subtype === 'origin'
              || item.subtype === 'GMastering'
              || item.subtype === 'Oppression'
              || item.subtype === 'scenery'
              || item.subtype === 'genre'
              || item.subtype === 'place'
              || item.subtype === 'NPC'
              || item.subtype === 'faction'
              || item.subtype === 'quest'
              || item.subtype === 'artiTectProject'
              || item.subtype === 'salvator'
              || item.subtype === 'artifact')
              ? 330
              : (item.subtype === 'spell'
              || item.subtype === 'supernatural'
              || item.subtype === 'natural')
              ? 500
              : 100
            }
            value={item.description ? item.description : ''}
            onChange={(e)=>{
              setItem(
                setProperty(item, 'description', e.target.value), item
            )}}
          />
        </label>
      </div>

              

        <div className={styles.buttons}>
            <div>
            <button
            onClick={(e)=>{
                e.preventDefault()
                handleSetNewItem && handleSetNewItem(false)
                handleSetEditing && handleSetEditing('')
                saveItem(item)
            }}
          >Zapisz</button>
          </div>
          <div>
          <button
            onClick={(e)=>{
                e.preventDefault()
                handleSetNewItem && handleSetNewItem(false)
                clearAll && clearAll()
                handleSetEditing && handleSetItem(savedItem, item) //sprawdzam czy to edycja czy nowy item
                handleSetEditing && handleSetEditing('')
            }}
          >Anuluj</button>
          </div>
        </div>

    </div>
  )
}

export default LexiconItemEdition
