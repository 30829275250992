import { useState, useEffect, useRef } from "react"
import styles from './InputProgress.module.css'

function InputProgress({
  path, 
  editMode, 
  character, 
  newAdded, 
  hasAnyFilledChildren, 
  savedCharacter, 
  getValue, 
  setValue, 
  justSaved,
  hint,
  parseLinks,
  maxLength,
  handleSubmit,
  unsaved
}) {

const [edit, setEdit] = useState(editMode)
const[charsLeft, setCharsLeft] = useState(maxLength)

const matterPath = `${path}.name`
const progressPath = `${path}.progress`
const removePath = `${path}.removed`

const prevEditMode = useRef(false)
const[showHint, setShowHint] = useState(false)
const prevJustSaved = useRef(justSaved)
const toFocus = useRef(null)
const anyFilledChildren = getValue(character, path) 
? hasAnyFilledChildren(character[path]) 
: false

const cancel = (e) => {
    // console.log('cancel')
    e && e.preventDefault()
    setValue([
              {
                value: getValue(savedCharacter, matterPath), 
                path: matterPath
              },
              {
                value: getValue(savedCharacter, progressPath), 
                path: progressPath
              }
      ])
    setEdit(false)

}

const remove = (e, hard) => {
    // console.log('remove')
    e && e.preventDefault()
    setValue(
        [
          {
              value: '',
              path: matterPath
          },
          {
              value: '',
              path: progressPath
          },
          {
            value: true,
            path: removePath
        }
        ],
        false, hard ? true : false
    )
    setEdit(false) 
}

useEffect(() => {
    if(toFocus.current && !editMode) {
        toFocus.current.focus()
    }
    // eslint-disable-next-line
},[edit])

useEffect(() => {

    if(prevJustSaved.current !== justSaved) {
        cancel()    
    }
    else {
        if(!anyFilledChildren && !edit && newAdded) {
            // console.log(path,': FORCED EDIT')
            setEdit(true)
        }
    }

    if(prevEditMode.current !== editMode)
    {
        prevEditMode.current = editMode
        setEdit(editMode)    
        if (prevEditMode.current === false && !anyFilledChildren) {
            cancel()
        }
    }

// eslint-disable-next-line
  },[justSaved, editMode])


if(edit){

  return (
    <>
    <div className={styles.ProgressEdit}>
    <div>
    <div><div className='lbl'>Co:</div></div>
    <label>
        <input 
            type="text" 
            maxLength={maxLength}
            onChange={(e)=>{
                setValue([{value: e.target.value, path: matterPath}])
                setCharsLeft(Number(maxLength) - e.target.value.length)
            }} 
            value={getValue(character, matterPath)} 
            onFocus={()=>{
                setCharsLeft(Number(maxLength) - getValue(character,matterPath).length)
                setTimeout(()=>{
                setShowHint(true)
                },250)
                }} 
            onBlur={()=>{
                setTimeout(()=>{
                setShowHint(false)
            },200) 
            }}
            ref={toFocus}
        />
        <span>{charsLeft}</span>
    </label>
    </div>
    <div>
    <div><div className='lbl'>Postępy:</div></div>
    <div className={styles.slider}>
        <input 
            type="range" 
            max='20'
            onChange={(e)=>{setValue([{value: Number(e.target.value), path: progressPath}])}} 
            value={getValue(character, progressPath) 
            ? getValue(character, progressPath) 
            : 0}
            onFocus={()=>{ setShowHint(true) }}
            onBlur={()=>{
                setTimeout(()=>{
                  setShowHint(false)
                },200)
              }}
        />
        <span>
        {getValue(character, progressPath) 
        ? getValue(character, progressPath) 
        : 0}/20
        </span>
    </div>
    </div>

    <div>
    {(edit && unsaved) &&
      <div>
          <button onClick={(e)=> {
              // e.preventDefault()
              handleSubmit(e)
          }}>Zapisz</button>
      </div>
      }
    {(edit && !editMode) &&
    <div>
            <button onClick={(e)=>{
                (anyFilledChildren && Object.keys(getValue(savedCharacter, path)).length > 0) 
                ? cancel(e) 
                : remove(e, true)
            }}
            >Anuluj</button>
        </div>
        }
        {edit &&
        <div>
            <button onClick={(e)=> {
                (anyFilledChildren && Object.keys(getValue(savedCharacter, path)).length > 0) 
                ? remove(e)
                : remove(e, true)
            }}>Usuń</button>
        </div>
        }
        </div>
    </div>
    {(hint && parseLinks && showHint) &&
        <div className='hint'>
            <p>{parseLinks(hint)}</p>
        </div>
    }
    </>
  )
}
else if(anyFilledChildren) {
    return (
        <div 
            className={styles.Progress}
            onClick={(e) => {
                e.preventDefault()
                setEdit(true)
              }}
            >
            <div>{getValue(character, matterPath)}</div> 
            <div>
                <div><div className='lbl'>Postępy:</div></div>
                {/* <progress value={getValue(character, progressPath)} max={20}></progress> */}
                <div className={styles.progressBar}>
                    <div style={{width: `${Number(getValue(character, progressPath))*5}%`}}></div>
                </div>
                <div>{getValue(character, progressPath)} / 20</div>
            </div>
            <div>
                <button 
                    onClick={(e)=> {
                        e.preventDefault()
                        setEdit(true)
                    }}>edytuj
                </button>
            </div>
        </div>
    )
}
}

export default InputProgress
