import { useState } from 'react'
import { auth } from '../firebase/config'
import { createUserWithEmailAndPassword } from 'firebase/auth'
import { useAuthContext } from '../hooks/useAuthContext'
import { setDoc, doc } from 'firebase/firestore';
import { db } from '../firebase/config';
import emailjs from '@emailjs/browser'

const sendEmail = (email) => {

  const messageData = {
    to_email: 'malversum.rpg@gmail.com',
    from_email: 'malversum.rpg@gmail.com',
    subject: 'Nowy użytkownik!',
    message: `Założono konto na malversum.com przy użyciu adresu ${email}. Czeka na weryfikację.`,
  };

  emailjs.send('service_8l9ufua', 'template_d995yy1', messageData, 'ayQwt9MvMF_5td0xT')
    .then((result) => {
        console.log(result.text);
    }, (error) => {
        console.log(error.text);
    });
};

export const useSignup = () => {
  
    const [error, setError] = useState(null)
    const { dispatch } = useAuthContext()


    const signup = (email, password) => {
        setError(null)
        createUserWithEmailAndPassword(auth, email, password)
            .then(async (res) => {
                await dispatch({ type: 'LOGIN', payload: res.user })
               // console.log('logging: ', res.user.email)
                try {
                    await setDoc(doc(db,'users',res.user.uid), {
                        email: email,
                        verified: false,
                        admin: false,
                        removed: false
                    })
                    await dispatch({
                        type: 'USER_CHECK_PERMISSIONS',
                        payload: true
                    })
                    sendEmail(res.user.email)
                   // console.log('New User ID: ', res.user.uid)
                }catch(e) {
                    console.error('Signup ERROR: ',e)
                }
                
            })
            .catch((err) => {
                setError(err.message)
            })

    }
  
  return { error, signup }
}

