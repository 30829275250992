import { useState, useEffect, useRef } from "react"
import CharEditModal from '../CharEditModal'
import styles from './InputUtensilium.module.css'

function InputUtensiluim({
    type, 
    path, 
    stats, 
    newAdded, 
    character, 
    hasAnyFilledChildren, 
    savedCharacter, 
    editMode, 
    getValue, 
    setValue, 
    justSaved,
    hint,
    parseLinks,
    maxLength,
    maxLengthTA,
    handleSubmit,
    unsaved
}) {

    // console.log('PATH: ',path)

const {speed, armor_level, camouflage, range, reload, accuracy} = stats
const [edit, setEdit] = useState(editMode)
const [showHint, setShowHint] = useState(false)
const[charsLeft, setCharsLeft] = useState(maxLength)
const[charsLeftTA, setCharsLeftTA] = useState(maxLengthTA)

const namePath = `${path}.name`
const speedPath = `${path}.speed`
const armor_levelPath = `${path}.armor_level`
const camouflagePath = `${path}.camouflage`
const rangeShortPath = `${path}.range.short`
const rangeMediumPath = `${path}.range.medium`
const rangeLongPath = `${path}.range.long`
const reloadPath = `${path}.reload`
const accuracyPath = `${path}.accuracy`
const descriptionPath = `${path}.description`
const removePath = `${path}.removed`
const pointsPath = `points`
const pricePath = `${path}.price`

const getSubtype = () => {
    let t = ''
    if(speed && range) { t = 'weapon'}
    if(camouflage) { t = 'camouflage' }
    if(armor_level && range) { t = 'shield' }
    if(armor_level && !range) { t = 'armor' }
    if(reload && accuracy) { t = 'ranged_weapon' }
    return t
}

const n = (n) => {
    return Number(n ? n : 0)
  }

const prevEditMode = useRef(false)
const prevJustSaved = useRef(justSaved)
const toFocus = useRef(null)
const anyFilledChildren = getValue(character, path) 
? hasAnyFilledChildren(character[path]) 
: false

const[showCharEditModal, setShowCharEditModal] = useState(false)

const handleConfirm = (item, mods) => {
    let newItemPrice = (Number(item.price) + n(mods.price)) > 0 ? (Number(item.price) + n(mods.price)) : 0 
    setShowCharEditModal(false) 
    setEdit(false)
    let values = 
    [
        { value: item.name, path: namePath },
        { value: item.description, path: descriptionPath },
        { value: (Number(getValue(character,'points')) + newItemPrice), path: 'points'},
        {
            value: Number(getValue(character,'points'))
            - Number(getValue(character, pricePath))                   //price of old item
            + newItemPrice,                                            //price of new item
            path: pointsPath
        },
        {
            value: newItemPrice,
            path: pricePath
        }
    ]
    if(getSubtype() === 'weapon' || getSubtype() === 'shield') {
       values = [...values, 
            { value: (mods.range && mods.range.short) ? mods.range.short : item.range.short ? item.range.short : false, path: rangeShortPath },
            { value: (mods.range && mods.range.medium) ? mods.range.medium : item.range.medium ? item.range.medium : false, path: rangeMediumPath },
            { value: (mods.range && mods.range.long) ? mods.range.long : item.range.long ? item.range.long : false, path: rangeLongPath }
        ]            
    }
    if(getSubtype() === 'weapon') {
        values = [...values, { value: Number(item.speed) + n(mods.speed), path: speedPath }]  
    }
    if(getSubtype() === 'camouflage') {
        values = [...values, {value: Number(item.camouflage) + n(mods.camouflage), path: camouflagePath }]  
    }
    if(getSubtype() === 'armor' || getSubtype() === 'shield') {
        values = [...values, { value: Number(item.armor_level) + n(mods.armor_level), path: armor_levelPath }]
    }
    if(getSubtype() === 'ranged_weapon') {
        values = [...values, 
            { value: Number(item.reload) + n(mods.reload), path: reloadPath },
            { value: Number(item.accuracy) + n(mods.accuracy), path: accuracyPath }
        ]
           
    }
    setValue(values)
  }
  const handleClose = () => {
    setShowCharEditModal(false)
  }

const cancel = (e) => {
    // console.log('cancel')
    e && e.preventDefault()
    setValue([
        {
            value: getValue(savedCharacter, namePath),
            path: namePath
        },
        {
            value: getValue(savedCharacter, speedPath),
            path: speedPath
        },
        {
            value: getValue(savedCharacter, armor_levelPath),
            path: armor_levelPath
        },
        {
            value: getValue(savedCharacter, camouflagePath),
            path: camouflagePath
        },
        {
            value: getValue(savedCharacter, rangeShortPath),
            path: rangeShortPath
        },
        {
            value: getValue(savedCharacter, rangeMediumPath),
            path: rangeMediumPath
        },
        {
            value: getValue(savedCharacter, rangeLongPath),
            path: rangeLongPath
        },
        {
            value: getValue(savedCharacter, reloadPath),
            path: reloadPath
        },
        {
            value: getValue(savedCharacter, accuracyPath),
            path: accuracyPath
        },
        {
            value: getValue(savedCharacter, descriptionPath),
            path: descriptionPath
        },
        {
            value: getValue(savedCharacter, pricePath),
            path: pricePath
        },
        {
            value: Number(getValue(character,'points'))
            - Number(getValue(character, pricePath))                   
            + Number(getValue(savedCharacter, pricePath)),                                            
            path: pointsPath
        }
    ])
    setEdit(false)

}

const remove = (e, hard) => {
    // console.log('remove')
    e && e.preventDefault()
    setValue(
        [{
            value: '',
            path: namePath
        },
        {
            value: '',
            path: speedPath
        },
        {
            value: '',
            path: armor_levelPath
        },
        {
            value: '',
            path: camouflagePath
        },
        {
            value: '',
            path: rangeShortPath
        },
        {
            value: '',
            path: rangeMediumPath
        },
        {
            value: '',
            path: rangeLongPath
        },
        {
            value: '',
            path: reloadPath
        },
        {
            value: '',
            path: accuracyPath
        },
        {
            value: '',
            path: descriptionPath
        },
        {
            value: true,
            path: removePath
        },
        {
            value: 0,
            path: pricePath
        },
        {
            value: Number(getValue(character,'points')) - Number(getValue(character, pricePath)),
            path: pointsPath
        }
        ],
        false, hard ? true : false
    )
    setEdit(false) 
}

useEffect(() => {
    if(toFocus.current && !editMode) {
        toFocus.current.focus()
    }
    // eslint-disable-next-line
},[edit])


useEffect(() => {

    if(prevJustSaved.current !== justSaved) {
        cancel()    
    }
    else {
        if(!anyFilledChildren && !edit && newAdded) {
            // console.log(path,': FORCED EDIT')
            setEdit(true)
        }
    }

    if(prevEditMode.current !== editMode)
    {
        prevEditMode.current = editMode
        setEdit(editMode)    
        if (prevEditMode.current === false && !anyFilledChildren) {
            cancel()
        }
    }
    setCharsLeftTA(Number(charsLeftTA) - Number(getValue(character,descriptionPath).length))

// eslint-disable-next-line
  },[justSaved, editMode])



//   console.log(getValue(character, pricePath))
// console.log('character:', character)

if(edit)
{
  return (
    <>
    <div 
        tabIndex='0'
        className={styles.UtensiliumEdit}
    >
    <>    
        {showCharEditModal &&
              <CharEditModal 
                handleClose={handleClose} 
                handleConfirm={handleConfirm} 
                confirmText='Wybierz' 
                cancelText='Anuluj'
                theme='weapon'
                subtype={getSubtype()}
                universe={getValue(character,'universum')}
              >
                <p></p>
              </CharEditModal>
              }
        <h4>{type}</h4>
        <div>
            <div className='lbl'>Nazwa:</div> 
              <label>
                <input 
                    type="text"
                    onChange={(e)=>{
                        setValue([{value: e.target.value, path: namePath}])
                        setCharsLeft(Number(maxLength) - e.target.value.length)
                    }}
                    value={getValue(character, namePath)}
                    maxLength={maxLength}
                    ref={toFocus} 
                    onFocus={()=>{
                        setCharsLeft(Number(maxLength) - getValue(character,namePath).length)
                        setTimeout(()=>{
                        setShowHint(true)
                        },250)
                        }} 
                    onBlur={()=>{
                        setTimeout(()=>{
                        setShowHint(false)
                    },200) 
                    }}
                />
                <span>{charsLeft}</span>
            </label>
        </div>
        {range &&
        <>
            <div className='lbl'>Zasięg:</div>
                <div className={styles.range}>
                    <div></div>
                    <div>
                        
                        <div>
                        {getValue(character,rangeShortPath) 
                                ? <span className={styles.rangeSlot}><span className={styles.rangeFill}></span></span> 
                                : <span className={styles.rangeSlot}></span>}
                        <input 
                            type="checkbox"
                            onChange={(e)=>{setValue([{value: e.target.checked, path: rangeShortPath}])}}
                            checked={getValue(character, rangeShortPath)}
                            onFocus={()=>{
                                setTimeout(()=>{
                                setShowHint(true)
                                },250)
                                }} 
                            onBlur={()=>{
                                setTimeout(()=>{
                                setShowHint(false)
                            },200) 
                            }}
                        />
                        </div>
                        <div>
                        {getValue(character,rangeMediumPath)
                                ? <span className={styles.rangeSlot}><span className={styles.rangeFill}></span></span> 
                                : <span className={styles.rangeSlot}></span>}
                        <input 
                            type="checkbox"
                            onChange={(e)=>{setValue([{value: e.target.checked, path: rangeMediumPath}])}}
                            checked={getValue(character, rangeMediumPath)}
                            onFocus={()=>{
                                setTimeout(()=>{
                                setShowHint(true)
                                },250)
                                }} 
                            onBlur={()=>{
                                setTimeout(()=>{
                                setShowHint(false)
                            },200) 
                            }}
                        />
                        </div>
                        <div>
                        {getValue(character,rangeLongPath) 
                                ? <span className={styles.rangeSlot}><span className={styles.rangeFill}></span></span> 
                                : <span className={styles.rangeSlot}></span>}
                        <input 
                            type="checkbox"
                            onChange={(e)=>{setValue([{value: e.target.checked, path: rangeLongPath}])}}
                            checked={getValue(character, rangeLongPath)}
                            onFocus={()=>{
                                setTimeout(()=>{
                                setShowHint(true)
                                },250)
                                }} 
                            onBlur={()=>{
                                setTimeout(()=>{
                                setShowHint(false)
                            },200) 
                            }}
                        />
                        </div>
                    </div>
            </div>
        </>
        }
        {speed &&
            <div>
                <div className='lbl'>Szybkość:</div>
                <div> 
                    <input 
                        disabled={true}
                        type="number"
                        onChange={(e)=>{setValue([{value: Number(e.target.value), path: speedPath}])}}
                        value={getValue(character, speedPath)} 
                        onFocus={()=>{
                            setTimeout(()=>{
                            setShowHint(true)
                            },250)
                            }} 
                        onBlur={()=>{
                            setTimeout(()=>{
                            setShowHint(false)
                        },200) 
                        }}
                    />
                    <button
                        className={styles.pointsButton}
                        onClick={(e)=>{
                            e.preventDefault()
                            setValue([
                            {
                                value: Number(getValue(character, speedPath) )+1,
                                path: speedPath
                            }
                            ])
                        }}
                    >+</button>
                    <button
                        className={styles.pointsButton}
                        onClick={(e)=>{
                            e.preventDefault()
                                setValue([
                                {
                                    value: Number(getValue(character, speedPath) -1),
                                    path: speedPath
                                }
                                ])
                        }}
                    >-</button>
                </div>
            </div>
        }
        {armor_level &&
            <div>
                <div className='lbl'>Stopień opancerzenia: </div>
                <div>
                    <input 
                        type="number"
                        disabled={true}
                        onChange={(e)=>{setValue([{value: Number(e.target.value), path: armor_levelPath}])}}
                        value={getValue(character, armor_levelPath)} 
                        onFocus={()=>{
                            setTimeout(()=>{
                            setShowHint(true)
                            },250)
                            }} 
                        onBlur={()=>{
                            setTimeout(()=>{
                            setShowHint(false)
                        },200) 
                        }}
                    />
                    <button
                        className={styles.pointsButton}
                        onClick={(e)=>{
                            e.preventDefault()
                            setValue([
                            {
                                value: Number(getValue(character, armor_levelPath) )+1,
                                path: armor_levelPath
                            }
                            ])
                        }}
                    >+</button>
                    {Number(getValue(character, armor_levelPath)) > 0 &&
                    <button
                        className={styles.pointsButton}
                        onClick={(e)=>{
                            e.preventDefault()
                                setValue([
                                {
                                    value: Number(getValue(character, armor_levelPath) -1),
                                    path: armor_levelPath
                                }
                                ])
                        }}
                    >-</button>
                    }
                </div>
            </div>
        }
        {camouflage &&
            <div>
                <div className='lbl'>Kamuflaż:</div>
                <div> 
                    <input 
                        type="number"
                        disabled={true}
                        onChange={(e)=>{setValue([{value: Number(e.target.value), path: camouflagePath}])}}
                        value={getValue(character, camouflagePath)} 
                        onFocus={()=>{
                            setTimeout(()=>{
                            setShowHint(true)
                            },250)
                            }} 
                        onBlur={()=>{
                            setTimeout(()=>{
                            setShowHint(false)
                        },200) 
                        }}
                    />
                    <button
                        className={styles.pointsButton}
                        onClick={(e)=>{
                            e.preventDefault()
                            setValue([
                            {
                                value: Number(getValue(character, camouflagePath) )+1,
                                path: camouflagePath
                            }
                            ])
                        }}
                    >+</button>
                    <button
                        className={styles.pointsButton}
                        onClick={(e)=>{
                            e.preventDefault()
                                setValue([
                                {
                                    value: Number(getValue(character, camouflagePath) -1),
                                    path: camouflagePath
                                }
                                ])
                        }}
                    >-</button>
                </div>
            </div>
        }
        {accuracy &&
            <div>
                <div className='lbl'>Celność: </div>
                <div>
                    <input 
                        type="number"
                        disabled={true}
                        onChange={(e)=>{setValue([{value: Number(e.target.value), path: accuracyPath}])}}
                        value={getValue(character, accuracyPath)} 
                        onFocus={()=>{
                            setTimeout(()=>{
                            setShowHint(true)
                            },250)
                            }} 
                        onBlur={()=>{
                            setTimeout(()=>{
                            setShowHint(false)
                        },200) 
                        }}
                    />
                    <button
                        className={styles.pointsButton}
                        onClick={(e)=>{
                            e.preventDefault()
                            setValue([
                            {
                                value: Number(getValue(character, accuracyPath) )+1,
                                path: accuracyPath
                            }
                            ])
                        }}
                    >+</button>
                    <button
                        className={styles.pointsButton}
                        onClick={(e)=>{
                            e.preventDefault()
                                setValue([
                                {
                                    value: Number(getValue(character, accuracyPath) -1),
                                    path: accuracyPath
                                }
                                ])
                        }}
                    >-</button>
                </div>
            </div>
        }
        {reload &&
            <div>
                <div className='lbl'>Przeładowanie: </div>
                <div>
                    <input 
                        type="number"
                        disabled={true}
                        onChange={(e)=>{setValue([{value: Number(e.target.value), path: reloadPath}])}}
                        value={getValue(character, reloadPath)} 
                        onFocus={()=>{
                            setTimeout(()=>{
                            setShowHint(true)
                            },250)
                            }} 
                        onBlur={()=>{
                            setTimeout(()=>{
                            setShowHint(false)
                        },200) 
                        }}
                    />
                    <button
                        className={styles.pointsButton}
                        onClick={(e)=>{
                            e.preventDefault()
                            setValue([
                            {
                                value: Number(getValue(character, reloadPath) )+1,
                                path: reloadPath
                            }
                            ])
                        }}
                    >+</button>
                    {Number(getValue(character, reloadPath)) > 0 &&
                    <button
                        className={styles.pointsButton}
                        onClick={(e)=>{
                            e.preventDefault()
                                setValue([
                                {
                                    value: Number(getValue(character, reloadPath) -1),
                                    path: reloadPath
                                }
                                ])
                        }}
                    >-</button>
                    }
                </div>
            </div>
        }
        <div>
            <div className='lbl'>Opis:</div>
            <label> 
                <textarea 
                    onChange={(e)=>{
                        setValue([{value: e.target.value, path: descriptionPath}])
                        setCharsLeftTA(Number(maxLengthTA) - e.target.value.length)
                    }}
                    value={getValue(character, descriptionPath)} 
                    maxLength={maxLengthTA}
                    onFocus={()=>{
                        setTimeout(()=>{
                        setShowHint(true)
                        },250)
                        }} 
                    onBlur={()=>{
                        setTimeout(()=>{
                        setShowHint(false)
                    },200) 
                    }}
                />
                <span>{charsLeftTA}</span>
            </label>
        </div>
        <div>
            <div className='lbl'>Koszt: </div>
            <div>
                {/* {console.log(character)} */}
                    <input 
                        type="number"
                        disabled={true}
                        value={getValue(character, pricePath)} 
                    />
                    <button
                        className={styles.pointsButton}
                        onClick={(e)=>{
                            e.preventDefault()
                            setValue([{
                                value: Number(getValue(character,'points') )+1, 
                                path: pointsPath
                            },
                            {
                                value: Number(getValue(character, pricePath) )+1,
                                path: pricePath
                            }
                            ])
                        }}
                    >+</button>
                    {Number(getValue(character, pricePath)) > 0 &&
                    <button
                        className={styles.pointsButton}
                        onClick={(e)=>{
                            e.preventDefault()
                                setValue([{
                                    value: Number(getValue(character,'points') -1), 
                                    path: pointsPath
                                },
                                {
                                    value: Number(getValue(character, pricePath) -1),
                                    path: pricePath
                                }
                                ])
                        }}
                    >-</button>
                    }
            </div>
        </div>
        <div>
            <div>
            {(edit && unsaved) &&
            <div>
                <button onClick={(e)=> {
                    // e.preventDefault()
                    handleSubmit(e)
                }}>Zapisz</button>
            </div>
            }
        {(edit && !editMode) &&
        <div>
            <button onClick={(e)=>{
                (anyFilledChildren && Object.keys(getValue(savedCharacter, path)).length > 0) 
                ? cancel(e) 
                : remove(e, true)
            }}
            >Anuluj</button>
        </div>
        }
        {edit &&
        <div>
            <button onClick={(e)=> {
                (anyFilledChildren && Object.keys(getValue(savedCharacter, path)).length > 0) 
                ? remove(e)
                : remove(e, true)
            }}>Usuń</button>
        </div>
        }
        {edit &&
        <div>
            <button onClick={(e)=> {
                e.preventDefault()
                setShowCharEditModal(true)
            }}>Inspiracje</button>
        </div>
        }
            </div>
        </div>
        
       </> 
    </div>

    {(hint && parseLinks && showHint) &&
    <div className='hint'>
      <p>{parseLinks(hint)}</p>
    </div>
    }
    
    </>)
}
else if(anyFilledChildren) {
    return (
        <div 
            className={styles.Utensilium}
            onClick={(e) => {
                e.preventDefault()
                setEdit(true)
              }}
        >
                  <h4>{type}</h4>       
                  <h4>{getValue(character,namePath)}</h4> 
                  {range && 
                  <div className={styles.range}>
                    <div className='lbl'>Zasięg:</div> {
                    <div>
                    {getValue(character,rangeShortPath) 
                        ? <span className={styles.rangeSlot}><span className={styles.rangeFill}></span></span> 
                        : <span className={styles.rangeSlot}></span>}
                    {getValue(character,rangeMediumPath)
                        ? <span className={styles.rangeSlot}><span className={styles.rangeFill}></span></span> 
                        : <span className={styles.rangeSlot}></span>}
                    {getValue(character,rangeLongPath) 
                        ? <span className={styles.rangeSlot}><span className={styles.rangeFill}></span></span> 
                        : <span className={styles.rangeSlot}></span>}
                    </div>
                  }</div>}
                  {speed && 
                  <div>
                    <div className='lbl'>Szybkość:</div> 
                    <div className={styles.price}>{getValue(character,speedPath)}</div>
                   </div>}
                  {camouflage && 
                  <div>
                    <div className='lbl'>Kamuflaż:</div> 
                    <div className={styles.price}>{getValue(character,camouflagePath)}</div>
                   </div>}
                  {armor_level && 
                  <div>
                    <div className='lbl'>Stopień opancerzenia:</div> 
                    <div className={styles.price}>{getValue(character,armor_levelPath)}</div>
                   </div>}
                  {accuracy && 
                  <div>
                    <div className='lbl'>Celność:</div> 
                    <div className={styles.price}>{getValue(character,accuracyPath)}</div>
                   </div>}
                  {reload && 
                  <div>
                    <div className='lbl'>Przeładowanie:</div> 
                    <div className={styles.price}>{getValue(character,reloadPath)}</div>
                   </div>}
                  <div>
                  <div className='lbl'>Cechy:</div> 
                    <div>{getValue(character,descriptionPath)}</div></div>
                  <div>
                    <div></div>
                    <div>
                        {Number(getValue(character, pricePath)) !== 0 &&
                        <>
                            <div className='lbl'>Koszt:</div> 
                            <div className={styles.price}>{Number(getValue(character, pricePath))}</div>
                        </>}
                    </div>
                   </div> 
                  <div>
                    <div>
                        <button onClick={(e)=> {
                            e.preventDefault()
                            setEdit(true)
                        }}>edytuj</button>
                    </div>
                </div>
          
        </div>
      ) 
}
// else {
//     // console.log(path,' removed: ',getValue(character, namePath))
//     // console.log(character)
//     return (
//         <div>{path}: ---ERROR--- </div>
//     )
// }
}

export default InputUtensiluim
