import styles from './Login.module.css'

function Login({
  handleSubmit,
  handleSetEmail,
  handleSetPassword,
  email,
  password,
  haveAccount
}) {

  // const [password, setPassword] = useState('')
  // const [email, setLogin] = useState('')

  return (
    <div className={styles.loginBox}>
      <form className={styles.loginForm} onSubmit={handleSubmit}>
        <label className={styles.label}>
          {/* <span className={styles.formSpan}>Email: </span> */}
          <input 
            className = {styles.formInput} 
            type = "email" 
            name = "email" 
            placeholder='Email'
            onChange = {(e) => handleSetEmail(e.target.value)}
            value = {email} 
            autoComplete={haveAccount ? undefined : 'off'}
            />
          <span className={styles.formSpan}></span>
        </label>
        <label className={styles.label}>
          {/* <span className={styles.formSpan}>Password: </span> */}
          <input 
            className={styles.formInput} 
            placeholder={haveAccount ? 'Hasło' : 'Stwórz hasło'}
            type = "password" 
            name = "password" 
            onChange = {(e) => handleSetPassword(e.target.value)}
            value = {password}
            autoComplete={haveAccount ? undefined : 'off'}
          />
          <span className={styles.formSpan}></span>
        </label>
        <label className={styles.label}>
          {haveAccount 
            ? <button className={styles.button} type='submit'>Zaloguj się</button>
            : <button className={styles.button} type='submit'>Zgłoś chęć dołączenia</button>
          } 
          
        </label>
        
      </form>
    </div>
  )
}

export default Login
