import styles from './Home.module.css'
import ArticlesList from '../components/ArticlesList';



function Home() {

  return (
    <div id='homeContent' className={styles.HomeContent}>
        <ArticlesList />
    </div>
  )
}

export default Home
