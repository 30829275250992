import { doc, updateDoc } from 'firebase/firestore';
import { Link, useNavigate } from 'react-router-dom'
import { db } from '../firebase/config'
import { useState } from 'react';
import Modal from '../components/Modal';
import { useAuthContext } from '../hooks/useAuthContext';
import Select from 'react-select'
import { selectClassNames } from '../misc/SelectClassNames';
import styles from './CharactersSublist.module.css'
import { deepSearchCheck, searchCheck } from '../misc/CommonFunctions'

const deleteArticle = async (id) => {
    const ref = doc(db, 'characters', id)
    await updateDoc(ref, {
      removed: true
    })
  }

  const updateOwner = async (char, uid) => {
    const ref = doc(db, 'characters', char)
    await updateDoc(ref, {
      owner: uid
    })
  }

function CharactersSublist({
    universum, 
    characters, 
    search, 
    inContent, 
    universeFilters,
    usersInDB : players
}) {

    
    const { userIsAdmin } = useAuthContext()
    const[showModal, setShowModal] = useState(false)
    const[itemToDelete, setItemToDelete] = useState('')
    const[changingOwner, setChangingOwner] = useState('')

    const navigate = useNavigate()

    const handleClose = () => {
        setShowModal(false)
    }
    const handleConfirm = () => {
        // console.log(itemToDelete)
        deleteArticle(itemToDelete.id)
        setShowModal(false)
    }
    const toDelete = (item) => {
        setItemToDelete(item)
        setShowModal(true)
    }

    const searchRegExp = new RegExp(search, 'i')

    const getEmailById = (id) => {
        let email = ''
        players.forEach((p)=>{
            if(p.id === id) {
                email = p.email
            }
        })
        return email
    }

    const getUsers = () => {
        let p = []
        players.forEach((i) => {
            p = [...p, {
                value: i.id,
                label: i.email
            }]
        })
        return p
    }
        
    
    const handleSelect = (selected, char) => {
        updateOwner(char, selected.value)
    }

if((!userIsAdmin && characters) || (userIsAdmin && characters && players)) {

    return (
        <div>
        {showModal &&
            <Modal handleClose={handleClose} handleConfirm={handleConfirm} cancelText='Anuluj' confirmText='Usuń'>
            <p>Na pewno usunąć postać {itemToDelete.name}?</p>
            </Modal>
        }
        {universeFilters[universum] && <h3>{universum}</h3>}
        <ul> 
            {characters && characters.map((d,i) => (
            ((!search || (
                inContent 
                ? (deepSearchCheck(d, searchRegExp)) 
                : searchCheck(d.name, searchRegExp)
                        )) && universeFilters[universum]) 
                &&  
            <li key={d.id+i}>
                <div>
                    {/* <p>{d.id}</p> */}
                    <p><Link to={`/${'characters'}/${d.id}`}>{d.name}</Link></p>
                    <p>Stworzono: {d.creationDate}, {d.creationTime}</p>
                    {userIsAdmin && 
                    <p>{getEmailById(d.owner)}</p>
                    }
                </div>
                <div>
                    <button onClick={()=>{
                        navigate(`/${'characters'}/${d.id}`)
                    }}>Edytuj</button>
                </div>
                {(userIsAdmin && players) &&  
                    <div>  
                    {changingOwner !== d.id
                    ?
                    <div>
                        <button
                            onClick={(e)=>{
                                e.preventDefault()
                                setChangingOwner(d.id)
                            }}
                        >Zmień właściciela</button>
                    </div>
                    :
                    changingOwner === d.id &&
                    <div className={styles.changeOwner}>
                        <div>Obecny właściciel: {getEmailById(d.owner)}</div>
                        <div>
                            <label>Zmień właściciela: 
                                
                                <Select 
                                    options={getUsers()}
                                    onChange={(selectedOption) => handleSelect(selectedOption, d.id)}
                                    value={{value: d.owner, label: getEmailById(d.owner)}}
                                    unstyled
                                    classNames={selectClassNames}
                                /> 
                                    
                            </label>

                        </div>
                        <div>
                        <button
                            onClick={(e)=>{
                                e.preventDefault()
                                setChangingOwner('')
                            }}
                        >Wyjdź</button>
                        </div>
                    </div>
                    }
                    </div>
                }
                <div>
                    <button onClick={()=>{
                    toDelete(d)
                    }}>Usuń</button>
                </div>
            </li>
            ))}
        </ul>
        </div>
    )

}
}

export default CharactersSublist
