import { useParams } from "react-router-dom"
import s from './PrintCharacterSheet.module.css'
import { useNavigate } from 'react-router-dom'
import ReactToPrint from 'react-to-print';
import { useRef, useState, useEffect } from "react";
import { doc, collection, onSnapshot, updateDoc } from 'firebase/firestore';
import { db } from '../firebase/config';
import ValueDescription from "../components/CharacterEditionComponents/ValueDescription";
import { setTopMargin } from '../misc/CommonFunctions'

function PrintCharacterSheet() {

const navigate = useNavigate()

const { id } = useParams()
const [c, setCharacter] = useState()
const componentRef = useRef()
const [portraitSize, setPortraitSize] = useState(150)
const [portraitX, setPortraitX] = useState(50)
const [portraitY, setPortraitY] = useState(50)

const [showTools, setShowTools] = useState(false)

useEffect(()=>{
    let ref = doc(collection(db, 'characters'), id)
    const unsub = onSnapshot(ref, (snapshot) => {
      let c = snapshot.data()
      setCharacter(c)
      if(c.portraitPos){
        setPortraitX(c.portraitPos.x)
        setPortraitY(c.portraitPos.y)
        setPortraitSize(c.portraitPos.z)
      }
    })
    
    return () => unsub
},[id])

const getPseudoArray = (v) => {
    let n = Number(v)
    let a = []
    for(let i = 0;i<n;i++){
        a = [...a, i]
    }
    return a
}

const savePos = (x,y,z) => {
    let ref = doc(collection(db, 'characters'), id)
    updateDoc(ref, {
        'portraitPos.x': x,
        'portraitPos.y': y,
        'portraitPos.z': z         
    })
}

function getInt(s) {
    let o = ''
    if(typeof s === 'string'){
      o = Number(s.match(/(\d+)/)[0])
    }
    else {
      o = s
    }
    return o
}

const getItems = (path, inRoot) => {    //ie. 'abilities'
    let items = []
    if(inRoot){
        for(let i in c){
            // console.log('i: ',i)
            // if(c[i].hasOwnProperty(path))
            if(i.startsWith(path))
            {
                items = [...items, i]
            }
        }
    }
    else {
        // console.log('not in root')
        for(let i in c[path]) {
            items = [...items, i]
        }
        // console.log('items: ',items)
    }
    return items.sort((a,b) => getInt(a) - getInt(b))
}


const gv = (obj, path) => {
    if(!obj) {
      return ''
    }
    const [head, ...rest] = path.split('.')
        if(rest.length && obj[head])
        {
          return gv(obj[head], rest.join('.'))
        }
        else if(obj[head])
        { 
          return obj[head]
        }
        else 
        { 
          return ''
        }
  }

  useEffect(()=>{
    setTopMargin()
  })

  const pageStyle ="@page {size: A4 landscape; }"; //or:   size: 2.5in 4in


if(c) {
  return (
    <div className={s.page}>
        <nav className="tools">
        
        <div>
        <ReactToPrint
            trigger={() => <button>Drukuj</button>}
            content={() => componentRef.current}
            pageStyle={pageStyle}
        />
        </div>
        <div>
        <button
            onClick={()=>{
                navigate(`/characters/${id}`)
            }}
        >Wróć do edycji</button>
        </div>

        {!showTools &&
           <div> 
            <button
                onClick={(e)=>{
                    e.preventDefault()
                    setShowTools(true)
                    setTopMargin()
                }}
            >Opcje portretu</button>
            </div>
        }
        {showTools &&
        <div className={s.portraitDimensions}>
        <div>Portret:</div>
        <label>
            
            Rozmiar
            <input 
                type="range" 
                value={portraitSize}
                onChange={(e)=>{setPortraitSize(e.target.value)}}
                max="700"
                min="5"
            />
            </label>
            <label>Pozycja poziom
                <input 
                    className={s.xRange}
                    type="range" 
                    value={portraitX}
                    onChange={(e)=>{setPortraitX(e.target.value)}}
                    max="100"
                    min="0"
                />
            </label>
            <label>Pozycja pion
                <input 
                    type="range" 
                    value={portraitY}
                    onChange={(e)=>{setPortraitY(e.target.value)}}
                    max="100"
                    min="0"
                />
            </label>  
            <div>
                <button
                    onClick={(e)=>{
                        e.preventDefault()
                        savePos(portraitX,portraitY,portraitSize)
                        setShowTools(false)
                    }}
                >
                    Zapisz pozycję
                </button>
            </div>
           <div>
            <button
                onClick={(e)=>{
                    e.preventDefault()
                    setShowTools(false)
                    setTopMargin()
                }}
            >Zamknij</button>
            </div>
        </div> 
            
        }             
        </nav>
        <h2>Podgląd wydruku: <span>{c.name}</span></h2>
        
        <div className={s.characterSheet} id='toPrint' ref={componentRef} >
            
            <style type="text/css" media="print">{" @page { size: landscape; } "}</style>

            <div className={s.main}>
                <div className={s.up}>
                    <div className={s.appearance}>
                        <div style={{
                                backgroundImage: 'url('+gv(c,'appearance.portrait')+')',
                                backgroundSize: portraitSize+'%',
                                backgroundPositionX: portraitX+'%',
                                backgroundPositionY: portraitY+'%'
                            }} className={s.portrait}>
                        </div>
                        <div className={s.ageHei}>
                            <div className={s.age}><h5>Wiek:</h5><p>{gv(c,'metrics.age')}{gv(c,'metrics.age')&&<> lat</>}</p></div>
                            <div className={s.height}><h5>Wzrost:</h5><p>{gv(c,'metrics.height')}{gv(c,'metrics.height')&&<> cm</>}</p></div>
                        </div>
                        <div className={s.posture}><h5>Postura:</h5><p>{gv(c,'appearance.posture')}</p></div>
                        <div className={s.appearDesc}><h5>Inne cechy wyglądu:</h5><p>{gv(c,'appearance.description')}</p></div>
                    </div>
                    <div className={s.metrics}>
                        <div className={s.name}><h5>Imię:</h5><h2>{gv(c,'name')}</h2></div>
                        <div className={s.profDesc}><h5>Krótki opis / fach:</h5><p>{gv(c,'roleplay.description')}</p></div>
                        <div className={s.socialState}><h5>Stan społeczny:</h5><p>{gv(c,'metrics.social_state')}</p></div>
                        <div className={s.connections}><h5>Koneksje:</h5><p>{gv(c,'bio.connections')}</p></div>
                        <div className={s.origin}><h5>Pochodzenie:</h5><p>{gv(c,'metrics.origin')}</p></div>
                        <div className={s.folk}><h5>Lud:</h5><p>{gv(c,'metrics.folk')}</p></div>
                    </div>
                    <div className={s.roleplay}>
                        <div className={s.characterType}><h5>Inspiracje osobowości postaci:</h5><p>{gv(c,'roleplay.personality_inspirations')}</p></div>
                        <div className={s.goals}><h5>Główne cele:</h5><p>{gv(c,'roleplay.goals')}</p></div>
                        <div className={s.roles}><h5>Role społeczne:</h5><p>{gv(c,'roleplay.roles')}</p></div>
                        <div className={s.disorders}><h5>Zaburzenia osobowości / psychiczne:</h5><p>{gv(c,'roleplay.disorders')}</p></div>
                        <div className={s.keyEvents}><h5>Kluczowe zdarzenia z przeszłości:</h5><p>{gv(c,'bio.key_events')}</p></div>
                        <div className={s.otherPersonality}><h5>Inne istotne cechy osobowości:</h5><p>{gv(c,'roleplay.personality')}</p></div>
                    </div>
                    <div className={s.progresses}>
                        <h5>Obecnie rozwijane:</h5>
                        {getItems('progress', true).map((i,x)=>{
                            return (
                                <div className={s.progress} key={x}>
                                    <div className={s.progressTable}>
                                        <div className={s.what}>
                                            <h5>Co:</h5>
                                            <p>{gv(c,i+'.name')}</p>
                                        </div>
                                        <div>
                                            <h5>Postępy:</h5>
                                            <div className={s.points}>
                                                {gv(c,i+'.progress')>=1 ? <div className={s.filledPoint}></div> : <div></div>}
                                                {gv(c,i+'.progress')>=2 ? <div className={s.filledPoint}></div> : <div></div>}
                                                {gv(c,i+'.progress')>=3 ? <div className={s.filledPoint}></div> : <div></div>}
                                                {gv(c,i+'.progress')>=4 ? <div className={s.filledPoint}></div> : <div></div>}
                                                {gv(c,i+'.progress')>=5 ? <div className={s.filledPoint}></div> : <div></div>}
                                                {gv(c,i+'.progress')>=6 ? <div className={s.filledPoint}></div> : <div></div>}
                                                {gv(c,i+'.progress')>=7 ? <div className={s.filledPoint}></div> : <div></div>}
                                                {gv(c,i+'.progress')>=8 ? <div className={s.filledPoint}></div> : <div></div>}
                                                {gv(c,i+'.progress')>=9 ? <div className={s.filledPoint}></div> : <div></div>}
                                                {gv(c,i+'.progress')>=10 ? <div className={s.filledPoint}></div> : <div></div>}
                                            </div>
                                            <div className={s.points}>
                                                {gv(c,i+'.progress')>=11 ? <div className={s.filledPoint}></div> : <div></div>}
                                                {gv(c,i+'.progress')>=12 ? <div className={s.filledPoint}></div> : <div></div>}
                                                {gv(c,i+'.progress')>=13 ? <div className={s.filledPoint}></div> : <div></div>}
                                                {gv(c,i+'.progress')>=14 ? <div className={s.filledPoint}></div> : <div></div>}
                                                {gv(c,i+'.progress')>=15 ? <div className={s.filledPoint}></div> : <div></div>}
                                                {gv(c,i+'.progress')>=16 ? <div className={s.filledPoint}></div> : <div></div>}
                                                {gv(c,i+'.progress')>=17 ? <div className={s.filledPoint}></div> : <div></div>}
                                                {gv(c,i+'.progress')>=18 ? <div className={s.filledPoint}></div> : <div></div>}
                                                {gv(c,i+'.progress')>=19 ? <div className={s.filledPoint}></div> : <div></div>}
                                                {gv(c,i+'.progress')>=20 ? <div className={s.filledPoint}></div> : <div></div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>  
                            )
                        })}
                        {getPseudoArray(6 - getItems('progress',true).length).map((i,x)=>{
                            return (
                                <div className={s.progress} key={x}>
                                    <div className={s.progressTable}>
                                        <div className={s.what}>
                                            <h5>Co:</h5>
                                            <p>{gv(c,i+'.name')}</p>
                                        </div>
                                        <div>
                                            <h5>Postępy:</h5>
                                            <div className={s.points}>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                            <div className={s.points}>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>  
                            )
                        })

                        }
                    </div>
                </div>
                <div className={s.middle}>
                    <div className={s.competences}>
                        <div className={s.compHead}>
                            <div><p>KOMPETENCJE</p></div>
                            <div className={s.bonusHead}><p>Premia<br />/Kara</p></div>
                        </div>
                        <div className={s.compCatHead}><p>Wyczynowe</p></div>
                        <div className={s.comp}>
                            <div className={s.compName}><p>SIŁA</p></div>
                            <div className={s.compValue}><p>{gv(c,'competences.strength')}</p></div>
                        </div>
                        <div className={s.comp}>
                            <div className={s.compName}><p>WALKA</p></div>
                            <div className={s.compValue}> 
                                <span className={s.fightingIcon}></span>  
                                <p>{gv(c,'competences.fighting')}</p>
                            </div>
                        </div>
                        <div className={s.comp}>
                            <div className={s.compName}><p>SPRAWNOŚĆ</p></div>
                            <div className={s.compValue}><p>{gv(c,'competences.agility')}</p></div>
                        </div>
                        <div className={s.comp}>
                            <div className={s.compName}><p>CELNOŚĆ</p></div>
                            <div className={s.compValue}>
                                <span className={s.marksmanshipIcon}></span>
                                <p>{gv(c,'competences.marksmanship')}</p>
                            </div>
                        </div>
                        <div className={s.comp}>
                            <div className={s.compName}><p>WYDOLNOŚĆ</p></div>
                            <div className={s.compValue}><p>{gv(c,'competences.endurance')}</p></div>
                        </div>
                        <div className={s.comp}>
                            <div className={s.compName}><p>ZIMNA KREW</p></div>
                            <div className={s.compValue}><p>{gv(c,'competences.cold_blood')}</p></div>
                        </div>
                        <div className={s.compCatHead}><p>Intelektualne</p></div>
                        <div className={s.comp}>
                            <div className={s.compName}><p>KALKULACJA</p></div>
                            <div className={s.compValue}><p>{gv(c,'competences.logic')}</p></div>
                        </div>
                        <div className={s.comp}>
                            <div className={s.compName}><p>ERUDYCJA</p></div>
                            <div className={s.compValue}><p>{gv(c,'competences.erudition')}</p></div>
                        </div>
                        <div className={s.comp}>
                            <div className={s.compName}><p>TECHNIKA</p></div>
                            <div className={s.compValue}><p>{gv(c,'competences.technics')}</p></div>
                        </div>
                        <div className={s.compCatHead}><p>Uważnościowe</p></div>
                        <div className={s.comp}>
                            <div className={s.compName}><p>SKRADANIE</p></div>
                            <div className={s.compValue}>
                                <span className={s.stealthIcon}></span>
                                <p>{gv(c,'competences.stealth')}</p>
                            </div>
                        </div>
                        <div className={s.comp}>
                            <div className={s.compName}><p>PERCEPCJA</p></div>
                            <div className={s.compValue}><p>{gv(c,'competences.perception')}</p></div>
                        </div>
                        <div className={s.compCatHead}><p>Społeczne</p></div>
                        <div className={s.comp}>
                            <div className={s.compName}><p>PERSWAZJA</p></div>
                            <div className={s.compValue}><p>{gv(c,'competences.persuasion')}</p></div>
                        </div>
                        <div className={s.comp}>
                            <div className={s.compName}><p>PRZENIKLIWOŚĆ</p></div>
                            <div className={s.compValue}><p>{gv(c,'competences.perspicacity')}</p></div>
                        </div>
                    </div>
                    <div className={s.utensiliaMid}>
                        <div>
                        {getItems('weapon', true).map((i,x)=>{
                            return (
                                <div className={s.weapon} key={x}>
                                    <div className={s.utensName}>
                                        <h5>Broń</h5>
                                        <p>{gv(c,i+'.name')}</p>
                                    </div>
                                    <div className={s.utensRange}>
                                        <div><p>Zasięg: </p></div>
                                        <div className={s.range1}>
                                            <div className={s.rangeSlot}></div>
                                            {gv(c,i+'.range.short') && <div className={s.rangeFill}></div>}
                                        </div>
                                        <div className={s.range2}>
                                            <div className={s.rangeSlot}></div>
                                            {gv(c,i+'.range.medium') && <div className={s.rangeFill}></div>}
                                        </div>
                                        <div className={s.range3}>
                                            <div className={s.rangeSlot}></div>
                                            {gv(c,i+'.range.long') && <div className={s.rangeFill}></div>}
                                        </div>
                                        <div></div>
                                    </div>
                                    <div><p>Szybkość: <span className={s.utensValue}>{gv(c,i+'.speed')}</span></p><span className={s.fightingIcon}></span></div>
                                    <div>
                                        <h5>Cechy</h5>
                                        <p>{gv(c,i+'.description')}</p>
                                    </div>
                                </div>
                            )})}
                            {getPseudoArray(3 - getItems('weapon',true).length).map((i,x)=>{
                            return (
                                <div className={s.weapon} key={x} >
                                    <div className={s.utensName}>
                                        <h5>Broń</h5>
                                        <p></p>
                                    </div>
                                    <div className={s.utensRange}>
                                        <div><p>Zasięg: </p></div>
                                        <div className={s.range1}>
                                            <div className={s.rangeSlot}></div>
                                        </div>
                                        <div className={s.range2}>
                                            <div className={s.rangeSlot}></div>
                                        </div>
                                        <div className={s.range3}>
                                            <div className={s.rangeSlot}></div>
                                        </div>
                                        <div></div>
                                    </div>
                                    <div><p>Szybkość: <span className={s.utensValue}></span></p><span className={s.fightingIcon}></span></div>
                                    <div>
                                        <h5>Cechy</h5>
                                        <p></p>
                                    </div>
                                </div>
                            )})}
                            
                        </div>
                        <div>
                            {getItems('camouflage', true).map((i,x)=>{
                                return (
                                    <div className={s.camouflage} key={x}>
                                        <div className={s.utensName}>
                                            <h5>Kamuflaż</h5>
                                            <p>{gv(c,i+'.name')}</p>
                                        </div>
                                        
                                        <div><p>Kamuflaż: <span className={s.utensValue}>{gv(c,i+'.camouflage')}</span></p><span className={s.stealthIcon}></span></div>
                                        <div>
                                            <h5>Cechy</h5>
                                            <p>{gv(c,i+'.description')}</p>
                                        </div>
                                    </div>
                            )})}
                            {getPseudoArray(1 - getItems('camouflage',true).length).map((i,x)=>{
                            return (
                                <div className={s.camouflage} key={x}>
                                    <div className={s.utensName}>
                                        <h5>Kamuflaż</h5>
                                        <p></p>
                                    </div>
                                    
                                    <div><p>Kamuflaż: <span className={s.utensValue}></span></p><span className={s.stealthIcon}></span></div>
                                    <div>
                                        <h5>Cechy</h5>
                                        <p></p>
                                    </div>
                                </div>
                            )})}
                            {getItems('shield', true).map((i,x)=>{
                                return (
                                <div className={s.shield} key={x}>    
                                    <div className={s.utensName}>
                                        <h5>Tarcza</h5>
                                        <p>{gv(c,i+'.name')}</p>
                                    </div>
                                    <div className={s.utensRange}>
                                        <div><p>Zasięg: </p></div>
                                        <div className={s.range1}>
                                            <div className={s.rangeSlot}></div>
                                            {gv(c,i+'.range.short') && <div className={s.rangeFill}></div>}
                                        </div>
                                        <div className={s.range2}>
                                            <div className={s.rangeSlot}></div>
                                            {gv(c,i+'.range.medium') && <div className={s.rangeFill}></div>}
                                        </div>
                                        <div className={s.range3}>
                                            <div className={s.rangeSlot}></div>
                                            {gv(c,i+'.range.long') && <div className={s.rangeFill}></div>}
                                        </div>
                                        <div></div>
                                    </div>
                                    <div><p>Opancerzenie: <span className={s.utensValue}>{gv(c,i+'.armor_level')}</span></p><span className={s.armorLevelIcon}></span></div>
                                    <div>
                                        <h5>Cechy</h5>
                                        <p>{gv(c,i+'.description')}</p>
                                    </div>
                                </div>
                            )})}
                            {getPseudoArray(1 - getItems('shield',true).length).map((i,x)=>{
                                return (
                                    <div className={s.shield} key={x}>    
                                        <div className={s.utensName}>
                                            <h5>Tarcza</h5>
                                            <p></p>
                                        </div>
                                        <div className={s.utensRange}>
                                            <div><p>Zasięg: </p></div>
                                            <div className={s.range1}>
                                                <div className={s.rangeSlot}></div>
                                            </div>
                                            <div className={s.range2}>
                                                <div className={s.rangeSlot}></div>
                                            </div>
                                            <div className={s.range3}>
                                                <div className={s.rangeSlot}></div>
                                            </div>
                                            <div></div>
                                        </div>
                                        <div><p>Opancerzenie: <span className={s.utensValue}></span></p><span className={s.armorLevelIcon}></span></div>
                                        <div>
                                            <h5>Cechy</h5>
                                            <p></p>
                                        </div>
                                    </div>    
                            )})}
                            {getItems('armor', true).map((i,x)=>{
                                return (
                                    <div className={s.armor} key={x}>
                                        <div className={s.utensName}>
                                            <h5>Zbroja</h5>
                                            <p>{gv(c,i+'.name')}</p>
                                        </div>
                                        <div><p>Opancerzenie: <span className={s.utensValue}>{gv(c,i+'.armor_level')}</span></p><span className={s.armorLevelIcon}></span></div>
                                        <div>
                                            <h5>Cechy</h5>
                                            <p>{gv(c,i+'.description')}</p>
                                        </div>
                                    </div>
                            )})}
                            {getPseudoArray(1 - getItems('armor',true).length).map((i,x)=>{
                            return (
                                <div className={s.armor} key={x}>
                                    <div className={s.utensName}>
                                        <h5>Zbroja</h5>
                                        <p></p>
                                    </div>
                                    <div><p>Opancerzenie: <span className={s.utensValue}></span></p><span className={s.armorLevelIcon}></span></div>
                                    <div>
                                        <h5>Cechy</h5>
                                        <p></p>
                                    </div>
                                </div>
                            )})}    
                            
                        </div>
                    </div>
                    <div className={s.abilities}>
                        <div className={s.abiHead}>
                                <p>Zdolności</p>
                        </div>
                        {getItems('abilities').map((i,x)=>{
                            return (
                                <div key={x}>
                                    <div><p>{gv(c,'abilities.'+i+'.name')}</p></div>
                                    <div><p>{gv(c,'abilities.'+i+'.value')}</p></div>
                                </div>
                        )})}
                        {getPseudoArray(12 - getItems('abilities').length).map((i,x)=>{
                            return (
                                <div key={x}>
                                    <div></div>
                                    <div></div>
                                </div>
                        )})} 
                        <div className={s.abiHead}>
                                <p>Niezdolności</p>
                        </div>
                        {getItems('disabilities').map((i,x)=>{
                            return (
                                <div key={x}>
                                    <div><p>{gv(c,'disabilities.'+i+'.name')}</p></div>
                                    <div><p>{gv(c,'disabilities.'+i+'.value')}</p></div>
                                </div>
                        )})}
                        {getPseudoArray(5 - getItems('disabilities').length).map((i,x)=>{
                            return (
                                <div key={x}>
                                    <div></div>
                                    <div></div>
                                </div>
                        )})} 
                    </div>
                    <div className={s.inventory}>
                        <div className={s.invMainHead}><p>INWENTARZ</p></div>
                        <div className={s.invHead}><p>Przy sobie</p></div>
                        <div className={s.invSingleSection}>
                            <p>{gv(c,'inventory.self')}</p>
                        </div>
                        <div className={s.invHead}><p>Toboła / ...</p></div>
                        <div className={s.invSingleSection}>
                            <p>{gv(c,'inventory.bag')}</p>
                        </div>
                        <div className={s.invTwinHead}>
                            <div><p>Wierzchowiec / Pojazd / ...</p></div>
                            <div><p>Siedziba / ...</p></div>
                        </div>
                        <div className={s.invSection}>
                            <div><p>{gv(c,'inventory.mount')}</p></div>
                            <div><p>{gv(c,'inventory.quarter')}</p></div>
                        </div>
                    </div>
                </div>
                <div className={s.bottom}>
                    <div className={s.state}>
                        <div className={s.stateHead}>
                            <p>STAN:</p>
                        </div>
                        <div className={s.stateContent}>
                            <p>{gv(c,'temporary_values.state')}</p>
                        </div>
                    </div>
                    <div className={s.utensiliaBot}>
                        {getItems('ranged_weapon', true).map((i,x)=>{
                            return (
                                <div className={s.rangedWeapon} key={x}>
                                    <div className={s.utensName}>
                                        <h5>Broń Dystansowa</h5>
                                        <p>{gv(c,i+'.name')}</p>
                                    </div>
                                    
                                    <div><p>Celność: <span className={s.utensValue}>{gv(c,i+'.accuracy')}</span></p><span className={s.marksmanshipIcon}></span></div>
                                    <div><p>Przeładowanie: <span className={s.utensValueReload}>{gv(c,i+'.reload')}</span></p><span className={s.reloadIcon}></span></div>
                                    <div>
                                        <h5>Cechy</h5>
                                        <p>{gv(c,i+'.description')}</p>
                                    </div>
                                </div>  
                        )})}
                        {getPseudoArray(3 - getItems('ranged_weapon',true).length).map((i,x)=>{
                            return (
                                <div className={s.rangedWeapon} key={x}>
                                    <div className={s.utensName}>
                                        <h5>Broń Dystansowa</h5>
                                        <p></p>
                                    </div>
                                    
                                    <div><p>Celność: <span className={s.utensValue}></span></p><span className={s.marksmanshipIcon}></span></div>
                                    <div><p>Przeładowanie: <span className={s.utensValueReload}></span></p><span className={s.reloadIcon}></span></div>
                                    <div>
                                        <h5>Cechy</h5>
                                        <p></p>
                                    </div>
                                </div>
                        )})} 
                    </div>
                    <div className={s.misc}>
                        <h5>Inne przedmioty zmieniające kompetencje</h5>
                        <div className={s.miscHead}>
                            <div>
                                <p>Przedmiot:</p>
                            </div>
                            <div>
                                <p>Kompetencja:</p>
                            </div>
                            <div className={s.miscBonusHead}>
                                <p>Premia 
                                   <br />/Kara: 
                                </p>
                            </div>
                        </div>
                        {getItems('misc_items').map((i,x)=>{
                            return (
                                <div className={s.miscItem} key={x}>
                                    <div>
                                        <p>{gv(c,'misc_items.'+i+'.name')}</p>
                                    </div>
                                    <div>
                                        <p>{gv(c,'misc_items.'+i+'.stat')}</p>
                                    </div>
                                    <div>
                                        <p>{gv(c,'misc_items.'+i+'.bonus')}</p>
                                    </div>
                                </div>  
                        )})}
                        {getPseudoArray(9 - getItems('misc_items').length).map((i,x)=>{
                            return (
                                <div className={s.miscItem} key={x}>
                                    <div><p></p></div>
                                    <div><p></p></div>
                                    <div><p></p></div>
                                </div>
                        )})}
                    </div>
                    {getItems('mount', true).map((i,x)=>{
                        return (
                            <div className={s.mount} key={x}>
                                <h5>Wierzchowiec / Pojazd</h5>
                                <div className={s.mountName}>
                                    <p>{gv(c,i+'.name')}</p>
                                </div>
                                <div className={s.mountStat}>
                                    <div><p>Szybkość:</p></div>
                                    <div><p>{gv(c,i+'.speed')}</p></div>
                                </div>
                                <div className={s.mountStat}>
                                    <div className={s.longStatName}><p>Wytrzymałość / zasięg (km):</p></div>
                                    <div><p>{gv(c,i+'.endurance')}</p></div>
                                </div>
                                <div className={s.mountStat}>
                                    <div><p>Zwrotność:</p></div>
                                    <div><p>{gv(c,i+'.maneuverability')}</p></div>
                                </div>
                                <div className={s.mountStat}>
                                    <div><p>Odwaga:</p></div>
                                    <div><p>{gv(c,i+'.courage')}</p></div>
                                </div>
                                <div className={s.mountDesc}>
                                    <h5>Cechy</h5>
                                    <p>{gv(c,i+'.description')}</p>
                                </div>
                            </div>
                    )})}
                    {getPseudoArray(1 - getItems('mount',true).length).map((i,x)=>{
                        return (
                            <div className={s.mount} key={x}>
                                <h5>Wierzchowiec / Pojazd</h5>
                                <div className={s.mountName}>

                                </div>
                                <div className={s.mountStat}>
                                    <div></div>
                                    <div></div>
                                </div>
                                <div className={s.mountStat}>
                                    <div></div>
                                    <div></div>
                                </div>
                                <div className={s.mountStat}>
                                    <div></div>
                                    <div></div>
                                </div>
                                <div className={s.mountStat}>
                                    <div></div>
                                    <div></div>
                                </div>
                                <div className={s.mountDesc}>
                                    <h5>Cechy</h5>
                                </div>
                            </div>
                    )})} 
                    
                    <div className={s.wealth}>
                        <h5>Majętność</h5>
                        <div className={s.wealthLevel}>
                            <div><p>Poziom:</p></div>
                            <div><p>{gv(c,'wealth.level')}</p></div>                            
                        </div>
                        <div>
                            <h5>Opis</h5>
                            <ValueDescription 
                                stat='wealthLevel' 
                                universum={gv(c, 'universum')} 
                                value={gv(c, 'wealth.level')} />
                        </div>
                        <div>
                            <h5>Inny majątek</h5>
                            <p>{gv(c,'wealth.description')}</p>
                        </div>
                    </div>
                </div>
                <div className={s.downSide}>
                    {getItems('ammo', true).map((i,x)=>{
                        return (
                            <div className={s.ammo} key={x}>
                                <div className={s.resInfo}>
                                    <div>
                                        <h5>Amunicja</h5>
                                        <p>{gv(c,i+'.name')}</p>
                                    </div>
                                    <div className={s.resVal}>
                                        <h5>Celność</h5>
                                        <p>{gv(c,i+'.accuracy')}</p>
                                    </div>
                                </div>
                                <div className={s.resAmount}>
                                    <h5>Ilość</h5>
                                    <div>{gv(c,i+'.amount') === '1' ? <div className={s.resChecked}><p>1</p></div> : <div><p>1</p></div>}</div>
                                    <div>{gv(c,i+'.amount') === '2' ? <div className={s.resChecked}><p>2</p></div> : <div><p>2</p></div>}</div>
                                    <div>{gv(c,i+'.amount') === '3' ? <div className={s.resChecked}><p>3</p></div> : <div><p>3</p></div>}</div>
                                    <div className={s.few}>{gv(c,i+'.amount') === 'Mało' ? <div className={s.resChecked}><div className={s.fill}></div></div> : <div className={s.fill}></div>}</div>
                                    <div className={s.quite}>{gv(c,i+'.amount') === 'Trochę' ? <div className={s.resChecked}><div className={s.fill}></div></div> : <div className={s.fill}></div>}</div>
                                    <div className={s.much}>{gv(c,i+'.amount') === 'Dużo' ? <div className={s.resChecked}><div className={s.fill}></div></div> : <div className={s.fill}></div>}</div>
                                </div>
                            </div>
                    )})}
                    {getPseudoArray(4 - getItems('ammo',true).length).map((i,x)=>{
                        return (

                            <div className={s.ammo} key={x}>
                                <div className={s.resInfo}>
                                    <div><h5>Amunicja</h5></div>
                                    <div className={s.resVal}><h5>Celność</h5></div>
                                </div>
                                <div className={s.resAmount}>
                                    <h5>Ilość</h5>
                                    <div><p>1</p></div>
                                    <div><p>2</p></div>
                                    <div><p>3</p></div>
                                    <div className={s.few}><div className={s.fill}></div></div>
                                    <div className={s.quite}><div className={s.fill}></div></div>
                                    <div className={s.much}><div className={s.fill}></div></div>
                                </div>
                            </div>

                    )})} 
                </div>
            </div>
            <div className={s.rightSide}>
                {getPseudoArray(5 - getItems('resource',true).length).map((i,x)=>{
                        return (
                            <div className={s.resource} key={x}>                  
                                <div className={s.resAmount}>
                                    <h5>Ilość</h5>
                                    <div className={s.much}><div className={s.fill}></div></div>
                                    <div className={s.quite}><div className={s.fill}></div></div>                      
                                    <div className={s.few}><div className={s.fill}></div></div>
                                    <div><p>3</p></div>
                                    <div><p>2</p></div>
                                    <div><p>1</p></div>   
                                </div>
                                <div className={s.resInfo}>
                                    <div><h5>Zasób</h5></div>
                                </div>
                            </div>
                )})}
                {getItems('resource', true).reverse().map((i,x)=>{
                    return (
                        <div className={s.resource} key={x}>
                            <div className={s.resAmount}>
                                <h5>Ilość</h5>
                                <div className={s.much}>{gv(c,i+'.amount') === 'Dużo' ? <div className={s.resChecked}><div className={s.fill}></div></div> : <div className={s.fill}></div>}</div>
                                <div className={s.quite}>{gv(c,i+'.amount') === 'Trochę' ? <div className={s.resChecked}><div className={s.fill}></div></div> : <div className={s.fill}></div>}</div>
                                <div className={s.few}>{gv(c,i+'.amount') === 'Mało' ? <div className={s.resChecked}><div className={s.fill}></div></div> : <div className={s.fill}></div>}</div>
                                <div>{gv(c,i+'.amount') === '3' ? <div className={s.resChecked}><p>3</p></div> : <div><p>3</p></div>}</div>
                                <div>{gv(c,i+'.amount') === '2' ? <div className={s.resChecked}><p>2</p></div> : <div><p>2</p></div>}</div>
                                <div>{gv(c,i+'.amount') === '1' ? <div className={s.resChecked}><p>1</p></div> : <div><p>1</p></div>}</div>
                            </div>
                            <div className={s.resInfo}>
                                <div>
                                    <h5>Zasób</h5>
                                    <p>{gv(c,i+'.name')}</p>
                                </div>
                            </div>
                        </div>
                    )})}
                    
            </div>
        </div>
    </div>
  )
}
}

export default PrintCharacterSheet
