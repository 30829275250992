import styles from './Loading.module.css'
import { useLogout } from '../hooks/useLogout';
// import preloader from '../assets/preloader.gif'

function Loading() {

const { logout } = useLogout()

  return (
    <div className={styles.loader}>
        <h3>WCZYTYWANIE...</h3>
        {/* <img src={preloader} alt='loading' /> */}
        <div className={styles.loaderImg}></div>
        <button className='linkLikeButton' onClick={logout}>{'<<'} Wróć do strony logowania</button>
    </div>
  )
}

export default Loading
